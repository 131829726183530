import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useAppDispatch } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  Space,
  Typography,
  Button,
  Steps,
  Form,
  Input,
  Select,
  Card,
  DatePicker,
  Popconfirm,
  Alert,
  Row,
  Col,
} from "antd";
import {
  checkLists,
  listCampaignsList,
} from "../../../../redux/actions/CampaignManager/listsAction";
import { createCampaignManager } from "../../../../redux/actions/CampaignManager/campaignManagerAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import dayjs, { Dayjs } from "dayjs";
import ErrorBoundary from "../../../../utils/errorBoundary";
import CampaignScheduleModal from "../../../../components/modals/CampaignScheduleModal";

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;
const { Meta } = Card;

interface SelectedOptions {
  type: string;
  name: string;
  value: { name: string };
}
const CampaignCreate: React.FC = () => {
  const [form] = Form.useForm();
  const [campaignStep, setCampaignStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>([]);
  const dispatch = useAppDispatch();
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [sendGridCampaignId, setSendGridCampaignId] = useState<string>("");
  const editorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [checkListUpdated, setCheckListUpdated] = useState(false);
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = getLocalStorage("user");

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const letterOfAuthorization = user.letterOfAuthorisation;

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    sorter: {
      field: "createdAt",
      order: "ascend",
    },
  });

  const regulations = getRegulationsFromLocalStorage() ?? [];

  const multipleSelect = () => {
    const value: SelectedOptions[] = [];

    if (permissions?.read?.includes("conflictminerals")) {
      value.push({
        type: "Conflict Minerals",
        name: "Conflict Minerals",
        value: { name: "conflict" },
      });
    }

    if (permissions?.read?.includes("materials")) {
      value.push({
        type: "FMD",
        name: "FMD (Materials)",
        value: { name: "materials" },
      });
    }

    if (permissions?.read?.includes("extendedminerals")) {
      value.push({
        type: "Extended Minerals",
        name: "Extended Minerals",
        value: { name: "extended" },
      });
    }

    if (permissions?.read?.includes("scip")) {
      value.push({
        type: "SCIP (WFD)",
        name: "SCIP (WFD)",
        value: { name: "scip" },
      });
    }

    if (permissions?.read?.includes("regulations")) {
      regulations?.forEach((val: any) =>
        value.unshift({
          type: "Regulation",
          name: `${val.name}`,
          value: { name: val.regulationNumber },
        })
      );
    }

    return value;
  };
  const options = multipleSelect();

  const onSaveCampaign = async () => {
    try {
      if (campaignStep === 0) {
        await form.validateFields(["name", "selectOptions"]);
        submitCampaign();
      } else if (campaignStep === 1) {
        await form.validateFields(["subject", "message"]);
        submitCampaign();
      }
    } catch (error) {
      return;
    }
  };

  const submitCampaign = () => {
    setLoading(true);
    let message = editorRef?.current?.getContent();
    const values = form.getFieldsValue();
    const listOpts = listOptions.filter((o) => values.lists?.includes(o.value));
    const requestedOpts = options?.filter((e) =>
      values?.selectOptions?.includes(e.name)
    );
    const windowUrl = window.location.href.split("/");
    const link = `${windowUrl[0]}//${
      windowUrl[2]
    }/supplier/{{unique_name}}/${window.btoa(user?.tenantNumber)}/${window.btoa(
      sendGridCampaignId
    )}`;

    message =
      message &&
      message?.replace(
        "{{supplier_portal_link}}",
        `<a href='${link}'>${link}</a>`
      );

    message =
      letterOfAuthorization && message
        ? message.replace(
            "{{letter_of_authorization}}",
            `<a href='${link}/letterofauthorisation/${letterOfAuthorization
              .replace(".pdf", "")
              .replace(
                / /g,
                "%20"
              )}'>${link}/letterofauthorisation/${letterOfAuthorization
              .replace(".pdf", "")
              .replace(/ /g, "%20")}</a>`
          )
        : message;

    const formValues = {
      name: values?.name,
      lists: listOpts.map((e: any) => ({
        _id: e._id,
      })),
      regulations: requestedOpts
        .filter((v) => v.type === "Regulation")
        .map((o) => o?.value?.name),
      materials: requestedOpts.find((v) => v.type === "FMD") ? "Yes" : "No",
      conflict: requestedOpts.find((v) => v.type === "Conflict Minerals")
        ? "Yes"
        : "No",
      extended: requestedOpts.find((v) => v.type === "Extended Minerals")
        ? "Yes"
        : "No",
      scip: requestedOpts.find((v: any) => v.type === "SCIP (WFD)")
        ? "Yes"
        : "No",
      campaignStep: campaignStep + 1,
      sendgridCampaignId: sendGridCampaignId ? sendGridCampaignId : null,
      subject: values?.subject,
      message: message,
    };

    dispatch(createCampaignManager(formValues)).then((response) => {
      setSendGridCampaignId(response?.sendgridCampaignId);
      setCampaignStep(campaignStep + 1);
      setLoading(false);
    });
  };

  const prevStep = () => {
    setCampaignStep(campaignStep - 1);
  };

  const onClose = () => {
    navigate(-1);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const openModal = () => {
    onCancel();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigateToSurveyList = () => {
    setIsModalOpen(false);
    navigate("/app/campaignmanager/survey");
  };

  const totalContacts = listOptions
    .filter((o) => form.getFieldValue("lists")?.includes(o.value))
    .map((e) => e?.suppliersCount)
    .reduce((a, b) => a + b, 0);

  const missingContacts = listOptions
    .filter((e) => form.getFieldValue("lists")?.includes(e.value))
    .reduce((total, option) => {
      const missingCount = option.suppliersMissingCount.filter(
        (status: any) => !status
      ).length;
      return total + missingCount;
    }, 0);

  const disabledDate = (current: Dayjs | undefined): boolean => {
    return !!current && current.isBefore(dayjs().startOf("day"));
  };

  const disabledTime = (current: Dayjs | undefined, type: "start" | "end") => {
    if (!current || !current.isSame(dayjs(), "day")) {
      return {};
    }

    const currentHour = dayjs().hour();
    const currentMinute = dayjs().minute();

    return {
      disabledHours: () =>
        Array.from(
          { length: type === "start" ? currentHour : 24 - currentHour - 1 },
          (_, index) => (type === "start" ? index : currentHour + 1 + index)
        ),
      disabledMinutes: () =>
        Array.from(
          { length: type === "start" ? currentMinute : 60 - currentMinute - 1 },
          (_, index) => (type === "start" ? index : currentMinute + 1 + index)
        ),
    };
  };

  const scheduleCampaign = () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const currentDate = new Date();
    const startDate = new Date(
      values?.dateRange?.map((date: any) => date)[0].$d
    );
    const diff = startDate.getTime() - currentDate.getTime();
    const setTime =
      diff < 120000 ? new Date(new Date().getTime() + 1 * 120000) : startDate;
    const windowUrl = window.location.href.split("/");
    const link = `${windowUrl[0]}//${
      windowUrl[2]
    }/supplier/{{unique_name}}/${window.btoa(user?.tenantNumber)}/${window.btoa(
      sendGridCampaignId
    )}`;
    const listOpts = listOptions.filter((o) => values.lists?.includes(o.value));

    const formValues = {
      lists: listOpts.map((e: any) => ({
        _id: e._id,
      })),
      campaignStep: campaignStep + 1,
      sendgridCampaignId: sendGridCampaignId ? sendGridCampaignId : null,
      startDate: setTime.toISOString(),
      endDate: new Date(
        values?.dateRange?.map((date: any) => date)[1].$d
      ).toISOString(),
      link: link,
    };

    dispatch(createCampaignManager(formValues)).then((response) => {
      setSendGridCampaignId(response?.sendgridCampaignId);
      setCampaignStep(campaignStep + 1);
      setOpen(false);
      navigate(-1);
      setLoading(false);
    });
  };

  const onFormSubmit = () => {
    if (campaignStep === 2) {
      if (!form.validateFields(["dateRange"])) return;
    }
    const values = form.getFieldsValue();
    const startDate = new Date(
      values?.dateRange?.map((date: any) => date)[0].$d
    );
    const endDate = new Date(values?.dateRange?.map((date: any) => date)[1].$d);
    const currentDate = new Date();
    if (startDate < currentDate) {
      form.setFields([
        {
          name: "dateRange",
          errors: ["Start date cannot be earlier than current date"],
        },
      ]);
    }
    if (endDate < startDate) {
      form.setFields([
        {
          name: "dateRange",
          errors: ["End date cannot be earlier than start date"],
        },
      ]);
    }

    setOpen(true);
  };

  const actionCards = [
    <Space key="1" style={{ display: "flex", justifyContent: "end" }}>
      {campaignStep > 0 && (
        <Button
          style={{ margin: "0 8px" }}
          type="primary"
          onClick={prevStep}
          data-testid="previous-campaign-button"
        >
          Previous
        </Button>
      )}
      {campaignStep < 2 && (
        <Button
          style={{ background: "#045B7C", color: "white", marginRight: 8 }}
          onClick={onSaveCampaign}
          loading={loading}
          data-testid="save-campaign-button"
          disabled={isNextStepDisabled}
        >
          Save & Continue
        </Button>
      )}
      {campaignStep <= 2 && (
        <Button
          style={{ marginRight: 8 }}
          type="text"
          onClick={onClose}
          data-testid="close-campaign-button"
        >
          Close
        </Button>
      )}
      {campaignStep === 2 && (
        <>
          <Popconfirm
            title={"Schedule Campaign"}
            description={"Are you certain about scheduling this campaign ?"}
            onConfirm={openModal}
            okText="Yes"
            open={open}
            cancelText="No"
            onCancel={onCancel}
            placement="topLeft"
          >
            <Button
              style={{ background: "#045B7C", color: "white", marginRight: 8 }}
              onClick={onFormSubmit}
              disabled={checkListUpdated === true}
              loading={loading}
              data-testid="submit-campaign-button"
            >
              Submit
            </Button>
          </Popconfirm>
          <CampaignScheduleModal
            isModalOpen={isModalOpen}
            openModal={openModal}
            closeModal={closeModal}
            navigateToSurveyList={navigateToSurveyList}
            scheduleCampaign={scheduleCampaign}
            missingContacts={missingContacts}
            totalSuppliers={totalContacts}
          />
        </>
      )}
    </Space>,
  ];

  const onSearch = (value: any) => {
    readlists(
      { pageSize: 10, current: 1, total: 0 },
      {
        query: value?.key === "Enter" ? value?.target?.value : value,
      },
      {
        field: tableParams.sorter.field,
        order: tableParams.sorter.order,
      }
    );
  };

  const readlists = (pagination: any, filters: any, sorter: any) => {
    dispatch(
      listCampaignsList({
        limit: pagination.pageSize,
        page: pagination.current,
        sortBy: sorter.column ? sorter.field : ["createdAt"],
        sortDesc: sorter.order === "descend" ? true : false,
        softDelete: false,
        search: filters.query ? filters.query : "",
      })
    ).then((response: any) => {
      setListOptions(
        response.lists?.map((e: any) => ({
          value: e.name,
          ...e,
        }))
      );
      setTableParams({
        pagination: { ...pagination },
        sorter: sorter,
      });
    });
  };

  const readListsFromSendGrid = (values: any) => {
    const listOpts = listOptions.filter((o) => values?.includes(o.value));

    dispatch(
      checkLists({
        lists: listOpts.map((e: any) => ({
          _id: e._id,
        })),
      })
    ).then((response: any) => {
      const errors = [];
      if (response?.missingContacts > 0) {
        const missingContactsMessage =
          response?.missingContacts === 1
            ? "1 supplier has a missing primary contact."
            : `${response?.missingContacts} suppliers have missing primary contacts.`;
        errors.push(missingContactsMessage);
      }
      if (response?.invalidContacts > 0) {
        const invalidContactsMessage =
          response?.invalidContacts === 1
            ? "1 supplier has an invalid email address."
            : `${response?.invalidContacts} suppliers have invalid email addresses.`;
        errors.push(invalidContactsMessage);
      }

      if (errors.length > 0) {
        form.setFields([
          {
            name: "lists",
            errors: errors,
          },
        ]);
      } else {
        form.setFields([
          {
            name: "lists",
            errors: [],
          },
        ]);
      }

      if (response?.invalidContacts > 0) {
        setIsNextStepDisabled(true);
      } else {
        setIsNextStepDisabled(false);
      }
    });
  };

  useEffect(() => {
    readlists(
      { pageSize: 10, current: 1 },
      { query: "" },
      {
        field: tableParams.sorter.field,
        order: tableParams.sorter.order,
      }
    );
  }, []);

  return (
    <ErrorBoundary>
      <Card actions={actionCards}>
        <Steps
          items={[
            {
              title: "Send To & Request Data",
            },
            {
              title: "Email Content",
            },
            {
              title: "Schedule Campaign",
            },
          ]}
          current={campaignStep}
        />
        <div style={{ marginTop: "20px" }}>
          <Form
            form={form}
            onFinish={onFormSubmit}
            layout="vertical"
            autoComplete="off"
          >
            <Card style={{ display: campaignStep === 0 ? "block" : "none" }}>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ flex: 1 }}>
                  <Form.Item
                    name="name"
                    label="Campaign Name"
                    rules={[
                      { required: true, message: "Please enter Campaign name" },
                      {
                        pattern: /^([a-zA-Z0-9()-/.,&_@*]+\s?)*$/,
                        message: "Special Characters not allowed!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Item
                    name="lists"
                    label="Lists"
                    rules={[
                      {
                        required: true,
                        message: "Please select from the list",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Type to Search for lists"
                      options={listOptions.map((option) => ({
                        ...option,
                        label: (
                          <>
                            <div>{option.value}</div>
                            <div
                              style={{ fontSize: "smaller", color: "green" }}
                            >
                              {"Total supplier: " + option?.suppliersCount}
                            </div>
                            <div style={{ fontSize: "smaller", color: "red" }}>
                              {"Missing contacts: " +
                                option?.suppliersMissingCount.filter(
                                  (e: any) => e === false
                                ).length}
                            </div>
                            <div
                              style={{ fontSize: "smaller", color: "orange" }}
                            >
                              {"Invalid contacts: " +
                                option?.suppliersMissingCount.filter(
                                  (e: any) => e === "invalid"
                                ).length}
                            </div>
                          </>
                        ),
                      }))}
                      onSearch={onSearch}
                      onChange={(selectedValues) => {
                        readListsFromSendGrid(selectedValues);
                      }}
                      mode="multiple"
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                name="selectOptions"
                label="Requested data"
                rules={[
                  {
                    required: true,
                    message: "Please select requested data from the list",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  value={selectedOptions}
                  onChange={(values) => setSelectedOptions(values)}
                >
                  {options.map((option) => (
                    <Select.Option key={option.name} value={option.name}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
            <Card style={{ display: campaignStep === 1 ? "block" : "none" }}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[{ required: true, message: "Subject cannot be null" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="message"
                label="Message"
                rules={[{ required: true, message: "Required" }]}
                hidden={true}
              >
                <Input.TextArea />
              </Form.Item>
              <label style={{ display: "block", marginBottom: "0.5rem" }}>
                <span
                  style={{
                    color: "red",
                    fontSize: "25px",
                    fontWeight: "50",
                    display: "inline",
                    verticalAlign: "middle",
                    marginRight:"4px"
                  }}
                >
                  *
                </span>
                <span
                  style={{
                    display: "inline",
                    verticalAlign: "middle",
                    position: "relative",
                    top: "-7px",
                  }}
                >
                  Message
                </span>
              </label>
              <Editor
                apiKey="eph2salzmox3uyanm02ujyd63j89lg4hp5o450729hkt5xf1"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                  form.setFieldValue("message", editor.getContent());
                }}
                onChange={(content) =>
                  form.setFieldValue("message", { content })
                }
                init={{
                  height: 400,
                  menubar: false,
                  convert_urls: true,
                  relative_urls: false,
                  remove_script_host: false,
                  setup: function (editor) {
                    editor.ui.registry.addButton("compliancebutton", {
                      text: "Supplier Portal Link",
                      tooltip: "Supplier Portal Link",
                      onAction: function () {
                        editor.focus();
                        editor.selection.setContent(
                          " {{supplier_portal_link}} "
                        );
                      },
                    });
                    editor.ui.registry.addButton("firstNameButton", {
                      text: "First Name",
                      tooltip: "First Name",
                      onAction: function () {
                        editor.focus();
                        editor.selection.setContent(" {{first_name}} ");
                      },
                    });
                    editor.ui.registry.addButton("lastNameButton", {
                      text: "Last Name",
                      tooltip: "Last Name",
                      onAction: function () {
                        editor.focus();
                        editor.selection.setContent(" {{last_name}} ");
                      },
                    });
                    if (letterOfAuthorization) {
                      editor.ui.registry.addButton(
                        "letterOfAuthorizationButton",
                        {
                          text: "Letter of Authorization",
                          tooltip: "Letter of Authorization",
                          onAction: function () {
                            editor.focus();
                            editor.selection.setContent(
                              " {{letter_of_authorization}} "
                            );
                          },
                        }
                      );
                    }
                  },
                  plugins:
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen table insertdatetime media table paste code wordcount",
                  toolbar1:
                    // eslint-disable-next-line no-multi-str
                    " undo redo | bold italic underline | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat preview | advlist autolink lists link image charmap",
                  toolbar2:
                    "compliancebutton firstNameButton lastNameButton addressButton letterOfAuthorizationButton",
                }}
              />
            </Card>
            <Card style={{ display: campaignStep === 2 ? "block" : "none" }}>
              {checkListUpdated === true && (
                <Alert
                  message="Campaign survey list attached is being prepared , please wait for few mins and come back to schedule a campaign."
                  type="warning"
                  showIcon
                />
              )}
              <Card style={{ marginTop: "2rem" }}>
                <Meta
                  title={
                    <div style={{ textAlign: "center" }}>
                      {"Review Campaign-" + form.getFieldValue("name")}
                    </div>
                  }
                  description={
                    <span>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Text strong>Total contacts: </Text>
                          <Text>
                            {totalContacts - missingContacts}
                            {" out of "}
                            {totalContacts}
                            {" supplier(s) will receive the following message."}
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text strong>Subject: </Text>
                          <Text>{form.getFieldValue("subject")}</Text>
                        </Col>
                        <Col span={24}>
                          <Text strong>Requested Data: </Text>
                          <Text>
                            {form.getFieldValue("selectOptions")?.join(",")}
                          </Text>
                        </Col>
                      </Row>
                    </span>
                  }
                />
              </Card>
              <Form.Item
                name="dateRange"
                label="Schedule"
                rules={[
                  { required: true, message: "Please select a date range" },
                ]}
                initialValue={[
                  dayjs().add(5, "minutes"),
                  dayjs().add(3, "months"),
                ]}
              >
                <RangePicker
                  disabled={checkListUpdated === true}
                  disabledDate={(current) => disabledDate(dayjs(current))}
                  disabledTime={(current, type) =>
                    disabledTime(dayjs(current), type as "start" | "end")
                  }
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  size="large"
                />
              </Form.Item>
            </Card>
          </Form>
        </div>
      </Card>
    </ErrorBoundary>
  );
};

export default CampaignCreate;
