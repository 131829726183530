import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Steps,
  Switch,
  Image,
  Typography,
  Collapse,
  SelectProps,
  Tooltip,
  Input,
  DatePicker,
  InputNumber,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  ArrowRightOutlined,
  CaretRightOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MinusCircleOutlined,
  MinusCircleTwoTone,
  PlusCircleOutlined,
  PlusCircleTwoTone,
  UnlockOutlined,
} from "@ant-design/icons";
import vectorOutlined1 from "../../assets/vector-outlined-1.png";
import vectorOutlined2 from "../../assets/vector-outlined-2.png";
import TextArea from "antd/es/input/TextArea";
import DeclarationPreview from "../ipcEditor/declarationPreview";
import XmlDownloadComponent from "../ipcEditor/generateDeclaration";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
const { Title, Text } = Typography;

interface Substances {
  substance: string;
  cas: string;
  exempt: string;
  weight: number;
  uom: string;
  toleranceM: number;
  toleranceP: number;
  ppm: number;
}

interface Materials {
  name: string;
  weight: number;
  uom: string;
  level?: string;
  substanceCategory?: string;
  substances: Substances[];
}

interface Item {
  name: string;
  weight: number;
  uom: string;
  unitType: string;
  materials: Materials[];
}
const IPC1752Declaration: React.FC = () => {
  const location = useLocation();
  const xmlData = location.state;
  const [currentStep, setCurrentStep] = useState(0);
  const [fieldLock, setFieldLock] = useState(false);
  const [formTypeSelect, setFormTypeSelect] = useState(
    xmlData ? xmlData.formType : ""
  );
  const [productDeclarationType, setProductDeclarationType] = useState<string>(
    xmlData ? xmlData.productDeclarationType : "Single"
  );
  const [declarationClassSelect, setDeclarationClassSelect] = useState(
    xmlData ? xmlData.declarationClass : ""
  );
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  const [legalDef, setLegalDef] = useState<string>("");
  const [supplierCheckbox, setSupplierCheckbox] = useState<boolean>(
    xmlData ? xmlData.mfrItemVersionManufacturingSite : false
  );
  const [selectedRohsExemptions, setSelectedRohsExemptions] = useState<
    string[]
  >(xmlData && xmlData.rohsExemptions ? xmlData.rohsExemptions : []);

  const steps = [
    {
      title: "Business Information",
    },
    {
      title: (
        <>
          Information (Requester,
          <br /> Supplier & Manufacturing)
        </>
      ),
    },
    {
      title: (
        <>
          RoHS Material Composition
          <br /> Declaration
        </>
      ),
    },
    {
      title: (
        <>
          Homogeneous Material Composition <br />
          Declaration for Electronic Products
        </>
      ),
    },
  ];

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = async () => {
    try {
      if (currentStep === 0) {
        await form.validateFields(["formType", "declarationClass"]);
      } else if (currentStep === 1) {
        await form.validateFields([
          ["requesterInformation", "companyName"],
          ["requesterInformation", "responseDate"],
          ["requesterInformation", "respondByDate"],
          ["requesterInformation", "contactName"],
          ["requesterInformation", "contactTitle"],
          ["requesterInformation", "contactPhone"],
          ["requesterInformation", "contactEmail"],
          ["requesterInformation", "requesterComments"],
          ["requesterInformation", "itemNumber"],
          ["requesterInformation", "mfrItemVersion"],
          ["supplierInformation", "companyName"],
          ["supplierInformation", "responseDate"],
          ["supplierInformation", "contactName"],
          ["supplierInformation", "phoneContact"],
          ["supplierInformation", "emailContact"],
          ["supplierInformation", "authorisedRepresentative"],
          ["supplierInformation", "titleRepresentative"],
          ["supplierInformation", "phoneRepresentative"],
          ["supplierInformation", "emailRepresentative"],
          ["supplierInformation", "supplierComments"],
          ["supplierInformation", "weight"],
          ["supplierInformation", "uom"],
          ["supplierInformation", "unitType"],
        ]);
      } else if (currentStep === 2) {
        await form.validateFields([
          "declarationType",
          "declarationTypeDescription",
          "rohsExemptions",
          "rohsDeclaration",
          "supplierAcceptance",
          ["exemptionListVersion_65_EU_ANNEX_IV"],
          ["exemptionListVersion_534_EU"],
        ]);
      }
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleFormSubmit = (values: any) => {
    setFormData(values);
  };
  const actionCards = [
    <Space
      key="1"
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div style={{ marginLeft: "auto", paddingLeft: "25px" }}>
        {currentStep <= 3 && currentStep > 0 && (
          <Button
            danger
            style={{ borderRadius: 0 }}
            onClick={() => {
              form.resetFields();
              setCurrentStep(0);
              setFieldLock(false);
            }}
          >
            Reset
          </Button>
        )}
      </div>
      <div>
        {currentStep > 0 && (
          <Button
            style={{ margin: "0 8px", borderRadius: 0 }}
            onClick={handlePrev}
          >
            Back
          </Button>
        )}
        {currentStep === 3 && (
          <>
            {
              <>
                <DeclarationPreview
                  onSubmit={handleFormSubmit}
                  form={form}
                  fieldLock={fieldLock}
                />
                <XmlDownloadComponent
                  onSubmit={handleFormSubmit}
                  form={form}
                  fieldLock={fieldLock}
                />
              </>
            }
          </>
        )}
        {currentStep < 3 && (
          <Button
            style={{
              background: "#F7863E",
              color: "white",
              marginRight: 8,
              borderRadius: 0,
            }}
            onClick={handleNext}
          >
            Save & Next
          </Button>
        )}
        {currentStep === 3 && (
          <Tooltip title={fieldLock ? "Locked" : "Unlocked"}>
            <Switch
              style={{
                marginRight: "2rem",
                backgroundColor: fieldLock ? "red" : "green",
              }}
              checkedChildren={<LockOutlined />}
              unCheckedChildren={<UnlockOutlined />}
              onChange={() => setFieldLock(!fieldLock)}
            />
          </Tooltip>
        )}
      </div>
    </Space>,
  ];
  useEffect(() => {
    if (xmlData) {
      xmlData.requesterInformation?.responseDate
        ? form.setFieldsValue({
            requesterInformation: {
              responseDate: dayjs(xmlData.requesterInformation.responseDate),
            },
          })
        : form.setFieldsValue({
            requesterInformation: {
              responseDate: null,
            },
          });
      xmlData.supplierInformation.responseDate
        ? form.setFieldsValue({
            supplierInformation: {
              responseDate: dayjs(xmlData.supplierInformation.responseDate),
            },
          })
        : form.setFieldsValue({
            supplierInformation: {
              responseDate: null,
            },
          });
      xmlData.requesterInformation.respondByDate
        ? form.setFieldsValue({
            requesterInformation: {
              respondByDate: dayjs(xmlData.requesterInformation.respondByDate),
            },
          })
        : form.setFieldsValue({
            requesterInformation: {
              respondByDate: null,
            },
          });
      xmlData.supplierInformation.effectiveDate
        ? form.setFieldsValue({
            supplierInformation: {
              effectiveDate: dayjs(xmlData.supplierInformation.effectiveDate),
            },
          })
        : form.setFieldsValue({
            supplierInformation: {
              effectiveDate: null,
            },
          });
    }
  }, []);
  function getLinearGradient(hexColor: string) {
    let hex = hexColor.replace(/^#/, "");
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }
    const num = parseInt(hex, 16);
    const r = (num >> 16) & 255;
    const g = (num >> 8) & 255;
    const b = num & 255;

    const fromColor = r + "," + g + "," + b;
    const color2 = r + 40 + "," + (g + 40) + "," + (b + 40);
    const toColor = r + 90 + "," + (g + 90) + "," + (b + 90);
    return `linear-gradient(90deg, rgba(${toColor},1) 0%, rgba(${color2}, 1) 40%, rgba(${fromColor}, 1) 100%)`;
  }
  const rohsDeclaration = [
    "1 - Item(s) does not contain RoHS restricted substances per the definition above",

    "2 - Item(s) contains RoHS restricted substances above the limits per the definition and is not under exemption",

    "3 - Item(s) does not contain RoHS restricted substances per the definition above except for lead in solders and selected exemptions, if any",

    "4 - Item(s) does not contain RoHS restricted substances per the definition above except for selected exemptions",

    "5 - Item(s) is obsolete, no information is available",

    "6 - Item(s) is unknown, no information is available",
  ];
  const options: SelectProps["options"] = rohsDeclaration.map((item) => ({
    label: item,
    value: item,
  }));

  const gridArrayMaterial = [
    "Aluminum (Al) - anodized",
    "Bright Tin (Sn)",
    "Bright Tin (Sn) - annealed",
    "Bright Tin (Sn) - fused",
    "Bright Tin (Sn) - reflowed",
    "Bright Tin (Sn) - reflowed over Nickel (Ni) barrier",
    "Bright Tin (Sn) - with Nickel (Ni) barrier",
    "Bright Tin (Sn) - with Silver (Ag) barrier",
    "Chromium (Cr)",
    "Chromium (Cr) - hexavalent",
    "Chromium (Cr) - trivalent",
    "Gold (Au)",
    "Gold (Au) - electroplated",
    "Gold (Au) - hard",
    "Indium (In)",
    "Matte Tin/Copper (Sn/Cu)",
    "Matte Tin (Sn)",
    "Matte Tin (Sn) - annealed",
    "Matte Tin (Sn) - fused",
    "Matte Tin (Sn) - reflowed",
    "Matte Tin (Sn) - reflowed over Nickel (Ni) barrier",
    "Matte Tin (Sn) - with Nickel (Ni) barrier",
    "Matte Tin (Sn) - with Silver (Ag) barrier",
    "Nickel (Ni)",
    "Nickel/Gold (Ni/Au)",
    "Nickel/Gold (Ni/Au) - electrolytic",
    "Nickel/Gold (Ni/Au) - ENIG",
    "Nickel/Palladium (Ni/Pd)",
    "Nickel/Palladium/Gold (Ni/Pd/Au)",
    "Nickel/Palladium/Gold (Ni/Pd/Au) - ENEPIG",
    "Organic Solderability Preservative (OSP)",
    "Organic Solderability Preservative (OSP-HT) - high temperature",
    "Palladium (Pd)",
    "Platinum/Palladium/Silver (Pt/Pd/Ag)",
    "Semi-matte Tin (Sn)",
    "Silver (Ag)",
    "Silver (Ag) - electroplated",
    "Silver (Ag) - immersion",
    "Silver (Ag) - with Nickel (Ni) barrier",
    "Silver/Palladium (Ag/Pd)",
    "Silver/Palladium (Ag/Pd) - Ni barrier",
    "Tin (Sn)",
    "Tin (Sn) - hot dipped",
    "Tin (Sn) - immersion",
    "Tin (Sn) - reflowed",
    "Tin/Bismuth (SnBi)",
    "Tin/Bismuth (SnBi) - <5% Bi",
    "Tin/Bismuth/Gold (Sn/Bi/Au)",
    "Tin/Copper (Sn/Cu)",
    "Tin/Copper (Sn/Cu) - annealed",
    "Tin/Copper (Sn/Cu) - HASL",
    "Tin/Copper (Sn/Cu) - hot dipped",
    "Tin/Lead (Sn10Pb90)",
    "Tin/Lead (Sn63Pb37)",
    "Tin/Lead/Silver (Sn/Pb/Ag)",
    "Tin/Silver (Sn/Ag)",
    "Tin/Silver (Sn/Ag) - hot dipped",
    "Tin/Silver (Sn/Ag) - plated",
    "Tin/Silver/Bismuth (Sn/Ag/Bi)",
    "Tin/Silver/Bismuth/Copper (Sn/Ag/Bi/Cu)",
    "Tin/Silver/Copper (Sn/Ag/Cu)",
    "Tin/Silver/Copper (Sn/Ag/Cu) - hot dipped",
    "Tin/Zinc (Sn/Zn)",
    "Tin/Zinc/Aluminum (Sn/Zn/Al)",
    "Tin/Zinc/Nickel (Sn/Zn/Ni)",
    "Zinc (Zn)",
    "Other",
    "N/A",
  ];
  const gridArrayMaterialOptions: SelectProps["options"] =
    gridArrayMaterial.map((item) => ({
      label: item,
      value: item,
    }));
  const terminalBaseAlloy = [
    "",
    "Alloy 42",
    "CU Alloy",
    "Beryllium Copper",
    "Brass",
    "Phosphor Bronze",
    "Kovar",
    "Other",
    "Not Applicable",
  ];
  const terminalBaseAlloyOptions: SelectProps["options"] =
    terminalBaseAlloy.map((item) => ({
      label: item,
      value: item,
    }));
  const mslRating = [
    "",
    "1",
    "2",
    "2a",
    "4",
    "5",
    "5a",
    "6",
    "Does not meet",
    "Not Applicable",
  ];
  const mslRatingOptions: SelectProps["options"] = mslRating.map((item) => ({
    label: item,
    value: item,
  }));
  const exemptionListVersion_EL2011_534_EU = [
    "1. Mercury in single capped (compact) fluorescent lamps not exceeding (per burner):",
    "1(a). Mercury in single capped (compact) fluorescent lamps not exceeding (per burner): For general lighting purposes less than 30 W: 3.5 mg",
    "1(b). Mercury in single capped (compact) fluorescent lamps not exceeding (per burner): For general lighting purposes greater than or equal to 30 W and less than 50 W: 3.5 mg",
    "1(c). Mercury in single capped (compact) fluorescent lamps not exceeding (per burner): For general lighting purposes greater than or equal to 50 W and less than 150 W: 5 mg",
    "1(d). Mercury in single capped (compact) fluorescent lamps not exceeding (per burner): For general lighting purposes greater than or equal to 150 W: 15 mg",
    "1(e). Mercury in single capped (compact) fluorescent lamps not exceeding (per burner): For general lighting purposes with circular or square structural shape and tube diameter less than or equal to 17 mm: 7 mg",
    "1(f). Mercury in single capped (compact) fluorescent lamps not exceeding (per burner): For special purposes: 5 mg",
    "1(g). Mercury in single capped (compact) fluorescent lamps not exceeding (per burner):For general lighting purposes less than 30 W with a lifetime equal or above 20,000 h: 3.5 mg",
    "2(a). Mercury in double-capped linear fluorescent lamps for general lighting purposes not exceeding (per lamp):",
    "2(a)(1). Mercury in double-capped linear fluorescent lamps for general lighting purposes not exceeding (per lamp): Tri-band phosphor with normal lifetime and a tube diameter less than 9 mm (e.g. T2): 4 mg",
    "2(a)(2). Mercury in double-capped linear fluorescent lamps for general lighting purposes not exceeding (per lamp): Tri-band phosphor with normal lifetime and a tube diameter greater than or equal to 9 mm and less than or equal to 17 mm (e.g. T5): 3 mg",
    "2(a)(3). Mercury in double-capped linear fluorescent lamps for general lighting purposes not exceeding (per lamp): Tri-band phosphor with normal lifetime and a tube diameter greater than 17 mm and less than or equal to 28 mm (e.g. T8): 3.5 mg",
    "2(a)(4). Mercury in double-capped linear fluorescent lamps for general lighting purposes not exceeding (per lamp): Tri-band phosphor with normal lifetime and a tube diameter greater than 28 mm (e.g. T12): 5 mg",
    "2(a)(5). Mercury in double-capped linear fluorescent lamps for general lighting purposes not exceeding (per lamp): Tri-band phosphor with long lifetime (= 25,000 h): 5 mg",
    "2(b). Mercury in other fluorescent lamps not exceeding (per lamp):",
    "2(b)(1). Mercury in other fluorescent lamps not exceeding (per lamp): Linear halophosphate lamps with tube greater than 28 mm (e.g. T10 and T12): 10 mg",
    "2(b)(2). Mercury in other fluorescent lamps not exceeding (per lamp): Non-linear halophosphate lamps (all diameters): 15 mg",
    "2(b)(3). Mercury in other fluorescent lamps not exceeding (per lamp): Non-linear tri-band phosphor lamps with tube diameter greater than 17 mm (e.g. T9): 15 mg",
    "2(b)(4). Mercury in other fluorescent lamps not exceeding (per lamp): Lamps for other general lighting and special purposes (e.g. induction lamps): 15 mg",
    "3. Mercury in cold cathode fluorescent lamps and external electrode fluorescent lamps (CCFL and EEFL) for special purposes not exceeding (per lamp):",
    "3(a). Mercury in cold cathode fluorescent lamps and external electrode fluorescent lamps (CCFL and EEFL) for special purposes not exceeding (per lamp): Short length (less than or equal to 500 mm): 3.5 mg",
    "3(b). Mercury in cold cathode fluorescent lamps and external electrode fluorescent lamps (CCFL and EEFL) for special purposes not exceeding (per lamp): Medium length (greater than 500 mm and less than or equal to 1500 mm): 5 mg",
    "3(c). Mercury in cold cathode fluorescent lamps and external electrode fluorescent lamps (CCFL and EEFL) for special purposes not exceeding (per lamp): Long length (greater than 1500 mm): 13 mg",
    "4(a). Mercury in other low pressure discharge lamps (per lamp): 15 mg",
    "4(b). Mercury in High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner) in lamps with improved colour rendering index Ra > 60:",
    "4(b)-I. Mercury in High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner) in lamps with improved colour rendering index Ra greater than 60: P less than or equal to 155 W: 30 mg",
    "4(b)-II. Mercury in High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner) in lamps with improved colour rendering index Ra greater than 60: 155 W less than P less than or equal to 405 W: 40 mg",
    "4(b)-III. Mercury in High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner) in lamps with improved colour rendering index Ra greater than 60: P greater than 405 W: 40 mg",
    "4(c). Mercury in other High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner):",
    "4(c)-I. Mercury in other High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner): P less than or equal to 155 W: 25 mg",
    "4(c)-II. Mercury in other High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner): 155 W less than P less than or equal to 405 W: 30 mg",
    "4(c)-III. Mercury in other High Pressure Sodium (vapour) lamps for general lighting purposes not exceeding (per burner): P greater than 405 W: 40 mg",
    "4(d). Mercury in High Pressure Mercury (vapour) lamps (HPMV)",
    "4(e). Mercury in metal halide lamps (MH)",
    "4(f). Mercury in other discharge lamps for special purposes not specifically mentioned in this Annex",
    "4(g). Mercury in hand crafted luminous discharge tubes used for signs, decorative or architectural and specialist lighting and light-artwork, where the mercury content shall be limited as follows: (a) 20 mg per electrode pair + 0.3 mg per tube length in cm, but not more than 80 mg, for outdoor applications and indoor applications exposed to temperatures below 20 °C; (b) 15 mg per electrode pair + 0.24 mg per tube length in cm, but not more than 80 mg, for all other indoor applications",
    "5(a). Lead in glass of cathode ray tubes",
    "5(b). Lead in glass of fluorescent tubes not exceeding 0.2% by weight",
    "6(a). Lead as an alloying element in steel for machining purposes and in galvanized steel containing up to 0.35 % lead by weight",
    "6(b). Lead as an alloying element in aluminium containing up to 0.4% lead by weight",
    "6(c). Copper alloy containing up to 4% lead by weight",
    "7(a). Lead in high melting temperature type solders (i.e. lead-based alloys containing 85% by weight or more lead)",
    "7(b). Lead in solders for servers, storage and storage array systems, network infrastructure equipment for switching, signalling, transmission, and network management for telecommunications",
    "7(c)-I. Electrical and electronic components containing lead in a glass or ceramic other than dielectric ceramic in capacitors, e.g. piezoelectronic devices, or in a glass or ceramic matrix compound",
    "7(c)-II. Lead in dielectric ceramic in capacitors for a rated voltage of 125 V AC or 250 V DC or higher",
    "7(c)-III. Lead in dielectric ceramic in capacitors for a rated voltage of less than 125 V AC or 250 V DC",
    "7(c)-IV. Lead in PZT based dielectric ceramic materials for capacitors being part of integrated circuits or discrete semiconductors",
    "8(a). Cadmium and its compounds in one shot pellet type thermal cut-offs",
    "8(b). Cadmium and its compounds in electrical contacts",
    "9. Hexavalent chromium as an anticorrosion agent of the carbon steel cooling system in absorption refrigerators up to 0.75% by weight in the cooling solution",
    "9(b). Lead in bearing shells and bushes for refrigerant-containing compressors for heating, ventilation, air conditioning and refrigeration (HVACR) applications",
    "11(a). Lead used in C-press compliant pin connector systems",
    "11(b). Lead used in other than C-press compliant pin connector systems",
    "12. Lead as a coating material for the thermal conduction module C-ring",
    "13(a). Lead in white glasses used for optical applications",
    "13(b). Cadmium and lead in filter glasses and glasses used for reflectance standards",
    "14. Lead in solders consisting of more than two elements for the connection between the pins and the package of microprocessors with a lead content of more than 80% and less than 85% by weight",
    "15. Lead in solders to complete a viable electrical connection between semiconductor die and carrier within integrated circuit flip chip packages",
    "16. Lead in linear incandescent lamps with silicate coated tubes",
    "17. Lead halide as radiant agent in high intensity discharge (HID) lamps used for professional reprography applications",
    "18(a). Lead as activator in the fluorescent powder (1% lead by weight or less) of discharge lamps when used as speciality lamps for diazoprinting reprography, lithography, insect traps, photochemical and curing processes containing phosphors such as SMS ((Sr,Ba)2 MgSi2O7:Pb)",
    "18(b). Lead as activator in the fluorescent powder (1% lead by weight or less) of discharge lamps when used as sun tanning lamps containing phosphors such as BSP (BaSi 2 O 5 :Pb)",
    "19. Lead with PbBiSn-Hg and PbInSn-Hg in specific compositions as main amalgam and with PbSn-Hg as auxiliary amalgam in very compact energy saving lamps (ESL)",
    "20. Lead oxide in glass used for bonding front and rear substrates of flat fluorescent lamps used for Liquid Crystal Displays (LCDs)",
    "21. Lead and cadmium in printing inks for the application of enamels on glasses, such as borosilicate and soda lime glasses",
    "23. Lead in finishes of fine pitch components other than connectors with a pitch of 0.65 mm and less",
    "24. Lead in solders for the soldering to machined through hole discoidal and planar array ceramic multilayer capacitors",
    "25. Lead oxide in surface conduction electron emitter displays (SED) used in structural elements, notably in the seal frit and frit ring",
    "26. Lead oxide in the glass envelope of black light blue lamps",
    "27. Lead alloys as solder for transducers used in high-powered (designated to operate for several hours at acoustic power levels of 125 dB SPL and above) loudspeakers",
    "29. Lead bound in crystal glass as defined in Annex I (Categories 1, 2, 3 and 4) of Council Directive 69/493/EEC ( 1 )",
    "30. Cadmium alloys as electrical/mechanical solder joints to electrical conductors located directly on the voice coil in transducers used in high-powered loudspeakers with sound pressure levels of 100 dB (A) and more",
    "31. Lead in soldering materials in mercury free flat fluorescent lamps (which e.g. are used for liquid crystal displays, design or industrial lighting)",
    "32. Lead oxide in seal frit used for making window assemblies for Argon and Krypton laser tubes",
    "33. Lead in solders for the soldering of thin copper wires of 100 micrometer diameter and less in power transformers",
    "34. Lead in cermet-based trimmer potentiometer elements",
    "36. Mercury used as a cathode sputtering inhibitor in DC plasma displays with a content up to 30 mg per display",
    "37. Lead in the plating layer of high voltage diodes on the basis of a zinc borate glass body",
    "38. Cadmium and cadmium oxide in thick film pastes used on aluminium bonded beryllium oxide",
    "39. Cadmium in colour converting II-VI LEDs (less than 10 microgram Cd per mm2 of light-emitting area) for use in solid state illumination or display systems",
    "40. Cadmium in photoresistors for analogue optocouplers applied in professional audio equipment",
    "41. Lead in solders and termination finishes of electrical and electronic components and finishes of printed circuit boards used in ignition modules and other electrical and electronic engine control systems, which for technical reasons must be mounted directly on or in the crankcase or cylinder of hand-held combustion engines (classes SH:1, SH:2, SH:3 of Directive 97/68/EC of the European Parliament and of the Council(*)",
  ];
  const exemptionListVersion_EL2011_65_EU_ANNEX_IV = [
    "1. Lead, cadmium and mercury in detectors for ionising radiation.",
    "1a. Lead and cadmium in ion selective electrodes including glass of pH electrodes.",
    "1b. Lead anodes in electrochemical oxygen sensors.",
    "1c. Lead, cadmium and mercury in infra-red light detectors.",
    "1d. Mercury in reference electrodes: low chloride mercury chloride, mercury sulphate and mercury oxide.",
    "2. Lead bearings in X-ray tubes.",
    "3. Lead in electromagnetic radiation amplification devices: micro-channel plate and capillary plate.",
    "4. Lead in glass frit of X-ray tubes and image intensifiers and lead in glass frit binder for assembly of gas lasers and for vacuum tubes that convert electromagnetic radiation into electrons.",
    "5. Lead in shielding for ionising radiation.",
    "6. Lead in X-ray test objects.",
    "7. Lead stearate X-ray diffraction crystals.",
    "8. Radioactive cadmium isotope source for portable X-ray fluorescence spectrometers.",
    "9. Cadmium in helium-cadmium lasers.",
    "10. Lead and cadmium in atomic absorption spectroscopy lamps.",
    "11. Lead in alloys as a superconductor and thermal conductor in MRI.",
    "12. Lead and cadmium in metallic bonds creating superconducting magnetic circuits in MRI, SQUID, NMR (Nuclear Magnetic Resonance) or FTMS (Fourier Transform Mass Spectrometer) detectors.",
    "13. Lead in counterweights.",
    "14. Lead in single crystal piezoelectric materials for ultrasonic transducers.",
    "15. Lead in solders for bonding to ultrasonic transducers.",
    "16. Mercury in very high accuracy capacitance and loss measurement bridges and in high frequency RF switches and relays in monitoring and control instruments not exceeding 20 mg of mercury per switch or relay.",
    "17. Lead in solders in portable emergency defibrillators.",
    "18. Lead in solders of high performance infrared imaging modules to detect in the range 8-14 micrometer.",
    "19. Lead in Liquid crystal on silicon (LCoS) displays.",
    "20. Cadmium in X-ray measurement filters.",
    "21. Cadmium in phosphor coatings in image intensifiers for X-ray images until 31 December 2019 and in spare parts for X-ray systems placed on the EU market before 1 January 2020.",
    "22. Lead acetate marker for use in stereotactic head frames for use with CT and MRI and in positioning systems for gamma beam and particle therapy equipment.",
    "23. Lead as an alloying element for bearings and wear surfaces in medical equipment exposed to ionising radiation.",
    "24. Lead enabling vacuum tight connections between aluminium and steel in X-ray image intensifiers.",
    "25. Lead in the surface coatings of pin connector systems requiring nonmagnetic connectors which are used durably at a temperature below -20°C under normal operating and storage conditions.",
    "26. Lead in solders on printed circuit boards, termination coatings of electrical and electronic components and coatings of printed circuit boards, solders for connecting wires and cables, solders connecting transducers and sensors, that are used durably at a temperature below -20°C under normal operating and storage conditions.",
    "27. Lead in solders, termination coatings of electrical and electronic components and printed circuit boards, connections of electrical wires, shields and enclosed connectors, which are used in (a) magnetic fields within the sphere of 1 m radius around the isocenter of the magnet in medical magnetic resonance imaging equipment, including patient monitors designed to be used within this sphere, or (b) magnetic fields within 1 m distance from the external surfaces of cyclotron magnets, magnets for beam transport and beam direction control applied for particle therapy.",
    "28. Lead in solders for mounting cadmium telluride and cadmium zinc telluride digital array detectors to printed circuit boards.",
    "29. Lead in alloys, as a superconductor or thermal conductor, used in cryo-cooler cold heads and/or in cryo-cooled cold probes and/or in cryo-cooled equipotential bonding systems, in medical devices (category 8) and/or in industrial monitoring and control instruments.",
    "30. Hexavalent chromium in alkali dispensers used to create photocathodes in X-ray image intensifiers until 31 December 2019 and in spare parts for X-ray systems placed on the EU market before 1 January 2020.",
    "31. Lead, cadmium and hexavalent chromium in reused spare parts, recovered from medical devices placed on the market before 22 July 2014 and used in category 8 equipment placed on the market before 22 July 2021, provided that reuse takes place in auditable closed-loop business-to-business return systems, and that the reuse of parts is notified to the consumer.",
    "32. Lead in solders on printed circuit boards of detectors and data acquisition units for Positron Emission Tomographs which are integrated into Magnetic Resonance Imaging equipment.",
    "33. Lead in solders on populated printed circuit boards used in Directive 93/42/EEC class IIa and IIb mobile medical devices other than portable emergency defibrillators. Expires on 30 June 2016 for class IIa and on 31 December 2020 for class IIb.",
    "34. Lead as an activator in the fluorescent powder of discharge lamps when used for extracorporeal photopheresis lamps containing BSP (BaSi2O5:Pb) phosphors.",
  ];
  const exemptionListVersion_EL2011_534_EU_Options: SelectProps["options"] =
    exemptionListVersion_EL2011_534_EU.map((item) => ({
      label: item,
      value: item,
    }));

  const exemptionListVersion_EL2011_65_EU_ANNEX_IV_Options: SelectProps["options"] =
    exemptionListVersion_EL2011_65_EU_ANNEX_IV.map((item) => ({
      label: item,
      value: item,
    }));
  const [fields_534_EU, setFields_534_EU] = useState<string[]>(
    xmlData ? xmlData.exemptionListVersion_534_EU : [""]
  );
  const [fields_65_EU_ANNEX_IV, setFields_65_EU_ANNEX_IV] = useState<string[]>(
    xmlData ? xmlData.exemptionListVersion_65_EU_ANNEX_IV : [""]
  );
  const [showInput, setShowInput] = useState(
    (xmlData &&
      xmlData?.rohsDeclaration ===
        "3 - Item(s) does not contain RoHS restricted substances per the definition above except for lead in solders and selected exemptions, if any") ||
      (xmlData &&
        xmlData?.rohsDeclaration ===
          "4 - Item(s) does not contain RoHS restricted substances per the definition above except for selected exemptions")
      ? true
      : false
  );

  const handleSelectChange = (value: string) => {
    if (
      value ===
        "3 - Item(s) does not contain RoHS restricted substances per the definition above except for lead in solders and selected exemptions, if any" ||
      value ===
        "4 - Item(s) does not contain RoHS restricted substances per the definition above except for selected exemptions"
    ) {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };

  const [items, setItems] = useState<Item[]>(
    xmlData
      ? xmlData.items
      : [
          {
            name: "",
            weight: 0,
            uom: "",
            unitType: "",
            materials: [
              {
                name: "",
                weight: 0,
                uom: "",
                level: "",
                substanceCategory: "",
                substances: [
                  {
                    substance: "",
                    cas: "",
                    exempt: "",
                    weight: 0,
                    uom: "",
                    toleranceM: 0,
                    toleranceP: 0,
                    ppm: 0,
                  },
                ],
              },
            ],
          },
        ]
  );

  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(0);
  const [selectedMaterialsIndex, setSelectedMaterialsIndex] = useState<
    number | null
  >(0);

  const addItemField = () => {
    setItems([
      ...items,
      {
        name: "",
        weight: 0,
        uom: "",
        unitType: "",
        materials: [
          {
            name: "",
            weight: 0,
            uom: "",
            level: "",
            substanceCategory: "",
            substances: [
              {
                substance: "",
                cas: "",
                exempt: "",
                weight: 0,
                uom: "",
                toleranceM: 0,
                toleranceP: 0,
                ppm: 0,
              },
            ],
          },
        ],
      },
    ]);
    setSelectedItemIndex(items.length);
    setSelectedMaterialsIndex(0);
  };

  const addListField = () => {
    if (selectedItemIndex !== null) {
      const updatedItems = [...items];
      updatedItems[selectedItemIndex].materials.push({
        name: "",
        weight: 0,
        uom: "",
        level: "",
        substanceCategory: "",
        substances: [
          {
            substance: "",
            cas: "",
            exempt: "",
            weight: 0,
            uom: "",
            toleranceM: 0,
            toleranceP: 0,
            ppm: 0,
          },
        ],
      });

      setItems(updatedItems);
      setSelectedMaterialsIndex(
        updatedItems[selectedItemIndex].materials.length - 1
      );
    }
  };

  const addSubListField = () => {
    if (selectedItemIndex !== null && selectedMaterialsIndex !== null) {
      const updatedItems = [...items];
      updatedItems[selectedItemIndex]?.materials[
        selectedMaterialsIndex
      ]?.substances?.push({
        substance: "",
        cas: "",
        exempt: "",
        weight: 0,
        uom: "",
        toleranceM: 0,
        toleranceP: 0,
        ppm: 0,
      });
      setItems(updatedItems);
    }
  };

  const removeItemField = (index: number) => {
    const updatedItems = items.filter((_, itemIndex) => itemIndex !== index);
    setItems(updatedItems);

    if (updatedItems.length === 0) {
      setSelectedItemIndex(null);
      setSelectedMaterialsIndex(null);
    } else if (index === selectedItemIndex) {
      setSelectedItemIndex(0);
      setSelectedMaterialsIndex(0);
    } else if (index < selectedItemIndex!) {
      setSelectedItemIndex(selectedItemIndex! - 1);
    }
  };
  const removeListField = (listIndex: number) => {
    if (selectedItemIndex !== null) {
      const updatedItems = [...items];
      const lists = updatedItems[selectedItemIndex].materials.filter(
        (_, index) => index !== listIndex
      );
      updatedItems[selectedItemIndex].materials = lists;
      setItems(updatedItems);

      if (lists.length === 0) {
        setSelectedMaterialsIndex(null);
      } else if (listIndex === selectedMaterialsIndex) {
        setSelectedMaterialsIndex(0);
      } else if (listIndex < selectedMaterialsIndex!) {
        setSelectedMaterialsIndex(selectedMaterialsIndex! - 1);
      }
    }
  };

  const removeSubListField = (subListIndex: number) => {
    if (selectedItemIndex !== null && selectedMaterialsIndex !== null) {
      const updatedItems = [...items];
      const subLists = updatedItems[selectedItemIndex]?.materials[
        selectedMaterialsIndex
      ]?.substances?.filter((_, index) => index !== subListIndex);
      updatedItems[selectedItemIndex].materials[
        selectedMaterialsIndex
      ].substances = subLists;
      setItems(updatedItems);
    }
  };
  const declarationTypeOptions = [
    {
      value: "Standard",
      label: "Standard",
      text: "Supplier certifies that it gathered the provided information and such information is true and correct to the best of its knowledge and belief, as of the date that Supplier completes this form. Supplier acknowledges that Company will rely on this certification in determining the compliance of its products. Company acknowledges that Supplier may have relied on information provided by others in completing this form, and that Supplier may not have independently verified such information. However, in situations where Supplier has not independently verified information provided by others, Supplier agrees that, at a minimum, its suppliers have provided certifications regarding their contributions to the part(s), and those certifications are at least as comprehensive as the certification in this paragraph. If the Company and the Supplier enter into a written agreement with respect to the identified part(s), the terms and conditions of that agreement, including any warranty rights and/or remedies provided as part of that agreement, will be the sole and exclusive source of the Supplier's liability and the Company's remedies for issues that arise regarding information the Supplier provides in this form.",
    },
    { value: "Custom", label: "Custom", text: "" },
  ];
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <Card
        style={{
          background: getLinearGradient("#00516E"),
          borderRadius: 0,
          height: "150px",
          margin: 0,
          padding: 0,
          width: "100%",
        }}
        bordered={false}
      >
        <Row style={{ margin: 0, padding: 0, width: "100%" }}>
          <Col
            span={4}
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Image
              src={vectorOutlined1}
              alt="vector"
              width={180}
              preview={false}
              style={{ margin: 0, padding: 0 }}
            />
          </Col>
          <Col span={16}>
            <Title
              level={2}
              style={{
                color: "white",
                margin: 0,
                justifyContent: "center",
                display: "flex",
              }}
            >
              ACQUIS COMPLIANCE
            </Title>
            <Title
              level={4}
              style={{
                color: "white",
                margin: 0,
                justifyContent: "center",
                display: "flex",
              }}
            >
              Material Declaration Tool
            </Title>
            <Title
              level={5}
              style={{
                color: "white",
                justifyContent: "center",
                display: "flex",
              }}
            >
              IPC-1752A
            </Title>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              justifyContent: "end",
              padding: 0,
            }}
          >
            <Image
              src={vectorOutlined2}
              alt="vector"
              width={180}
              preview={false}
            />
          </Col>
        </Row>
      </Card>
      <Card>
        <Steps
          size="small"
          items={steps}
          style={{ paddingLeft: "50px", paddingRight: "50px" }}
          current={currentStep}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "3rem",
          }}
        >
          {actionCards}
        </div>
        <Form
          form={form}
          initialValues={xmlData}
          layout="vertical"
          autoComplete="off"
        >
          <Card
            style={{ display: currentStep === 0 ? "block" : "none" }}
            bordered={false}
          >
            <Row gutter={24} style={{ marginTop: "3rem" }}>
              <Col span={12}>
                <Form.Item
                  name="formType"
                  label="Form Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select from the list",
                    },
                  ]}
                >
                  <Select
                    disabled={fieldLock}
                    onSelect={(value: string) => {
                      setFormTypeSelect(value);
                    }}
                    placeholder={"Select Form Type"}
                    options={[
                      { value: "Request/Reply", label: "Request/Reply" },
                      { value: "Distribute", label: "Distribute" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="declarationClass"
                  label="Declaration Class"
                  rules={[
                    {
                      required: true,
                      message: "Please select from the list",
                    },
                  ]}
                >
                  <Select
                    disabled={fieldLock}
                    onSelect={(value: string) => {
                      setDeclarationClassSelect(value);
                    }}
                    placeholder={"Select Type"}
                    options={[
                      {
                        value: "Class 1 – RoHS Yes/No",
                        label: "Class 1 – RoHS Yes/No",
                      },
                      {
                        value: "Class 2 - RoHS Yes/No Mfg Process Information",
                        label: "Class 2 - RoHS Yes/No Mfg Process Information",
                      },
                      {
                        value: "Class 5 - RoHS Yes/No Homogeneous Materials",
                        label: "Class 5 - RoHS Yes/No Homogeneous Materials",
                      },
                      {
                        value:
                          "Class 6 - RoHS Yes/No Homogeneous Materials and Mfg Information",
                        label:
                          "Class 6 - RoHS Yes/No Homogeneous Materials and Mfg Information",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card
            style={{
              display: currentStep === 1 ? "block" : "none",
              marginTop: "1rem",
              overflow: "auto",
            }}
            bordered={false}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              {formTypeSelect === "Request/Reply" && (
                <Collapse
                  collapsible="header"
                  expandIcon={() => <CaretRightOutlined rotate={90} />}
                  expandIconPosition="end"
                  size="small"
                  style={{ borderRadius: 0 }}
                  items={[
                    {
                      key: "1",
                      label: (
                        <Title level={5} style={{ color: "#00516E" }}>
                          Requester Information
                        </Title>
                      ),
                      children: (
                        <Card>
                          <Row gutter={24}>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "companyName"]}
                                label={"Company Name"}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please Enter Company Name",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={[
                                  "requesterInformation",
                                  "companyUniqueId",
                                ]}
                                label={"Company Unique ID"}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={[
                                  "requesterInformation",
                                  "uniqueIdAuthority",
                                ]}
                                label={"Unique ID Authority"}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "responseDate"]}
                                label={"Response Date"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Response Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  disabled={fieldLock}
                                  placeholder="YYYY-MM-DD"
                                  style={{ width: "100%" }}
                                  format={"YYYY-MM-DD"}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col span={6}>
                              <Form.Item
                                name={[
                                  "requesterInformation",
                                  "requestDocumentId",
                                ]}
                                label={"Request Document ID"}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "respondByDate"]}
                                label={"Respond By Date"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Respond By Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  disabled={fieldLock}
                                  placeholder="YYYY-MM-DD"
                                  style={{ width: "100%" }}
                                  format={"YYYY-MM-DD"}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "contactName"]}
                                label={"Contact Name"}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please Enter Contact Name",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "contactTitle"]}
                                label={"Contact Title"}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please Enter Contact Title",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "contactPhone"]}
                                label={"Contact Phone"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Contact Phone",
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (xmlData && value) {
                                        return Promise.resolve();
                                      }

                                      const phoneRegex =
                                        /^(\+\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;
                                      if (!value) {
                                        return Promise.reject(
                                          "Phone number is required"
                                        );
                                      } else if (!phoneRegex.test(value)) {
                                        return Promise.reject(
                                          "Phone Number must be valid"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "contactEmail"]}
                                label={"Contact Email"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter email address",
                                  },
                                  {
                                    pattern: new RegExp(
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ),
                                    message:
                                      "Please input a valid email address",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[
                                  "requesterInformation",
                                  "requesterComments",
                                ]}
                                label={
                                  "Requester Comments or URL for Additional Information"
                                }
                                // rules={[
                                //   {
                                //     required: true,
                                //     whitespace: true,
                                //     message:
                                //       "Please Enter Requester Comments or URL for Additional Information",
                                //   },
                                // ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "mySupplierId"]}
                                label={"My Supplier ID"}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["requesterInformation", "fileType"]}
                                label="File Type"
                              >
                                <Select
                                  disabled={fieldLock}
                                  placeholder={"Select"}
                                  options={[
                                    { value: "PDF", label: "PDF" },
                                    { value: "XML", label: "XML" },
                                    { value: "XDP", label: "XDP" },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={[
                                  "requesterInformation",
                                  "destinationEmail",
                                ]}
                                label={"Destination - URL or Email Address"}
                                rules={[
                                  {
                                    pattern: new RegExp(
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ),
                                    message:
                                      "Please input a valid email address",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={[
                                  "requesterInformation",
                                  "mfrItemVersionManufacturingSite",
                                ]}
                                valuePropName="checked"
                              >
                                <Checkbox
                                  defaultChecked={false}
                                  checked={supplierCheckbox}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setSupplierCheckbox(isChecked);

                                    form.setFieldsValue({
                                      requesterInformation: {
                                        mfrItemVersionManufacturingSite:
                                          isChecked,
                                      },
                                    });
                                  }}
                                >
                                  Supplier provides Mfr Item Version &
                                  Manufacturing Site
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Card style={{ backgroundColor: "#F5F5F5" }}>
                            <Row gutter={24}>
                              <Col span={6}>
                                <Form.Item
                                  name={["requesterInformation", "itemNumber"]}
                                  label={"Item Number"}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please Enter Item Number",
                                    },
                                  ]}
                                >
                                  <Input
                                    disabled={fieldLock}
                                    placeholder="Enter"
                                    onChange={(e) => {
                                      form.setFieldsValue({
                                        supplierInformation: {
                                          requesterItemNumber: e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={["requesterInformation", "itemName"]}
                                  label={"Item Name"}
                                >
                                  <Input
                                    disabled={fieldLock}
                                    placeholder="Enter"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "requesterInformation",
                                    "mfrItemVersion",
                                  ]}
                                  label={"Mfr Item Version"}
                                  rules={[
                                    {
                                      required: !supplierCheckbox,
                                      whitespace: true,
                                      message: "Please Enter Mfr Item Version",
                                    },
                                  ]}
                                >
                                  <Input
                                    disabled={fieldLock || supplierCheckbox}
                                    placeholder="Enter"
                                    onChange={(e) => {
                                      form.setFieldsValue({
                                        supplierInformation: {
                                          version: e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "requesterInformation",
                                    "manufacturingSite",
                                  ]}
                                  label={"Manufacturing Site"}
                                >
                                  <Input
                                    disabled={fieldLock || supplierCheckbox}
                                    placeholder="Enter"
                                    onChange={(e) => {
                                      form.setFieldsValue({
                                        supplierInformation: {
                                          manufacturingSite: e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Card>
                      ),
                      forceRender: true,
                    },
                  ]}
                />
              )}
              <Collapse
                collapsible="header"
                expandIcon={() => <CaretRightOutlined rotate={90} />}
                expandIconPosition="end"
                size="small"
                style={{ borderRadius: 0 }}
                items={[
                  {
                    key: "2",
                    label: (
                      <Title level={5} style={{ color: "#00516E" }}>
                        Supplier Information
                      </Title>
                    ),
                    children: (
                      <Card>
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item
                              name={["supplierInformation", "companyName"]}
                              label={"Company Name"}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please Enter Company Name",
                                },
                              ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={["supplierInformation", "companyUniqueId"]}
                              label={"Company Unique ID"}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={[
                                "supplierInformation",
                                "uniqueIdAuthority",
                              ]}
                              label={"Unique ID Authority"}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={["supplierInformation", "responseDate"]}
                              label={"Response Date"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Response Date",
                                },
                              ]}
                            >
                              <DatePicker
                                disabled={fieldLock}
                                placeholder="YYYY-MM-DD"
                                style={{ width: "100%" }}
                                format={"YYYY-MM-DD"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item
                              name={["supplierInformation", "contactName"]}
                              label={"Contact Name"}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please Enter Contact Name",
                                },
                              ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={["supplierInformation", "titleContact"]}
                              label={"Title Contact"}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={["supplierInformation", "phoneContact"]}
                              label={"Phone Contact"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Phone Contact",
                                },
                                {
                                  validator: (_, value) => {
                                    if (xmlData && value) {
                                      return Promise.resolve();
                                    }

                                    const phoneRegex =
                                      /^(\+\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;
                                    if (!value) {
                                      return Promise.reject(
                                        "Phone number is required"
                                      );
                                    } else if (!phoneRegex.test(value)) {
                                      return Promise.reject(
                                        "Phone Number must be valid"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={["supplierInformation", "emailContact"]}
                              label={"Email Contact"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter email address",
                                },
                                {
                                  pattern: new RegExp(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                  ),
                                  message: "Please input a valid email address",
                                },
                              ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div style={{ marginBottom: "30px" }}>
                              <Button
                                type="default"
                                style={{
                                  color: "#F7863E",
                                  borderColor: "#F7863E",
                                }}
                                disabled={fieldLock}
                                onClick={() => {
                                  form.setFieldsValue({
                                    supplierInformation: {
                                      authorisedRepresentative:
                                        form.getFieldValue([
                                          "supplierInformation",
                                          "contactName",
                                        ]),

                                      titleRepresentative: form.getFieldValue([
                                        "supplierInformation",
                                        "titleContact",
                                      ]),
                                      phoneRepresentative: form.getFieldValue([
                                        "supplierInformation",
                                        "phoneContact",
                                      ]),
                                      emailRepresentative: form.getFieldValue([
                                        "supplierInformation",
                                        "emailContact",
                                      ]),
                                    },
                                  });
                                }}
                              >
                                Copy Contact Details to Authorization
                                Representative
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item
                              name={[
                                "supplierInformation",
                                "authorisedRepresentative",
                              ]}
                              label={"Authorised Representative"}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Please Enter Authorised Representative",
                                },
                              ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={[
                                "supplierInformation",
                                "titleRepresentative",
                              ]}
                              label={"Title Representative"}
                              // rules={[
                              //   {
                              //     required: true,
                              //     whitespace: true,
                              //     message: "Please Enter Title Representative",
                              //   },
                              // ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={[
                                "supplierInformation",
                                "phoneRepresentative",
                              ]}
                              label={"Phone Representative"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Phone Representative",
                                },
                                {
                                  validator: (_, value) => {
                                    if (xmlData && value) {
                                      return Promise.resolve();
                                    }

                                    const phoneRegex =
                                      /^(\+\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;
                                    if (!value) {
                                      return Promise.reject(
                                        "Phone number is required"
                                      );
                                    } else if (!phoneRegex.test(value)) {
                                      return Promise.reject(
                                        "Phone Number must be valid"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name={[
                                "supplierInformation",
                                "emailRepresentative",
                              ]}
                              label={"Email Representative"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter email address",
                                },
                                {
                                  pattern: new RegExp(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                  ),
                                  message: "Please input a valid email address",
                                },
                              ]}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item
                              name={[
                                "supplierInformation",
                                "responseDocumentId",
                              ]}
                              label={"Response Document ID"}
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name={["supplierInformation", "supplierComments"]}
                              label={
                                "Supplier Comments or URL for Additional Information"
                              }
                            >
                              <Input disabled={fieldLock} placeholder="Enter" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Card style={{ backgroundColor: "#F5F5F5" }}>
                          <Row gutter={24}>
                            <Col span={6}>
                              <Form.Item
                                name={[
                                  "supplierInformation",
                                  "requesterItemNumber",
                                ]}
                                label={"Requester Item Number"}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["supplierInformation", "mfrItemNumber"]}
                                label={"Mfr Item Number"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Mfr Item Number",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["supplierInformation", "mfrItemName"]}
                                label={"Mfr Item Name"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Mfr Item Name",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["supplierInformation", "effectiveDate"]}
                                label={"Effective Date"}
                              >
                                <DatePicker
                                  disabled={fieldLock}
                                  placeholder="YYYY-MM-DD"
                                  style={{ width: "100%" }}
                                  format={"YYYY-MM-DD"}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col span={6}>
                              <Form.Item
                                name={["supplierInformation", "version"]}
                                label={"Version"}
                              >
                                <Input
                                  disabled={fieldLock || !supplierCheckbox}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={[
                                  "supplierInformation",
                                  "manufacturingSite",
                                ]}
                                label={"Manufacturing Site"}
                              >
                                <Input
                                  disabled={fieldLock || !supplierCheckbox}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={["supplierInformation", "weight"]}
                                label={"Weight"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Weight",
                                  },
                                ]}
                              >
                                <InputNumber
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                  style={{ width: "100%" }}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <Form.Item
                                name={["supplierInformation", "uom"]}
                                label="UoM"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select UoM",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={fieldLock}
                                  placeholder={"Select"}
                                  options={[
                                    { value: "mg", label: "mg" },
                                    { value: "g", label: "g" },
                                    { value: "kg", label: "kg" },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <Form.Item
                                name={["supplierInformation", "unitType"]}
                                label="Unit Type"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Unit Type",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={fieldLock}
                                  placeholder={"Select"}
                                  options={[
                                    { value: "Each", label: "Each" },
                                    { value: "Meter", label: "Meter" },
                                    { value: "m^2", label: "m^2" },
                                    { value: "m^3", label: "m^3" },
                                    { value: "cm", label: "cm" },
                                    { value: "cm^2", label: "cm^2" },
                                    { value: "cm^3", label: "cm^3" },
                                    { value: "Liter", label: "Liter" },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col span={12}>
                              <Form.Item
                                name={[
                                  "supplierInformation",
                                  "alternateRecommendation",
                                ]}
                                label={"Alternate Recommendation"}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[
                                  "supplierInformation",
                                  "alternateItemComments",
                                ]}
                                label={"Alternate Item Comments"}
                              >
                                <Input
                                  disabled={fieldLock}
                                  placeholder="Enter"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Card>
                    ),
                    forceRender: true,
                  },
                ]}
              />
              {declarationClassSelect ===
                "Class 2 - RoHS Yes/No Mfg Process Information" ||
              declarationClassSelect ===
                "Class 6 - RoHS Yes/No Homogeneous Materials and Mfg Information" ? (
                <Collapse
                  collapsible="header"
                  expandIcon={() => <CaretRightOutlined rotate={90} />}
                  expandIconPosition="end"
                  size="small"
                  style={{ borderRadius: 0 }}
                  items={[
                    {
                      key: "3",
                      label: (
                        <Title level={5} style={{ color: "#00516E" }}>
                          Manufacturing Process Information
                        </Title>
                      ),
                      children: (
                        <div>
                          <Card>
                            <Row gutter={24}>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "manufacturingProcessInformation",
                                    "gridArrayMaterial",
                                  ]}
                                  label="Terminal Plating / Grid Array Material"
                                >
                                  <Select
                                    disabled={fieldLock}
                                    placeholder={"Select"}
                                    options={gridArrayMaterialOptions}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "manufacturingProcessInformation",
                                    "terminalBaseAlloy",
                                  ]}
                                  label="Terminal Base Alloy"
                                >
                                  <Select
                                    disabled={fieldLock}
                                    placeholder={"Select"}
                                    options={terminalBaseAlloyOptions}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "manufacturingProcessInformation",
                                    "mslRating",
                                  ]}
                                  label="J-STD-020 MSL Rating"
                                >
                                  <Select
                                    disabled={fieldLock}
                                    placeholder={"Select"}
                                    options={mslRatingOptions}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "manufacturingProcessInformation",
                                    "peakProcessBodyTemperature",
                                  ]}
                                  label={
                                    "Peak Process Body Temperature - in Celsius"
                                  }
                                >
                                  <InputNumber
                                    disabled={fieldLock}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "manufacturingProcessInformation",
                                    "maxTimeAtPeakTemperature",
                                  ]}
                                  label={
                                    "Max Time at Peak Temperature - in Seconds"
                                  }
                                >
                                  <InputNumber
                                    disabled={fieldLock}
                                    style={{ width: "100%" }}
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={[
                                    "manufacturingProcessInformation",
                                    "numberOfReflowCycles",
                                  ]}
                                  label="Number of Reflow Cycles"
                                >
                                  <Select
                                    placeholder={"Select"}
                                    disabled={fieldLock}
                                  >
                                    <Select.Option key={""} value={""}>
                                      {""}
                                    </Select.Option>
                                    {Array.from({ length: 10 }, (_, i) => (
                                      <Select.Option key={i + 1} value={i + 1}>
                                        {i + 1}
                                      </Select.Option>
                                    ))}
                                    <Select.Option
                                      key={"Not Applicable"}
                                      value={"Not Applicable"}
                                    >
                                      Not Applicable
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[
                                    "manufacturingProcessInformation",
                                    "comments",
                                  ]}
                                  label={"Comments"}
                                >
                                  <Input
                                    disabled={fieldLock}
                                    placeholder="Enter"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </div>
                      ),
                      forceRender: true,
                    },
                  ]}
                />
              ) : (
                <Title level={5}>
                  Manufacturing Information section intentionally omitted
                </Title>
              )}
            </Space>
          </Card>
          <Card
            style={{
              display: currentStep === 2 ? "block" : "none",
              marginTop: "1rem",
              overflow: "auto",
            }}
            bordered={false}
          >
            <Form.Item
              name="declarationType"
              label="Declaration Type"
              rules={[
                {
                  required: true,
                  message: "Please select from the list",
                },
              ]}
            >
              <Select
                disabled={fieldLock}
                placeholder={"Select Declaration Type"}
                onSelect={(value: string) => {
                  const selectedOption = declarationTypeOptions.find(
                    (option) => option.value === value
                  );
                  setLegalDef(selectedOption?.value || "");
                  form.setFieldsValue({
                    declarationTypeDescription: selectedOption?.text || "",
                  });
                }}
              >
                {declarationTypeOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="declarationTypeDescription"
              rules={[
                {
                  whitespace: true,
                  required: legalDef === "Custom" ? true : false,
                  message: "Please Enter your statement",
                },
              ]}
            >
              <TextArea
                disabled={fieldLock}
                rows={7}
                readOnly={legalDef !== "Custom"}
                placeholder={
                  legalDef === "Custom" ? "Enter your statement here" : ""
                }
              ></TextArea>
            </Form.Item>
            <Row gutter={24}>
              <Col span={16}>
                <Form.Item
                  name="rohsDeclaration"
                  label="RoHS Declaration"
                  rules={[
                    {
                      required: true,
                      message: "Please select from the list",
                    },
                  ]}
                >
                  <Select
                    disabled={fieldLock}
                    placeholder={"Enter Version"}
                    options={options}
                    onChange={handleSelectChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="supplierAcceptance"
                  label="Supplier Acceptance"
                  rules={[
                    {
                      required: true,
                      message: "Please select from the list",
                    },
                  ]}
                >
                  <Select
                    disabled={fieldLock}
                    placeholder={"Select"}
                    options={[
                      { value: "true", label: "Accepted" },
                      { value: "false", label: "Not Accepted" },
                    ]}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={7}>
                <Form.Item
                  name="declarationSignature"
                  label={"Declaration Signature"}
                >
                  <Input disabled={fieldLock} placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Tooltip
                  color={"white"}
                  title={
                    <Text>
                      <strong>Instructions:</strong> Complete all of the
                      required fields on all pages of this form. Select the
                      &ldquo;Accepted&ldquo; on the Supplier Acceptance
                      drop-down. This will display the signature area. Digitally
                      sign the declaration (if required by the Requester) and
                      click on Submit Form to have the form returned to the
                      Requester.
                    </Text>
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Col> */}
            </Row>
            {showInput && (
              <Form.Item
                name={"rohsExemptions"}
                label={"RoHS Exemptions"}
                // style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please select RoHS Exemptions",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select RoHS Exemptions"
                  onChange={(value) => {
                    setSelectedRohsExemptions(value);
                  }}
                  options={[
                    { value: "EL2011/534/EU", label: "EL2011/534/EU" },
                    {
                      value: "EL2011/65/EU_ANNEX_IV",
                      label: "EL2011/65/EU_ANNEX_IV",
                    },
                  ]}
                  disabled={fieldLock}
                />
              </Form.Item>
            )}
            {showInput && selectedRohsExemptions?.includes("EL2011/534/EU") && (
              <>
                {fields_534_EU.map((field, index) => (
                  <Row key={index} style={{ marginTop: 10 }} gutter={8}>
                    <Col flex="auto">
                      <Form.Item
                        name={["exemptionListVersion_534_EU", index]}
                        label={
                          index === 0 &&
                          "Exemption List Version - EL2011/534/EU"
                        }
                        style={{ marginBottom: 0 }}
                        rules={[
                          {
                            required: true,
                            message: "Please select a version",
                          },
                        ]}
                      >
                        <Row>
                          <Col>
                            {!fieldLock &&
                              (index === 0 ? (
                                <MinusCircleTwoTone
                                  onClick={() => {
                                    const updatedFields = [...fields_534_EU];
                                    updatedFields[0] = "";
                                    setFields_534_EU(updatedFields);
                                    form.setFieldsValue({
                                      exemptionListVersion_534_EU:
                                        updatedFields,
                                    });
                                  }}
                                  style={{
                                    marginLeft: 10,
                                    fontSize: "20px",
                                  }}
                                />
                              ) : (
                                <MinusCircleTwoTone
                                  onClick={() => {
                                    const updatedFields = fields_534_EU.filter(
                                      (_, i) => i !== index
                                    );
                                    setFields_534_EU(updatedFields);
                                    form.setFieldsValue({
                                      exemptionListVersion_534_EU:
                                        updatedFields,
                                    });
                                  }}
                                  style={{
                                    marginLeft: 10,
                                    fontSize: "20px",
                                  }}
                                />
                              ))}
                          </Col>
                          <Col>
                            {!fieldLock && (
                              <PlusCircleTwoTone
                                onClick={() => {
                                  setFields_534_EU([...fields_534_EU, ""]);
                                  form.setFieldsValue({
                                    exemptionListVersion_534_EU: [
                                      ...fields_534_EU,
                                      "",
                                    ],
                                  });
                                }}
                                style={{ marginLeft: 10, fontSize: "20px" }}
                              />
                            )}
                          </Col>
                          <Col flex="auto">
                            <Form.Item
                              name={["exemptionListVersion_534_EU", index]}
                              noStyle
                            >
                              <Select
                                disabled={fieldLock}
                                value={fields_534_EU[index]}
                                onChange={(value) => {
                                  const updatedFields = [...fields_534_EU];
                                  updatedFields[index] = value;
                                  setFields_534_EU(updatedFields);
                                }}
                                placeholder={"Select"}
                                options={
                                  exemptionListVersion_EL2011_534_EU_Options
                                }
                                style={{ marginLeft: 10 }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </>
            )}
            {showInput &&
              selectedRohsExemptions?.includes("EL2011/65/EU_ANNEX_IV") && (
                <>
                  {fields_65_EU_ANNEX_IV.map((field, index) => (
                    <Row key={index} style={{ marginTop: 10 }} gutter={8}>
                      <Col flex="auto">
                        <Form.Item
                          name={["exemptionListVersion_65_EU_ANNEX_IV", index]}
                          label={
                            index === 0 &&
                            "Exemption List Version - EL2011/65/EU_ANNEX_IV"
                          }
                          style={{ marginBottom: 0 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a version",
                            },
                          ]}
                        >
                          <Row>
                            <Col>
                              {!fieldLock &&
                                (index === 0 ? (
                                  <MinusCircleTwoTone
                                    onClick={() => {
                                      const updatedFields = [
                                        ...fields_65_EU_ANNEX_IV,
                                      ];
                                      updatedFields[0] = "";
                                      setFields_65_EU_ANNEX_IV(updatedFields);
                                      form.setFieldsValue({
                                        exemptionListVersion_65_EU_ANNEX_IV:
                                          updatedFields,
                                      });
                                    }}
                                    style={{
                                      marginLeft: 10,
                                      fontSize: "20px",
                                    }}
                                  />
                                ) : (
                                  <MinusCircleTwoTone
                                    onClick={() => {
                                      const updatedFields =
                                        fields_65_EU_ANNEX_IV.filter(
                                          (_, i) => i !== index
                                        );
                                      setFields_65_EU_ANNEX_IV(updatedFields);
                                      form.setFieldsValue({
                                        exemptionListVersion_65_EU_ANNEX_IV:
                                          updatedFields,
                                      });
                                    }}
                                    style={{
                                      marginLeft: 10,
                                      fontSize: "20px",
                                    }}
                                  />
                                ))}
                            </Col>
                            <Col>
                              {!fieldLock && (
                                <PlusCircleTwoTone
                                  onClick={() => {
                                    setFields_65_EU_ANNEX_IV([
                                      ...fields_65_EU_ANNEX_IV,
                                      "",
                                    ]);
                                    form.setFieldsValue({
                                      exemptionListVersion_65_EU_ANNEX_IV: [
                                        ...fields_65_EU_ANNEX_IV,
                                        "",
                                      ],
                                    });
                                  }}
                                  style={{ marginLeft: 10, fontSize: "20px" }}
                                />
                              )}
                            </Col>
                            <Col flex="auto">
                              <Form.Item
                                name={[
                                  "exemptionListVersion_65_EU_ANNEX_IV",
                                  index,
                                ]}
                                noStyle
                              >
                                <Select
                                  disabled={fieldLock}
                                  value={fields_65_EU_ANNEX_IV[index]}
                                  onChange={(value) => {
                                    const updatedFields = [
                                      ...fields_65_EU_ANNEX_IV,
                                    ];
                                    updatedFields[index] = value;
                                    setFields_65_EU_ANNEX_IV(updatedFields);
                                  }}
                                  placeholder={"Select"}
                                  options={
                                    exemptionListVersion_EL2011_65_EU_ANNEX_IV_Options
                                  }
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
          </Card>
          <Card
            style={{
              display: currentStep === 3 ? "block" : "none",
              marginTop: "1rem",
              overflow: "auto",
            }}
            bordered={false}
          >
            {declarationClassSelect ===
              "Class 5 - RoHS Yes/No Homogeneous Materials" ||
            declarationClassSelect ===
              "Class 6 - RoHS Yes/No Homogeneous Materials and Mfg Information" ? (
              <>
                <Form.Item
                  name="productDeclarationType"
                  label={<b>Product Declaration</b>}
                >
                  <Select
                    style={{ width: "100%" }}
                    disabled={fieldLock || xmlData}
                    onChange={(value: string) => {
                      setProductDeclarationType(value);
                    }}
                    defaultValue={
                      xmlData ? xmlData.productDeclarationType : "Single"
                    }
                    placeholder={"Select Product Declaration Type"}
                    options={[
                      {
                        value: "Single",
                        label: "Single Product Declaration",
                      },
                      {
                        value: "Multiple",
                        label: "Multiple Product Declaration",
                      },
                    ]}
                  />
                </Form.Item>
                <Row gutter={24}>
                  {productDeclarationType === "Multiple" && (
                    <Col span={7}>
                      <Card
                        title={"SubProduct"}
                        extra={
                          <PlusCircleOutlined
                            style={{ fontSize: "20px" }}
                            onClick={addItemField}
                          />
                        }
                        style={{
                          borderRadius: 0,
                          border: "1px solid lightgrey",
                        }}
                      >
                        <div style={{ overflow: "auto", height: 600 }}>
                          {items?.map((item, itemIndex) => (
                            <Row key={itemIndex}>
                              <Col span={2}>
                                {itemIndex > 0 && (
                                  <MinusCircleOutlined
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeItemField(itemIndex);
                                    }}
                                    style={{
                                      fontSize: "20px",
                                      cursor: "pointer",
                                      color: "#F7863E",
                                    }}
                                  />
                                )}
                              </Col>
                              <Col span={22}>
                                <Card
                                  key={itemIndex}
                                  style={{
                                    cursor: "pointer",
                                    border:
                                      selectedItemIndex === itemIndex
                                        ? "2px solid #1677ff"
                                        : "1px solid #d9d9d9",
                                    marginBottom: "8px",
                                  }}
                                  onClick={() =>
                                    setSelectedItemIndex(itemIndex)
                                  }
                                >
                                  <Form.Item
                                    name={["items", itemIndex, "name"]}
                                    label={"SubProduct Name"}
                                    rules={[
                                      {
                                        whitespace: true,
                                        required: true,
                                        message: "Please Enter Item Name",
                                      },
                                    ]}
                                  >
                                    <Input
                                      disabled={fieldLock}
                                      placeholder="Enter item name"
                                    />
                                  </Form.Item>
                                  <Row gutter={5}>
                                    <Col span={8}>
                                      <Form.Item
                                        name={["items", itemIndex, "weight"]}
                                        label={`Weight`}
                                        rules={[
                                          {
                                            required: true,
                                            message: `Weight for ${item.name} is required.`,
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          disabled={fieldLock}
                                          style={{ width: "100%" }}
                                          placeholder="Enter"
                                          min={0}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={["items", itemIndex, "uom"]}
                                        label={`UoM`}
                                        rules={[
                                          {
                                            required: true,
                                            message: `UOM for ${item.name} is required.`,
                                          },
                                        ]}
                                      >
                                        <Select
                                          disabled={fieldLock}
                                          placeholder={"UoM"}
                                          options={[
                                            { value: "mg", label: "mg" },
                                            { value: "g", label: "g" },
                                            { value: "kg", label: "kg" },
                                          ]}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={["items", itemIndex, "unitType"]}
                                        label="Unit Type"
                                        rules={[
                                          {
                                            required: true,
                                            message: `Unit Type for ${items[itemIndex].name} is required.`,
                                          },
                                        ]}
                                      >
                                        <Select
                                          disabled={fieldLock}
                                          placeholder={"Select"}
                                          options={[
                                            { value: "Each", label: "Each" },
                                            {
                                              value: "Meter",
                                              label: "Meter",
                                            },
                                            { value: "m^2", label: "m^2" },
                                            { value: "m^3", label: "m^3" },
                                            { value: "cm", label: "cm" },
                                            { value: "cm^2", label: "cm^2" },
                                            { value: "cm^3", label: "cm^3" },
                                            {
                                              value: "Liter",
                                              label: "Liter",
                                            },
                                          ]}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  {itemIndex !== selectedItemIndex &&
                                    item.materials.map(
                                      (materials, materialsIndex) => (
                                        <div key={materialsIndex}>
                                          <Form.Item
                                            name={[
                                              "items",
                                              itemIndex,
                                              "materials",
                                              materialsIndex,
                                              "name",
                                            ]}
                                            hidden
                                          >
                                            <Input disabled={fieldLock} />
                                          </Form.Item>
                                          <Form.Item
                                            name={[
                                              "items",
                                              itemIndex,
                                              "materials",
                                              materialsIndex,
                                              "weight",
                                            ]}
                                            hidden
                                          >
                                            <Input disabled={fieldLock} />
                                          </Form.Item>
                                          <Form.Item
                                            name={[
                                              "items",
                                              itemIndex,
                                              "materials",
                                              materialsIndex,
                                              "uom",
                                            ]}
                                            hidden
                                          >
                                            <Input disabled={fieldLock} />
                                          </Form.Item>
                                          <Form.Item
                                            name={[
                                              "items",
                                              itemIndex,
                                              "materials",
                                              materialsIndex,
                                              "level",
                                            ]}
                                            hidden
                                          >
                                            <Input disabled={fieldLock} />
                                          </Form.Item>
                                          <Form.Item
                                            name={[
                                              "items",
                                              itemIndex,
                                              "materials",
                                              materialsIndex,
                                              "substanceCategory",
                                            ]}
                                            hidden
                                          >
                                            <Input disabled={fieldLock} />
                                          </Form.Item>
                                          {materials.substances.map(
                                            (substance, substanceIndex) => (
                                              <div key={substanceIndex}>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "substance",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "cas",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "exempt",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "weight",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "uom",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "toleranceM",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "toleranceP",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                                <Form.Item
                                                  name={[
                                                    "items",
                                                    itemIndex,
                                                    "materials",
                                                    materialsIndex,
                                                    "substances",
                                                    substanceIndex,
                                                    "ppm",
                                                  ]}
                                                  hidden
                                                >
                                                  <Input disabled={fieldLock} />
                                                </Form.Item>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )
                                    )}
                                </Card>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </Card>
                    </Col>
                  )}
                  <Col span={productDeclarationType === "Multiple" ? 7 : 11}>
                    <Card
                      title={
                        <span>
                          {`SubProduct  `}
                          <ArrowRightOutlined />
                          {" Homogeneous Material"}
                        </span>
                      }
                      extra={
                        <PlusCircleOutlined
                          style={{ fontSize: "20px" }}
                          onClick={addListField}
                        />
                      }
                      style={{
                        borderRadius: 0,
                        border: "1px solid lightgrey",
                      }}
                    >
                      <div style={{ overflow: "auto", height: 600 }}>
                        {selectedItemIndex !== null &&
                          items[selectedItemIndex]?.materials?.map(
                            (list, listIndex) => (
                              <Row key={listIndex}>
                                <Col span={2}>
                                  {listIndex > 0 && (
                                    <MinusCircleOutlined
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeListField(listIndex);
                                      }}
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                        color: "#F7863E",
                                      }}
                                    />
                                  )}
                                </Col>
                                <Col span={22}>
                                  <Card
                                    key={listIndex}
                                    style={{
                                      cursor: "pointer",
                                      border:
                                        selectedMaterialsIndex === listIndex
                                          ? "2px solid #1677ff"
                                          : "1px solid #d9d9d9",
                                      marginBottom: "8px",
                                    }}
                                    onClick={() =>
                                      setSelectedMaterialsIndex(listIndex)
                                    }
                                  >
                                    <Row gutter={5}>
                                      <Col span={10}>
                                        <Form.Item
                                          name={[
                                            "items",
                                            selectedItemIndex,
                                            "materials",
                                            listIndex,
                                            "name",
                                          ]}
                                          label={`Name`}
                                          rules={[
                                            {
                                              whitespace: true,
                                              required: true,
                                              message:
                                                productDeclarationType ===
                                                "Multiple"
                                                  ? `Material Name for ${list.name} in ${items[selectedItemIndex].name} is required.`
                                                  : "Material Name is required.",
                                            },
                                          ]}
                                        >
                                          <Input
                                            disabled={fieldLock}
                                            placeholder="Enter"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <Form.Item
                                          name={[
                                            "items",
                                            selectedItemIndex,
                                            "materials",
                                            listIndex,
                                            "weight",
                                          ]}
                                          label={`Weight`}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                productDeclarationType ===
                                                "Multiple"
                                                  ? `Material Weight for ${list.name} in ${items[selectedItemIndex].name} is required.`
                                                  : "Material Weight is required.",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            disabled={fieldLock}
                                            style={{ width: "100%" }}
                                            placeholder="Enter"
                                            min={0}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={6}>
                                        <Form.Item
                                          name={[
                                            "items",
                                            selectedItemIndex,
                                            "materials",
                                            listIndex,
                                            "uom",
                                          ]}
                                          label={`UoM`}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                productDeclarationType ===
                                                "Multiple"
                                                  ? `Material UOM for ${list.name} in ${items[selectedItemIndex].name} is required.`
                                                  : `Material UOM is required.`,
                                            },
                                          ]}
                                        >
                                          <Select
                                            disabled={fieldLock}
                                            placeholder={"UoM"}
                                            options={[
                                              { value: "mg", label: "mg" },
                                              { value: "g", label: "g" },
                                              { value: "kg", label: "kg" },
                                            ]}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row gutter={5}>
                                      <Col span={10}>
                                        <Form.Item
                                          name={[
                                            "items",
                                            selectedItemIndex,
                                            "materials",
                                            listIndex,
                                            "level",
                                          ]}
                                          label={`Level`}
                                        >
                                          <Select
                                            disabled={fieldLock}
                                            placeholder={"Select"}
                                            options={[
                                              { value: " ", label: " " },
                                              {
                                                value: "Supplier",
                                                label: "Supplier",
                                              },
                                            ]}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={14}>
                                        <Form.Item
                                          name={[
                                            "items",
                                            selectedItemIndex,
                                            "materials",
                                            listIndex,
                                            "substanceCategory",
                                          ]}
                                          label={`Substance Category`}
                                        >
                                          <Input
                                            disabled={fieldLock}
                                            placeholder="Enter"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    {listIndex !== selectedMaterialsIndex &&
                                      list.substances.map(
                                        (substance, substanceIndex) => (
                                          <div key={substanceIndex}>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "substance",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "cas",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "exempt",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "weight",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "uom",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "toleranceM",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "toleranceP",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                            <Form.Item
                                              name={[
                                                "items",
                                                0,
                                                "materials",
                                                listIndex,
                                                "substances",
                                                substanceIndex,
                                                "ppm",
                                              ]}
                                              hidden
                                            >
                                              <Input disabled={fieldLock} />
                                            </Form.Item>
                                          </div>
                                        )
                                      )}
                                  </Card>
                                </Col>
                              </Row>
                            )
                          )}
                      </div>
                    </Card>
                  </Col>
                  <Col span={productDeclarationType === "Multiple" ? 10 : 13}>
                    <Card
                      title={
                        <span>
                          {`HM  `}
                          <ArrowRightOutlined /> {" Substances"}
                        </span>
                      }
                      extra={
                        <PlusCircleOutlined
                          style={{ fontSize: "20px" }}
                          onClick={addSubListField}
                        />
                      }
                      style={{
                        borderRadius: 0,
                        border: "1px solid lightgrey",
                      }}
                    >
                      <div style={{ overflow: "auto", height: 600 }}>
                        {selectedItemIndex !== null &&
                          selectedMaterialsIndex !== null &&
                          items[selectedItemIndex]?.materials[
                            selectedMaterialsIndex
                          ] &&
                          items[selectedItemIndex]?.materials[
                            selectedMaterialsIndex
                          ]?.substances?.map((subList, subListIndex) => (
                            <Row key={subListIndex}>
                              <Col span={2}>
                                {subListIndex > 0 && (
                                  <MinusCircleOutlined
                                    onClick={() =>
                                      removeSubListField(subListIndex)
                                    }
                                    style={{
                                      fontSize: "20px",
                                      cursor: "pointer",
                                      color: "#F7863E",
                                    }}
                                  />
                                )}
                              </Col>
                              <Col span={22}>
                                <Card
                                  key={subListIndex}
                                  style={{
                                    marginBottom: "8px",
                                    border: "1px solid #d9d9d9",
                                  }}
                                >
                                  <Row gutter={5}>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "substance",
                                        ]}
                                        label={`Substance`}
                                        rules={[
                                          {
                                            whitespace: true,
                                            required: true,
                                            message:
                                              productDeclarationType ===
                                              "Multiple"
                                                ? `Substance Name in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} of ${items[selectedItemIndex].name} is required.`
                                                : `Substance Name in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} is required.`,
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={fieldLock}
                                          placeholder="Enter"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "cas",
                                        ]}
                                        label={`CAS`}
                                        rules={[
                                          {
                                            validator: async (_, value) => {
                                              const pattern =
                                                /^\d{1,7}-\d{2}-\d{1}$/;
                                              const allowedWords = [
                                                "proprietary",
                                                "secret",
                                                "confidential",
                                              ];
                                              if (value) {
                                                if (
                                                  pattern.test(value) ||
                                                  allowedWords.includes(
                                                    value?.toLowerCase()
                                                  )
                                                ) {
                                                  return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                  new Error(
                                                    productDeclarationType ===
                                                    "Multiple"
                                                      ? `CAS for ${subList.substance} in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} of ${items[selectedItemIndex].name} is not allowed.`
                                                      : `CAS for ${subList.substance} in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} is not allowed`
                                                  )
                                                );
                                              }
                                            },
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={fieldLock}
                                          placeholder="Enter"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "exempt",
                                        ]}
                                        label={`Exempt`}
                                        // rules={[
                                        //   {
                                        //     required: showInput,
                                        //     message: "Required!",
                                        //   },
                                        // ]}
                                      >
                                        {/* <Select
                                          disabled={fieldLock}
                                          placeholder={"Select"}
                                          options={
                                            exemptionListVersion_EL2011_534_EU_Options
                                          }
                                        /> */}
                                        <Select
                                          placeholder="Select"
                                          disabled={fieldLock}
                                        >
                                          <Select.Option key={""} value={""}>
                                            {""}
                                          </Select.Option>
                                          {showInput &&
                                            fields_534_EU?.length > 0 &&
                                            fields_534_EU[0] !== "" && (
                                              <Select.OptGroup label="EL2011/534/EU">
                                                {fields_534_EU.map(
                                                  (item, index) => (
                                                    <Select.Option
                                                      key={`array1_${index}`}
                                                      value={item}
                                                    >
                                                      {item}
                                                    </Select.Option>
                                                  )
                                                )}
                                              </Select.OptGroup>
                                            )}

                                          {showInput &&
                                            fields_65_EU_ANNEX_IV?.length > 0 &&
                                            fields_65_EU_ANNEX_IV[0] !== "" && (
                                              <Select.OptGroup label="EL2011/65/EU_ANNEX_IV">
                                                {fields_65_EU_ANNEX_IV.map(
                                                  (item, index) => (
                                                    <Select.Option
                                                      key={`array2_${index}`}
                                                      value={item}
                                                    >
                                                      {item}
                                                    </Select.Option>
                                                  )
                                                )}
                                              </Select.OptGroup>
                                            )}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={5}>
                                    <Col span={5}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "weight",
                                        ]}
                                        label={`Weight`}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              productDeclarationType ===
                                              "Multiple"
                                                ? `Substance Weight for ${subList.substance} in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} of ${items[selectedItemIndex].name} is required.`
                                                : `Substance Weight for ${subList.substance} in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} is required.`,
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          disabled={fieldLock}
                                          style={{ width: "100%" }}
                                          placeholder="Enter"
                                          min={0}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "uom",
                                        ]}
                                        label={`UoM`}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              productDeclarationType ===
                                              "Multiple"
                                                ? `Substance UOM for ${subList.substance} in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} of ${items[selectedItemIndex].name} is required.`
                                                : `Substance UOM for ${subList.substance} in ${items[selectedItemIndex].materials[selectedMaterialsIndex].name} is required.`,
                                          },
                                        ]}
                                      >
                                        <Select
                                          disabled={fieldLock}
                                          placeholder={"UoM"}
                                          options={[
                                            { value: "mg", label: "mg" },
                                            { value: "g", label: "g" },
                                            { value: "kg", label: "kg" },
                                          ]}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "toleranceM",
                                        ]}
                                        label={`Tolerance-`}
                                      >
                                        <InputNumber
                                          disabled={fieldLock}
                                          style={{ width: "100%" }}
                                          placeholder="Enter"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "toleranceP",
                                        ]}
                                        label={`Tolerance+`}
                                      >
                                        <InputNumber
                                          disabled={fieldLock}
                                          style={{ width: "100%" }}
                                          placeholder="Enter"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                      <Form.Item
                                        name={[
                                          "items",
                                          selectedItemIndex,
                                          "materials",
                                          selectedMaterialsIndex,
                                          "substances",
                                          subListIndex,
                                          "ppm",
                                        ]}
                                        label={"PPM"}
                                      >
                                        <InputNumber
                                          disabled={fieldLock}
                                          style={{ width: "100%" }}
                                          placeholder="Enter"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          ))}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <Title level={5}>JIG section intentionally omitted.</Title>
            )}
          </Card>
        </Form>
      </Card>
    </div>
  );
};
export default IPC1752Declaration;
