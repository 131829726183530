import React, { useEffect } from "react";
import { Form, Input, Select, Button, Space, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { PendingResponse } from "../../utils/types/CampaignManager/campaignManager";

const { Option } = Select;

interface PendingApprovalEditFormProps {
  record: PendingResponse;
  onCancel: () => void;
  onSave: (updatedRecord: PendingResponse) => void;
}

const PendingApprovalEditForm: React.FC<PendingApprovalEditFormProps> = ({
  record,
  onCancel,
  onSave,
}) => {
  const [form] = Form.useForm();

  const formatRegulationName = (name: string) => {
    return name.replace("_", " ").toUpperCase();
  };

  useEffect(() => {
    form.setFieldsValue({
      manufacturerName: (record.manufacturer as any)?.name,
      manufacturerPartNumber: (record.manufacturer as any)?.itemNumber,
      regulation: (record.supplierComplianceResponse as any)?.regulationNumber,
      compliant: (record.supplierComplianceResponse as any)?.complianceOutputs
        ?.compliant,
      exemption: (record.supplierComplianceResponse as any)?.complianceOutputs
        ?.exemption,
      substances: (record.supplierComplianceResponse as any)?.complianceOutputs
        ?.substance,
      version: (record.supplierComplianceResponse as any)?.complianceOutputs
        ?.version,
      comment: record.comments,
    });
  }, [record, form]);

  const handleSave = () => {
    form.validateFields().then((values) => {
      const updatedRecord: PendingResponse = {
        ...record,
        supplierComplianceResponse: {
          ...(record.supplierComplianceResponse as any),
          regulationNumber: values.regulation,
          complianceOutputs: {
            ...(record.supplierComplianceResponse as any)?.complianceOutputs,
            compliant: values.compliant,
            exemption: values.exemption,
            substance: values.substances,
            version: values.version,
          },
        },
        comments: values.comment,
      };
      onSave(updatedRecord);
    });
  };

  return (
    <div style={{ padding: "20px", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          padding: "10px 20px",
        }}
      >
        <h2>
          Edit -{" "}
          {formatRegulationName(
            (record.supplierComplianceResponse as any)?.regulationNumber || ""
          )}
        </h2>
        <Space>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleSave}
            style={{ backgroundColor: "#F7863E", borderColor: "#F7863E" }}
          >
            Save
          </Button>
          <CloseOutlined
            onClick={onCancel}
            style={{ fontSize: "18px", cursor: "pointer" }}
          />
        </Space>
      </div>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="manufacturerName" label="Manufacturer Name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="manufacturerPartNumber"
              label="Manufacturer Part Number"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="regulation" label="Regulation">
              <Select>
                <Option value="EU_REACH">EU REACH</Option>
                <Option value="EU_ROHS">EU RoHS</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="compliant" label="Compliant">
              <Select>
                <Option value="YES">Yes</Option>
                <Option value="NO">No</Option>
                <Option value="YES_WITH_EXEMPTION">Yes with Exemption</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="exemption" label="Exemption">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="file" label="File">
              <Input
                suffix={
                  <Space>
                    <CloseOutlined />
                  </Space>
                }
                style={{ width: "100%" }}
                value="PDF"
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="substances" label="Substances">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="version" label="Version">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="comment" label="Comment">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PendingApprovalEditForm;
