import { privateGetApi } from "../../utils/apiHelper";

export const AnalyticsAdmin = (payload: any, id: string): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/analytics/admin/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
