import React, { useState, useEffect, useCallback } from "react";
import {
  CloseOutlined,
  CloseCircleOutlined,
  PlusCircleTwoTone,
  ExclamationCircleFilled,
  ExportOutlined,
  MinusCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  Tag,
  Tooltip,
  message,
  AutoComplete,
} from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import { Modal } from "antd";
import type {
  FilterDropdownProps,
  FilterValue,
  SorterResult,
} from "antd/es/table/interface";
import { useAppDispatch } from "../../../redux/hooks";
import {
  bulkActionCampaignResponse,
  readCampaignPendingResponses,
  updateCampaignResponse,
} from "../../../redux/actions/CampaignManager/campaignManagerAction";
import AddComplianceApprove from "../../../components/modals/addApproveComplianceModal";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import { getRegulationsFromLocalStorage } from "../../../utils/localStore";
import ErrorBoundary from "../../../utils/errorBoundary";
import SearchableColumnHeader from "./SearchableColumnHeader";
import PendingApprovalSubTable from "./PendingApprovalSubTable";
import {
  PendingResponse as ImportedPendingResponse,
  GroupedPendingResponse as ImportedGroupedPendingResponse,
} from "../../../utils/types/CampaignManager/campaignManager";

const { Search } = Input;
const { Text } = Typography;

interface GroupedPendingResponse
  extends Omit<ImportedGroupedPendingResponse, "items"> {
  items: PendingResponse[];
  count: number;
  sendgridCampaignId: string;
  comments: any[];
  manufacturer: {
    name: string;
    itemNumber: string;
  };
  groupedSupplierComplianceResponse: {
    regulationNumber?: string;
  };
}
interface Manufacturer {
  name: string;
  itemNumber: string;
}

interface SupplierComplianceResponse {
  regulationNumber?: string;
}

interface PendingResponse {
  _id: string;
  componentId: string;
  sendgridCampaignId: string;
  manufacturer: Manufacturer;
  internalItemNumber: string;
  supplierComplianceResponse: SupplierComplianceResponse;
  responseType?: string;
  campaignId?: string;
  regulation: string;
  comments?: any[];
  outOfScope?: any[];
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}
interface GroupedPendingResponse {
  _id: string;
  items: PendingResponse[];
  manufacturer: Manufacturer;
  internalItemNumber: string;
  responseType: string;
  groupedSupplierComplianceResponse: SupplierComplianceResponse;
  count: number;
  sendgridCampaignId: string;
  comments: any[];
}
export interface RejectionRequest {
  sendgridCampaignId: string;
  componentId: string;
  regulationNumber: string;
  type: "rejected";
  feedback: string | null;
}

const CampaignManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const [pendingResponse, setPendingResponse] = useState<PendingResponse[]>([]);
  const [pendingResponseCount, setPendingResponseCount] = useState<number>(0);
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<PendingResponse>>(
    {}
  );
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();
  const [subscriptionEnable, setSubscriptionEnable] = useState<any>();

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [selectedResponse, setSelectedResponse] = useState<any>({});

  const handleOpenModal = (record: any) => {
    setSelectedResponse(record);
    setIsModalVisible(true);
  };

  const handleReject = (comment?: boolean) => {
    let bulkApprovePayload;
    if (
      selectedResponse.regulation !== "Material" &&
      selectedResponse.regulation !== "Scip"
    ) {
      bulkApprovePayload = {
        supplierComplianceResponse: selectedResponse?.document?.map(
          (e: any) => e.supplierComplianceResponse
        ),
        type: "rejected",
        ...(comment && { feedback: feedbackText || null }),
        componentId: selectedResponse.document?.componentId,
      };
    } else {
      bulkApprovePayload = {
        type: "rejected",
        ...(comment && { feedback: feedbackText || null }),
        componentId: selectedResponse.document?.componentId,
      };
    }
    dispatch(
      bulkActionCampaignResponse({
        filter: selectedResponse.document?.map((item: any) => ({
          sendgridCampaignId: item.sendgridCampaignId,
          componentId: item.componentId,
        })),
        updates: bulkApprovePayload,
        responseType: selectedResponse.regulation,
      })
    ).then(() => {
      fetchData();
      setIsModalVisible(false);
      setFeedbackText("");
      setSelectedResponse({});
    });
  };

  const [columnSearches, setColumnSearches] = useState<Record<string, string>>(
    {}
  );

  const handleColumnSearch = useCallback((column: string, value: string) => {
    setColumnSearches((prev) => {
      if (prev[column] === value) return prev;
      return { ...prev, [column]: value };
    });
  }, []);

  const searchDependency = Object.entries(columnSearches)
    .map(([key, value]) => `${key}:${value}`)
    .join("|");

  const columns: ColumnsType<PendingResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text: any, record: any) => (
        <Space style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CloseCircleOutlined
            style={{ cursor: "pointer", fontSize: "16px", marginTop: "2px" }}
            onClick={() => handleOpenModal(record)}
          />
          <AddComplianceApprove
            type="icon"
            fetchData={fetchData}
            formData={record}
            isBulk={true}
          />
          {/* <div
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
            onClick={() => {
              handleExpand(record);
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <MinusCircleTwoTone
                style={{ cursor: "pointer", fontSize: "16px" }}
              />
            ) : (
              <PlusCircleTwoTone
                style={{ cursor: "pointer", fontSize: "16px" }}
              />
            )}
          </div> */}
        </Space>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 150,
      render: (text: string, record: any) => (
        <Space>
          {/* <Tag bordered={false} color="processing">
            {record?.totalComponents}
          </Tag> */}
          <Text>{text}</Text>
        </Space>
      ),
    },
    {
      title: "Regulation",
      dataIndex: "regulation",
      key: "regulation",
      sorter: true,
      width: 150,
      render: (value: string[], record: any) => {
        if (record.regulation !== "Material" || record.regulation !== "Scip") {
          const regulations = getRegulationsFromLocalStorage();
          const regulationData = value
            ? regulations?.find(
                (regulation: any) => regulation.regulationNumber === value
              )?.name || value
            : "";
          return <Text>{regulationData}</Text>;
        } else {
          <Text>{value}</Text>;
        }
      },
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {text ? new Date(text).toLocaleString() : ""}
          <br />
          {record?.createdBy}
        </Text>
      ),
    },
  ].filter(Boolean);

  const onSearch = (value: any) => {
    setSearch({
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      readCampaignPendingResponses({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
        columnSearches: columnSearches,
      })
    )
      .then((response: any) => {
        setPendingResponse(response?.pendingResponse || []);
        setPendingResponseCount(response?.count || 0);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<PendingResponse>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PendingResponse> | SorterResult<PendingResponse>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<PendingResponse>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [search, searchDependency]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
          }}
        >
          <Space>
            <Search
              placeholder="Search using manufacturer name text"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={pendingResponse}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          pagination={{
            ...tablePagination,
            total: pendingResponseCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          expandable={{
            expandedRowRender: (record) => (
              <PendingApprovalSubTable
                formData={record}
                fetchData={fetchData}
              />
            ),
          }}
          rowKey={(record) =>
            `${record.manufacturer}+${record.sendgridCampaignId}+${
              record?.regulation || ""
            }`
          }
          onChange={handleTableChange}
        />
      </ErrorBoundary>
      <Modal
        title="Feedback for Rejection"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setFeedbackText("");
        }}
        footer={[
          <>
            <Button
              onClick={() => handleReject(false)}
              style={{
                backgroundColor: "#00516E",
                color: "white",
                borderColor: "#00516E",
                marginRight: "8px",
              }}
            >
              Reject without Feedback
            </Button>
            <Button
              type="primary"
              onClick={() => handleReject(true)}
              style={{
                backgroundColor: "#F7863E",
                borderColor: "#F7863E",
              }}
            >
              Submit
            </Button>
          </>,
        ]}
        centered
        maskClosable={false}
      >
        <Input.TextArea
          rows={4}
          placeholder="Type Feedback"
          onChange={(e) => setFeedbackText(e.target.value)}
          value={feedbackText}
          style={{
            borderColor: "#ACE8FF",
            marginBottom: "50px",
          }}
        />
      </Modal>
    </div>
  );
};

export default CampaignManager;
