import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Campaign from "./Campaign";
import Survey from "./Survey";
import PendingApproval from "./pendingApproval";
import Inbox from "./inbox";

import { Space, Tabs, Typography } from "antd";
import type { TabsProps } from "antd";
import { getLocalStorage } from "../../../utils/localStore";

const { Title } = Typography;

const CampaignManager: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const BackTabActivate = getLocalStorage("BackTabActivate");

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const authorisedSender = getLocalStorage("user")
    ? getLocalStorage("user").authorisedSender
    : null;

  const handleTabChange = (key: string) => {
    navigate(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "campaign",
      label: "Campaign",
      children: <Campaign />,
    },
    {
      key: "survey",
      label: "Survey List",
      children: <Survey />,
    },
    authorisedSender && {
      key: "inbox",
      label: "Inbox",
      children: <Inbox />,
    },
    permissions?.read?.includes("components") && {
      key: "response",
      label: "Pending Approval",
      children: <PendingApproval />,
    },
  ];

  useEffect(() => {
    if (BackTabActivate) {
      navigate("survey");
      localStorage.removeItem("BackTabActivate");
    }
  }, []);

  return (
    <div>
      <Space
        align="end"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            Campaign
          </Title>
        </div>
      </Space>
      <Tabs
        activeKey={location.pathname.split("/")[3]}
        onChange={handleTabChange}
        items={items}
      />
    </div>
  );
};

export default CampaignManager;
