import React from "react";
import { Button, Modal } from "antd";

const CampaignScheduleModal: React.FC<{
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  navigateToSurveyList: () => void;
  scheduleCampaign: () => void;
  missingContacts: any;
  totalSuppliers: any;
}> = (props) => {
  const handleSubmit = () => {
    props.scheduleCampaign();
  };

  return (
    <Modal
      open={props.isModalOpen}
      centered
      width={650}
      closable={false}
      footer={
        props.totalSuppliers - props.missingContacts > 0 ? (
          <>
            <Button onClick={props.closeModal}>Cancel</Button>
            {props.missingContacts !== 0 && (
              <Button
                onClick={props.navigateToSurveyList}
                style={{ background: "#045B7C", color: "white" }}
              >
                Go To Survey List
              </Button>
            )}
            <Button type="primary" onClick={handleSubmit}>
              Schedule Campaign
            </Button>
          </>
        ) : (
          <Button
            onClick={props.navigateToSurveyList}
            type="primary"
            style={{ background: "#045B7C", color: "white" }}
          >
            Go To Survey List
          </Button>
        )
      }
    >
      <h3 style={{ textAlign: "center" }}>
        {props.totalSuppliers - props.missingContacts > 0
          ? "Confirmation Required"
          : "No Contacts Available"}
      </h3>
      <p style={{ textAlign: "center" }}>
        {props.totalSuppliers - props.missingContacts > 0
          ? `${props.totalSuppliers - props.missingContacts} out of ${
              props.totalSuppliers
            } recipients will receive the message. Do you want to proceed with scheduling the campaign?`
          : "Cannot schedule the campaign as there are no contacts to receive the message. Please add contacts to proceed."}
      </p>
    </Modal>
  );
};

export default CampaignScheduleModal;
