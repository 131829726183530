import React, { useEffect, useState } from "react";
import { Table, Space, Button, Tag, Typography, Tooltip } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useParams } from "react-router-dom";

import { Component } from "../../../utils/types/component";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchScipComponents } from "../../../redux/actions/supplierPortalAction";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import ErrorBoundary from "../../../utils/errorBoundary";
import ScipForm from "../../../components/forms/ScipForm";
import { getLocalStorage } from "../../../utils/localStore";

const { Title, Text } = Typography;

const CampaignScip: React.FC = () => {
  const { uniqueName, linkCampaignId, linkTenantNumber, regulationNumber } =
    useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [components, setComponents] = useState<any>([]);
  const [componentCount, setComponentCount] = useState<number>(0);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();
  const campaignData = getLocalStorage("campaignData");

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      fetchScipComponents({
        name: uniqueName,
        regulationNumber: regulationNumber,
        campaignId: window.atob(linkCampaignId!),
        tenantNumber: window.atob(linkTenantNumber!),
        pageNum: tablePagination.current,
        pageSize: tablePagination.pageSize,
      })
    )
      .then((response) => {
        setComponents(response.scipComponents);
        setComponentCount(response.scipComponentsCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setComponents([]);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (_: string, record: any) => (
        <>
          <Space>
            <ScipForm
              type="supplier"
              formData={record}
              fetchData={fetchData}
              campaignId={window.atob(linkCampaignId!)}
              supplierName={uniqueName}
            />
          </Space>
        </>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      //sorter: true,
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => (
        <Text>
          {manufacturer.itemNumber}
          <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
    },
    {
      title: "Substance of Concern",
      dataIndex: "compliances.complianceOutputs.substance",
      key: "compliances.complianceOutputs.substance",
      //sorter: true,
      width: 200,
      hidden: false,
      render: (text: any, record: any) => (
        <Text
          style={{ width: 200 }}
          ellipsis={{
            tooltip: record.compliances?.complianceOutputs?.substance,
          }}
        >
          {record && record.compliances
            ? record.compliances?.complianceOutputs?.substance
            : ""}
        </Text>
      ),
    },
    {
      title: "Article ID Type",
      dataIndex: "PrimaryArticleIdentifierType",
      key: "PrimaryArticleIdentifierType",
      //sorter: true,
      width: 150,
      render: (value: any) => <Text>{value ? value?.text : ""}</Text>,
    },
    {
      title: "Article ID Value",
      dataIndex: "PrimaryArticleIdentifierValue",
      key: "PrimaryArticleIdentifierValue",
      //sorter: true,
      width: 150,
    },
    {
      title: "Dataset Status",
      dataIndex: "Status",
      key: "Status",
      //sorter: true,
      width: 200,
      render: (text: any) =>
        text && (
          <Tag
            color={
              text === "" ? "blue" : text === "pending" ? "orange" : "green"
            }
          >
            {text === "" ? "INITIATED" : text.toUpperCase()}
          </Tag>
        ),
    },
  ];

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Title level={5} style={{ marginBottom: 0 }}>
            SCIP (EU Waste Framework Directive)
          </Title>
        </Space>

        <Table
          loading={loading}
          dataSource={components}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: componentCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </>
  );
};

export default CampaignScip;
