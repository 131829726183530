import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import { Table, Space, Button, message, Badge, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch } from "../../../../../redux/hooks";
import { readCampaignSupplierAnalytics } from "../../../../../redux/actions/CampaignManager/campaignManagerAction";
import ErrorBoundary from "../../../../../utils/errorBoundary";

const CampaignSuppliers: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();
  const [supplierAnalytics, setSupplierAnalytics] = useState<any[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (record) => (
        <Space>
          {contextHolder}
          <Tooltip title="Copy Link">
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={() => onClickCopy(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
    },
    {
      title: "Contact",
      dataIndex: "contacts",
      key: "contacts",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => (
        <div className="mx-3">
          {text?.includes("delivered") && (
            <span className="ml-2">
              <Badge>
                <Tag color="grey"> Delivered</Tag>
              </Badge>
            </span>
          )}
          {text.includes("open") && (
            <span className="mx-2" style={{ marginRight: "1rem" }}>
              <Badge count={text.filter((e: any) => e === "open").length}>
                <Tag color="#52c41a"> Opened </Tag>
              </Badge>
            </span>
          )}
          {text?.includes("click") && (
            <span className="mx-2" style={{ marginRight: "1rem" }}>
              <Badge count={text.filter((e: any) => e === "click").length}>
                <Tag color="#ffa940"> Clicked</Tag>
              </Badge>
            </span>
          )}
          {text.includes("bounced") && (
            <span className="mx-2" style={{ marginRight: "1rem" }}>
              <Badge count={text.filter((e: any) => e === "bounced").length}>
                <Tag color="#ffec3d"> Bounced </Tag>
              </Badge>
            </span>
          )}
        </div>
      ),
    },
  ];

  const onClickCopy = (record: any) => {
    navigator.clipboard.writeText(record.link);
    messageApi.open({
      type: "success",
      content: "Link copied to clipboard",
    });
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readCampaignSupplierAnalytics({
        sendgridCampaignId: id,
      })
    )
      .then((response: any) => {
        setSupplierAnalytics(response?.document);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ErrorBoundary>
        <Table
          loading={loading}
          dataSource={supplierAnalytics}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="supplierId"
        />
      </ErrorBoundary>
    </div>
  );
};

export default CampaignSuppliers;
