import React, { useEffect, useRef, useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Popover, Tag } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { bulkUpdateComplaince } from "../../redux/actions/compliancesAction";
import {
  bulkActionCampaignResponse,
  uploadCampaignDocument,
} from "../../redux/actions/CampaignManager/campaignManagerAction";
import ApprovalCommentModal from "./ApprovalCommentModal";
import { PopoverProps } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { bulkUpdateMaterial } from "../../redux/actions/materialsAction";
import { bulkUpdateScip } from "../../redux/actions/scipAction";

interface AddComplianceApproveProps {
  type: string;
  fetchData: () => void;
  formData?: any;
  sendgridCampaignId?: string;
  isBulk?: boolean;
  fetchParentData?: any;
  supplierResponseCount?: number;
}

const AddComplianceApprove: React.FC<AddComplianceApproveProps> = ({
  type,
  fetchData,
  formData,
  sendgridCampaignId,
  isBulk,
  fetchParentData,
  supplierResponseCount,
}) => {
  const dispatch = useAppDispatch();
  const [hovered, setHovered] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [doubleClick, setDoubleClick] = useState(false);
  const clickCountRef = useRef(0);
  const singleClickTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setHovered(false);
    clickCountRef.current++;
    if (singleClickTimerRef.current) {
      clearTimeout(singleClickTimerRef.current);
    }

    if (clickCountRef.current === 1) {
      singleClickTimerRef.current = setTimeout(() => {
        if (!doubleClick) {
          return;
        }
        setHovered(false);
        clickCountRef.current = 0;
        setDoubleClick(false);
      }, 300);
    } else if (clickCountRef.current === 2) {
      if (singleClickTimerRef.current) {
        clearTimeout(singleClickTimerRef.current);
        clickCountRef.current = 0;
      }
      setDoubleClick(true);
      clickCountRef.current = 0;
      setIsModalVisible(true);
      setHovered(false);
    }
  };

  const hoverContent = (
    <div
      style={{
        backgroundColor: "#1F1F1F",
        padding: "8px",
        borderRadius: "4px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#FFFFFF",
            fontSize: "14px",
            lineHeight: "20px",
            cursor: "pointer",
          }}
        >
          <CheckCircleOutlined style={{ color: "#52C41A" }} />
          <span>Single Click to Approve</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "#FFFFFF",
            fontSize: "14px",
            lineHeight: "20px",
            cursor: "pointer",
          }}
        >
          <CheckCircleOutlined style={{ color: "#52C41A" }} />
          <span>Double Click to Approve with Comment</span>
        </div>
      </div>
    </div>
  );

  const handleConfirm = (comment?: string) => {
    let bulkApprovePayload;
    if (formData.regulation !== "Material" && formData.regulation !== "Scip") {
      bulkApprovePayload = {
        type: "approved",
        ...(comment && { feedback: comment || null }),
        componentId: isBulk
          ? formData.document?.componentId
          : formData.componentId,
        supplierComplianceResponse: isBulk
          ? formData.document.map((e: any) => e.supplierComplianceResponse)
          : [formData?.supplierComplianceResponse],
      };
    } else {
      bulkApprovePayload = {
        type: "approved",
        ...(comment && { feedback: comment || null }),
        componentId: isBulk
          ? formData.document?.componentId
          : formData.componentId,
      };
    }
    dispatch(
      bulkActionCampaignResponse({
        filter: isBulk
          ? formData.document?.map((item: any) => ({
              sendgridCampaignId: item.sendgridCampaignId,
              componentId: item.componentId,
            }))
          : [
              {
                sendgridCampaignId: formData.sendgridCampaignId,
                componentId: formData.componentId,
              },
            ],
        updates: bulkApprovePayload,
        responseType: formData.regulation,
      })
    )
      .then(() => {
        if (formData.regulation === "Material") {
          dispatch(
            bulkUpdateMaterial({
              updates: isBulk ? formData.document : [formData],
              responseType: formData.regulation,
            })
          );
        } else if (formData.regulation === "Scip") {
          dispatch(
            bulkUpdateScip({
              updates: isBulk ? formData.document : [formData],
              responseType: formData.regulation,
            })
          );
        } else {
          dispatch(
            bulkUpdateComplaince({
              updates: isBulk ? formData.document : [formData],
              responseType: formData.regulation,
            })
          );
        }
      })
      .then(() => {
        if (
          formData.regulation !== "Material" &&
          formData.regulation !== "Scip"
        ) {
          const fileExists = isBulk
            ? formData.document?.some(
                (item: any) =>
                  item.supplierComplianceResponse?.complianceDocument
              )
            : formData.supplierComplianceResponse?.complianceDocument;

          fileExists &&
            dispatch(
              uploadCampaignDocument({
                foldername: "campaign",
                subfolder: "compliances",
                campaignData: isBulk ? formData?.document : [formData],
              })
            );
        } else if (formData.regulation === "Material") {
          const fileExists = isBulk
            ? formData.document?.some(
                (item: any) => item.supplierMaterialsResponse?.fmdDocument
              )
            : formData.supplierMaterialsResponse?.fmdDocument;

          fileExists &&
            dispatch(
              uploadCampaignDocument({
                foldername: "campaign",
                subfolder: "materials",
                campaignData: isBulk ? formData?.document : [formData],
              })
            );
        }
      })
      .then(() => {
        setIsModalVisible(false);
        fetchData();
        supplierResponseCount === 1 && fetchParentData();
      })
      .catch((error) => {
        message.error(
          `Approval failed: ${error.response?.data?.message || error.message}`
        );
      });
  };

  const handleCancel = () => {
    fetchData();
    setDoubleClick(false);
  };

  const popoverProps: PopoverProps = {
    content: hoverContent,
    trigger: "hover",
    open: hovered,
    onOpenChange: setHovered,
    overlayStyle: {
      maxWidth: "300px",
    },
    overlayInnerStyle: {
      backgroundColor: "transparent",
      padding: 0,
      boxShadow: "none",
    },
    placement: "top" as TooltipPlacement,
    arrow: false,
  };

  return (
    <>
      <Popover {...popoverProps}>
        <Popconfirm
          title={isBulk ? "Bulk Approve Compliance" : "Approve Compliance"}
          description={
            isBulk
              ? "Are you sure you want to approve all selected Component Compliances?"
              : "Are you sure you want to approve selected Component Compliance?"
          }
          onConfirm={() => handleConfirm()}
          onCancel={handleCancel}
          okText="Ok"
          cancelText="Cancel"
          placement="topLeft"
          // open={isVisible}
        >
          <Button
            type={type === "icon" ? "text" : "primary"}
            icon={
              <CheckCircleOutlined
                style={{
                  color: type === "icon" ? "#52C41A" : "#FFFFFF",
                  fontSize: "16px",
                }}
              />
            }
            onClick={handleClick}
            style={
              type === "button"
                ? {
                    backgroundColor: "#52C41A",
                    borderColor: "#52C41A",
                  }
                : {}
            }
          >
            {type === "button" && "Approve"}
          </Button>
        </Popconfirm>
      </Popover>

      <ApprovalCommentModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setDoubleClick(false);
        }}
        onSubmit={(comment) => handleConfirm(comment)}
      />
    </>
  );
};
export default AddComplianceApprove;
