import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { listScipComponents } from "../../../../redux/actions/scipAction";
import { useAppDispatch } from "../../../../redux/hooks";
import ErrorBoundary from "../../../../utils/errorBoundary";
import { Space, Table, Typography } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import ScipForm from "../../../../components/forms/ScipForm";
import { getLocalStorage } from "../../../../utils/localStore";
import ScipSupportingDocument from "../../../../components/modals/SCIP/ScipSupportingDocument";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";
import { useSearchParams } from "react-router-dom";

const { Title, Text } = Typography;

interface ComponentProps {
  search?: string;
}
export interface ComponentHandle {
  focusInput: () => void;
}

const Components = forwardRef<ComponentHandle, ComponentProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    focusInput: () => {
      if (inputRef.current) {
        inputRef.current.focus();
        fetchData();
      }
    },
  }));

  const dispatch = useAppDispatch();
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const [scipComponents, setScipComponents] = useState<[]>([]);
  const [scipComponentCount, setScipComponentCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<any>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";

  const columns: ColumnsType<any> = [
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 150,
      render: (_: string, record: any) => (
        <>
          <Space>
            {permissions?.update.includes("scip") && (
              <ScipForm type="edit" formData={record} fetchData={fetchData} />
            )}
            {permissions?.update.includes("scip") && (
              <ScipSupportingDocument formData={record} fetchData={fetchData} />
            )}
            {permissions?.update.includes("scip") && (
              <ScipForm
                type="preview"
                formData={record}
                fetchData={fetchData}
              />
            )}
          </Space>
        </>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      //sorter: true,
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => (
        <Text>
          {manufacturer.itemNumber}
          <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
    },
    {
      title: "SVHC Version",
      dataIndex: "compliances.complianceOutputs.version",
      key: "compliances.complianceOutputs.version",
      //sorter: true,
      width: 200,
      render: (_, record: any) => (
        <Text>
          {record && record.compliances
            ? record.compliances?.complianceOutputs?.version
            : ""}
        </Text>
      ),
    },
    {
      title: "Substance of Concern",
      dataIndex: "compliances.complianceOutputs.substance",
      key: "compliances.complianceOutputs.substance",
      //sorter: true,
      width: 200,
      hidden: false,
      render: (text: any, record: any) => (
        <Text
          style={{ width: 200 }}
          ellipsis={{
            tooltip: record.ConcernElements
              ? record.ConcernElements?.filter(
                  (e: any) => e?.CandidateListSubstanceLink?.name
                )?.map((e: any) => e.CandidateListSubstanceLink.name)
              : record.compliances?.complianceOutputs?.substance,
          }}
        >
          {record.ConcernElements
            ? record.ConcernElements?.filter(
                (e: any) => e?.CandidateListSubstanceLink?.name
              )?.map((e: any) => e.CandidateListSubstanceLink.name)
            : record.compliances?.complianceOutputs?.substance}
        </Text>
      ),
    },
    {
      title: "Article ID Type",
      dataIndex: "PrimaryArticleIdentifierType",
      key: "PrimaryArticleIdentifierType",
      //sorter: true,
      width: 150,
      render: (value: any) => <Text>{value ? value?.text : ""}</Text>,
    },
    {
      title: "Article ID Value",
      dataIndex: "PrimaryArticleIdentifierValue",
      key: "PrimaryArticleIdentifierValue",
      //sorter: true,
      width: 150,
    },
    {
      title: "Dataset Status",
      dataIndex: "Status",
      key: "Status",
      //sorter: true,
      width: 200,
      render: (text: any) => (
        <Text
          type={
            text === "Completed"
              ? "success"
              : text === "Query Raised"
              ? "danger"
              : "warning"
          }
        >
          {" "}
          {text}{" "}
        </Text>
      ),
    },
    {
      title: "Supporting Document",
      dataIndex: "scipDocument",
      key: "scipDocument",
      width: 150,
      render: (text: string) => {
        return (
          <ReadSupportingDocument
            postUrl="scip/document/download"
            filename={text}
            foldername="scip"
          />
        );
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {text ? new Date(text).toLocaleString() : ""}
          <br />
          {record?.createdBy}
        </Text>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  const fetchData = () => {
    setLoading(true);
    dispatch(
      listScipComponents({
        pageSize: search?.length > 0 ? 10 : tablePagination.pageSize,
        pageNum: search?.length > 0 ? 1 : tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        searchField: search || "",
      })
    )
      .then((response: any) => {
        setScipComponents(response.scipComponents);
        setScipComponentCount(response?.scipComponentCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<any>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<any>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setScipComponents([]);
    }
  };
  return (
    <div ref={inputRef}>
      <ErrorBoundary>
        <Table
          loading={loading}
          dataSource={scipComponents}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: scipComponentCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
});

Components.displayName = "Components";
export default Components;
