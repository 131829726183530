import React, { useEffect, useState } from "react";
import { PlusOutlined, WarningFilled } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  Drawer,
  Table,
  Typography,
  Tag,
} from "antd";

import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";

import { List } from "../../utils/types/CampaignManager/list";
import { Supplier } from "../../utils/types/supplier";
import { Component } from "../../utils/types/component";
import { useAppDispatch } from "../../redux/hooks";
import { updateLists } from "../../redux/actions/CampaignManager/listsAction";
import { listSuppliers } from "../../redux/actions/suppliersAction";
import { listComponents } from "../../redux/actions/componentsAction";
import { getLocalStorage } from "../../utils/localStore";
import { readList } from "../../redux/actions/CampaignManager/listsAction";

const { Search } = Input;
const { Text } = Typography;

const EditListForm: React.FC<{
  fetchData: any;
  formData?: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [supplierCount, setSupplierCount] = useState<number>(0);
  const [components, setComponents] = useState<Component[]>([]);
  const [componentCount, setComponentCount] = useState<number>(0);

  const [searchComponents, setSearchComponents] = useState<any>();
  const permission = getLocalStorage("role").permissions;
  const showComponents = permission?.read?.includes("components");

  const [selectedSuppliers, setSelectedSuppliers] = useState<Supplier[]>([]);
  const [selectedComponents, setSelectedComponents] = useState<any[]>([]);
  const [selectedComponentsKey, setSelectedComponentsKey] = useState<
    React.Key[]
  >([]);
  const [list, setList] = useState<List>();
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Supplier>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const [componentPagination, setComponentPagination] =
    useState<TablePaginationConfig>({
      current: 1,
      pageSize: 10,
    });
  const [componentSorter, setComponentSorter] = useState<
    SorterResult<Component>
  >({});
  const [componentFilters, setComponentFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const onSearchSupplier = (value: any) => {
    fetchSupplierData({
      searchField: true,
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };
  const onSearchComponent = (value: any) => {
    setSearchComponents({
      searchField: true,
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const supplierColumns: ColumnsType<Supplier> = [
    {
      title: "Supplier ID",
      dataIndex: "number",
      key: "number",
      sorter: true,
    },
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
    },
    {
      title: "Active Contacts",
      dataIndex: "activeContacts",
      key: "activeContacts",
      render: (value: any, record: any) => (
        <Text>
          {value}
          {!(
            record.contacts &&
            record.contacts.length &&
            record.contacts.find((e: any) => e.isPrimary)
          ) ? (
            <WarningFilled style={{ color: "red" }} />
          ) : (
            ""
          )}
        </Text>
      ),
    },
    permission?.read?.includes("components") && {
      title: "Active Components",
      dataIndex: "activeComponents",
      key: "activeComponents",
    },
    {
      title: "In Scope for",
      dataIndex: "inScope",
      key: "inScope",
      ellipsis: true,
      render: (inScope: any) => (
        <Text>{inScope?.map((e: any) => e.name).join(", ")}</Text>
      ),
    },
    {
      title: "Last Reported",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string) => <Text>{new Date(text).toLocaleString()}</Text>,
    },
  ].filter(Boolean);

  const componentColumns: ColumnsType<Component> = [
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      width: 150,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      render: (manufacturer) => (
        <Text>
          {" "}
          {manufacturer.itemNumber} <br></br>
          <small>{manufacturer.name}</small>
        </Text>
      ),
      width: 150,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 150,
      render: (text: string) => <Text>{new Date(text).toLocaleString()}</Text>,
    },
  ];

  const fetchSupplierData = (search?: any) => {
    setLoading(true);
    dispatch(
      listSuppliers({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        searchField: search?.searchField ? search?.query : "",
        filters:
          search?.query && search?.query.length
            ? encodeURIComponent(
                JSON.stringify({
                  elements: search?.query,
                  path: search?.suppliersPath,
                })
              )
            : [],
      })
    )
      .then((response: any) => {
        setSuppliers(response.suppliers);
        setSupplierCount(response.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchComponentData = (search?: any) => {
    setLoading(true);
    dispatch(
      listComponents({
        pageSize: componentPagination.pageSize,
        pageNum: componentPagination.current,
        sortBy: componentSorter.column ? componentSorter.field : ["createdAt"],
        sortDesc: componentSorter.order === "descend" ? true : false,
        softDelete: false,
        searchField: search?.searchField ? search?.query : "",
        filters:
          search?.query && search?.query.length
            ? encodeURIComponent(
                JSON.stringify({
                  elements: search?.query,
                  path: search?.componentsPath,
                })
              )
            : [],
      })
    )
      .then((response) => {
        setComponents(response.components);
        setComponentCount(response.componentCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableSupplier: TableProps<Supplier>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Supplier> | SorterResult<Supplier>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Supplier>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const handleTableComponent: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setComponentPagination(pagination);
    setComponentFilters(filters);
    setComponentSorter(sorter as SorterResult<Component>);
    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const onSelectSuppliers = (
    selectedRowKeys: React.Key[],
    selectedRows: Supplier[]
  ) => {
    setSelectedSuppliers(selectedRows);
  };

  const supplierSelection = {
    selectedRowKeys: selectedSuppliers.map((val) => val?._id),
    onChange: onSelectSuppliers,
    preserveSelectedRowKeys: true,
  };

  const onSelectComponents = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Component[]
  ) => {
    setSelectedComponentsKey(newSelectedRowKeys);
    setSelectedComponents(selectedRows);
  };

  const componentSelection: TableRowSelection<Component> = {
    selectedRowKeys: selectedComponentsKey,
    onChange: onSelectComponents,
    preserveSelectedRowKeys: true,
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
    setSelectedComponentsKey([]);
    setSelectedComponents([]);
    setSelectedSuppliers([]);
  };

  const readSingleList = () => {
    dispatch(
      readList({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        id: props.formData.id,
        type: showComponents ? "components" : "suppliers",
      })
    )
      .then((response: any) => {
        form.setFieldValue("name", response.list?.name);
        setList(response.list);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(
    () => {
      if (open) {
        readSingleList();
        showComponents
          ? fetchComponentData(searchComponents)
          : fetchSupplierData();
      }
    },
    showComponents
      ? [
          open,
          JSON.stringify(componentPagination),
          JSON.stringify(componentFilters),
          JSON.stringify(componentSorter),
          searchComponents,
        ]
      : [
          open,
          JSON.stringify(tablePagination),
          JSON.stringify(tableFilters),
          JSON.stringify(tableSorter),
        ]
  );

  const onFormSubmit = () => {
    setButtonLoading(true);
    const listName = form.getFieldValue("name")
    let formData = {};
    if (showComponents) {
      formData = {
        id: [props?.formData?.id],
        updates: {
          suppliers: selectedComponents.map(
            (val: any) => val?.manufacturer?.name
          ),
          components: selectedComponents.map((val: any) => val?._id),
          type: "componentsAdd",
          name: listName,
        },
      };
    } else {
      formData = {
        id: [props?.formData?.id],
        updates: {
          suppliers: selectedSuppliers.map((val: any) => val?.name),
          type: "suppliersAdd",
          name: listName,
        },
      };
    }
    dispatch(
      updateLists({
        ...formData,
      })
    )
      .then(() => {
        onClose();
        props.fetchData();
        setButtonLoading(false);
      })
      .catch(() => setButtonLoading(false));
  };

  const actionCards = (
    <Space style={{ display: "flex", justifyContent: "end" }}>
      <Button
        onClick={onClose}
        style={{ background: "#045B7C", marginRight: 8, color: "white" }}
        data-testid="cancel-list-button"
      >
        Cancel
      </Button>
      <Button
        onClick={form.submit}
        type="primary"
        data-testid="submit-list-button"
        loading={buttonLoading}
      >
        Save
      </Button>
    </Space>
  );

  return (
    <div>
      <Button
        type={"primary"}
        onClick={showDrawer}
        icon={<PlusOutlined />}
        data-testid="edit-list-button"
        title="Edit Survey List"
      >
        Update Survey List
      </Button>
      <Drawer
        title="Update Survey List"
        width={1000}
        onClose={onClose}
        open={open}
        extra={actionCards}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24} sm={24} md={24}>
              <Form.Item
                name="name"
                label="List Name"
                rules={[
                  { required: true, message: "Please input List Name!" },
                  {
                    pattern: /^([a-zA-Z0-9()-/.,&_@*]+\s?)*$/,
                    message: "Special Characters not allowed!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {showComponents ? (
            <Row gutter={24}>
              <Col span={24} sm={24} md={24}>
                <Space
                  align="end"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: "1rem",
                  }}
                >
                  {selectedComponents.length > 0 && (
                    <Tag> Selections {selectedComponents?.length}</Tag>
                  )}
                  <Search
                    placeholder="Search using internal item number, manufacturer name or item number"
                    onSearch={onSearchComponent}
                    onPressEnter={onSearchComponent}
                    allowClear
                    style={{ width: 350 }}
                  />
                </Space>
                <Table
                  loading={loading}
                  dataSource={components}
                  scroll={{
                    x: 1000,
                    y: "calc(100vh - 285px)",
                  }}
                  columns={componentColumns}
                  rowKey="_id"
                  pagination={{
                    ...componentPagination,
                    total: componentCount,
                    showQuickJumper: true,
                  }}
                  onChange={handleTableComponent}
                  rowSelection={componentSelection}
                />
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col span={24} sm={24} md={24}>
                <Space
                  align="end"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: "1rem",
                  }}
                >
                  {selectedSuppliers.length > 0 && (
                    <Tag>Selections {selectedSuppliers?.length}</Tag>
                  )}
                  <Search
                    placeholder="Search using name or number"
                    onSearch={onSearchSupplier}
                    onPressEnter={onSearchSupplier}
                    allowClear
                    style={{ width: 350 }}
                  />
                </Space>
                <Table
                  loading={loading}
                  dataSource={suppliers}
                  scroll={{
                    x: 1000,
                    y: "calc(100vh - 285px)",
                  }}
                  columns={supplierColumns}
                  rowKey="_id"
                  pagination={{
                    ...tablePagination,
                    total: supplierCount,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (totalCount) => `Total  ${totalCount}  items`,
                  }}
                  onChange={handleTableSupplier}
                  rowSelection={supplierSelection}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Drawer>
    </div>
  );
};

export default EditListForm;
