import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Tag,
  Typography,
  Modal,
  Input,
  Button,
  Drawer,
  message,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  ExportOutlined,
  ExclamationCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { PendingResponse } from "../../../utils/types/CampaignManager/campaignManager";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import AddComplianceApprove from "../../../components/modals/addApproveComplianceModal";
import PendingApprovalEditForm from "../../../components/forms/PendingApprovalEditForm";
import {
  readCampaignPendingResponses,
  updateCampaignResponse,
  readCampaignSupplierResponse,
} from "../../../redux/actions/CampaignManager/campaignManagerAction";
import { useAppDispatch } from "../../../redux/hooks";
import {
  ColumnsType,
  FilterDropdownProps,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { getRegulationsFromLocalStorage } from "../../../utils/localStore";

const { Text } = Typography;

const SubTable: React.FC<{
  formData: any;
  fetchData: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [rejectingItem, setRejectingItem] = useState<PendingResponse | null>(
    null
  );
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const [editingItem, setEditingItem] = useState<PendingResponse | null>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [subscriptionEnable, setSubscriptionEnable] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [supplierResponse, setSupplierResponse] = useState<any[]>([]);

  const handleOpenModal = (record: any) => {
    setSelectedItem(record);
    setIsModalVisible(true);
  };

  const handleReject = (withFeedback: boolean) => {
    if (selectedItem) {
      let bulkApprovePayload;
      if (
        props.formData.regulation !== "Material" &&
        props.formData.regulation !== "Scip"
      ) {
        bulkApprovePayload = {
          supplierComplianceResponse: selectedItem?.supplierComplianceResponse,
          type: "rejected",
          ...(withFeedback && { feedback: feedbackText || null }),
          componentId: selectedItem.componentId,
        };
      } else {
        bulkApprovePayload = {
          type: "rejected",
          ...(withFeedback && { feedback: feedbackText || null }),
          componentId: selectedItem.componentId,
        };
      }

      dispatch(
        updateCampaignResponse({
          filter: {
            sendgridCampaignId: selectedItem.sendgridCampaignId,
            componentId: selectedItem.componentId,
          },
          updates: bulkApprovePayload,
          responseType: props.formData.regulation,
        })
      )
        .then((response) => {
          message.success("Component rejected successfully");
          fetchData();
          setIsModalVisible(false);
          setFeedbackText("");
          setSelectedItem(null);
          supplierResponse?.length === 1 && props.fetchData();
        })
        .catch((error) => {
          console.error("Reject error:", error);
          message.error(
            `Rejection failed: ${
              error.response?.data?.message || error.message
            }`
          );
        });
    }
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readCampaignSupplierResponse({
        sendgridCampaignId: props.formData?.sendgridCampaignId,
        manufacturer: props.formData?.manufacturer,
        regulation: props.formData?.regulation,
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
      })
    )
      .then((response: any) => {
        setSupplierResponse(response?.supplierResponse);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleEdit = (record: PendingResponse) => {
    setEditingItem(record);
    setIsDrawerVisible(true);
  };
  const handleModalSubmit = () => {
    setIsModalVisible(false);
    setFeedbackText("");
    setRejectingItem(null);
    props.fetchData();
  };

  const handleSave = (updatedRecord: PendingResponse) => {
    // const updatedItems = items.map((item: any) =>
    //   item._id === updatedRecord._id ? updatedRecord : item
    // );
    // setIsDrawerVisible(false);
    // setEditingItem(null);
    props.fetchData();
  };
  // const [filteredItems, setFilteredItems] = useState<PendingResponse[]>(items);
  const [searchText, setSearchText] = useState<string>("");

  // const handlePartNumberSearch = (value: string) => {
  //   const filtered = items.filter(
  //     (item: PendingResponse) =>
  //       item.manufacturer.itemNumber.toLowerCase() === value.toLowerCase()
  //   );
  //   setFilteredItems(filtered);
  //   setSearchText(value);
  // };

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );

  const [tableSorter, setTableSorter] = useState<SorterResult<PendingResponse>>(
    {}
  );

  const handlePartNumberSearch = (value: string) => {
    dispatch(
      readCampaignPendingResponses({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: value,
        columnSearches: {
          manufacturerItemNumber: value,
        },
      })
    ).then((response: any) => {
      // setFilteredItems(response?.pendingResponse || []);
    });
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const columns: ColumnsType<PendingResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CloseCircleOutlined
            style={{ cursor: "pointer", fontSize: "16px", marginTop: "4px" }}
            onClick={() => handleOpenModal(record)}
          />
          <AddComplianceApprove
            type="icon"
            fetchData={fetchData}
            fetchParentData={props.fetchData}
            formData={{ ...record, regulation: props.formData.regulation }}
            supplierResponseCount={supplierResponse.length}
          />
        </Space>
      ),
    },
  ];

  const regulationColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturerPartNumber",
      sorter: true,
      width: 150,
      hidden: false,
      render: (manufacturer: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          const itemNumberMismatch =
            manufacturer.itemNumber &&
            !record.supplierComplianceResponse?.supplierPdfReader?.answers?.manufacturerItemNumber?.includes(
              manufacturer.itemNumber
            );

          let tooltipTitle = "";

          if (itemNumberMismatch) {
            tooltipTitle =
              "Act AI:Exact match not found. Check for series/part family/division-level declarations.";
          }

          return (
            <Text>
              {manufacturer}
              <br />
              {itemNumberMismatch && (
                <Tooltip title={tooltipTitle}>
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "5px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {manufacturer}
              <br />
            </Text>
          );
        }
      },
    },
    {
      title: "Regulation",
      dataIndex: "supplierComplianceResponse.regulationNumber",
      key: "supplierComplianceResponse.regulationNumber",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                : ""}
              {(record &&
                record.supplierComplianceResponse?.regulationNumber &&
                record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                  ?.toUpperCase()
                  .includes(
                    record.supplierComplianceResponse?.regulationNumber.toUpperCase()
                  )) ||
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                ?.toUpperCase()
                .includes(
                  record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                ) ||
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                ?.toUpperCase()
                .includes(
                  record.supplierComplianceResponse?.regulationNumber
                    .toUpperCase()
                    ?.replaceAll(/_|EU/g, " ")
                ) ? (
                ""
              ) : (
                <Tooltip title="Act AI:Regulation reference missing. Please validate.">
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "3px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                : ""}
            </Text>
          );
        }
      },
    },

    {
      title: "Compliant",
      dataIndex: "supplierComplianceResponse.complianceOutputs.compliant",
      key: "supplierComplianceResponse.complianceOutputs.compliant",
      sorter: true,
      width: 180,
      hidden: false,
      render: (text: any, record: any) => {
        let compliant = null;
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          compliant =
            record &&
            record.supplierComplianceResponse?.complianceOutputs?.compliant &&
            record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsCompliant?.toUpperCase() ===
              record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ? (
              ""
            ) : (
              <Tooltip title="Act AI: Status not validated. Review document for compliance confirmation.">
                <ExclamationCircleFilled
                  style={{ color: "red", marginLeft: "3px" }}
                />
              </Tooltip>
            );
        }

        if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "YES"
        ) {
          return (
            <>
              <Tag color="green"> YES </Tag>
              {compliant}
            </>
          );
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "NO"
        ) {
          return (
            <>
              <Tag color="red"> NO </Tag>
              {compliant}
            </>
          );
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "YES WITH EXEMPTION"
        ) {
          return (
            <>
              <Tag color="orange"> YES WITH EXEMPTION </Tag>
              {compliant}
            </>
          );
        } else if (
          record &&
          record.supplierComplianceResponse?.complianceOutputs &&
          record.supplierComplianceResponse?.complianceOutputs?.compliant.toUpperCase() ===
            "UNKNOWN"
        ) {
          return (
            <>
              <Tag color="default"> UNKNOWN </Tag>
              {compliant}
            </>
          );
        } else {
          return (
            <>
              <Tag color="grey">
                {record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.compliant
                  ? record.supplierComplianceResponse?.complianceOutputs
                      ?.compliant
                  : ""}
              </Tag>
              {compliant}
            </>
          );
        }
      },
    },
    {
      title: "Version",
      dataIndex: "supplierComplianceResponse.complianceOutputs.version",
      key: "supplierComplianceResponse.complianceOutputs.version",
      sorter: true,
      width: 180,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.version
                : ""}
              {record &&
              record.supplierComplianceResponse?.complianceOutputs?.version &&
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsVersion
                ?.replace(/\s/g, "")
                .includes(
                  record.supplierComplianceResponse?.complianceOutputs?.version?.replace(
                    /\s/g,
                    ""
                  )
                ) ? (
                ""
              ) : (
                <Tooltip title="Act AI: Regulation version missing/mismatched or document date unknown. Verify.">
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "3px" }}
                  />{" "}
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.version
                : ""}
            </Text>
          );
        }
      },
    },
    {
      title: "Exemptions",
      dataIndex: "supplierComplianceResponse.complianceOutputs.exemption",
      key: "supplierComplianceResponse.complianceOutputs.exemption",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  ),
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  )
                : ""}
              {record &&
                record.supplierComplianceResponse?.complianceOutputs?.exemption
                  ?.length > 0 &&
                (typeof record.supplierComplianceResponse?.supplierPdfReader
                  ?.answers?.complianceOutputsExemption === "string" &&
                record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsExemption
                  ?.replace(/\s/g, "")
                  .includes(
                    record.supplierComplianceResponse?.complianceOutputs?.exemption
                      ?.join("")
                      .replace(/\s/g, "")
                  ) ? (
                  ""
                ) : (
                  <Tooltip title="Act AI: Exemption(s) not found. Check document for specified exemptions.">
                    <ExclamationCircleFilled
                      style={{ color: "red", marginLeft: "3px" }}
                    />{" "}
                  </Tooltip>
                ))}
            </Text>
          );
        } else {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  ),
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                    ", "
                  )
                : ""}
            </Text>
          );
        }
      },
    },
    {
      title: "Substances",
      dataIndex: "supplierComplianceResponse.complianceOutputs.substance",
      key: "supplierComplianceResponse.complianceOutputs.substance",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs
                    ?.substance,
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs
                    ?.substance
                : ""}
              {record &&
                record.supplierComplianceResponse?.complianceOutputs?.substance
                  ?.length > 0 &&
                (record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsSubstances
                  ?.replace(/\s/g, "")
                  .includes(
                    record.supplierComplianceResponse?.complianceOutputs?.substance?.replace(
                      /\s/g,
                      ""
                    )
                  ) ? (
                  ""
                ) : (
                  <Tooltip title="Act AI:Substance of Concern not identified. Review document for details.">
                    <ExclamationCircleFilled
                      style={{ color: "red", marginLeft: "3px" }}
                    />{" "}
                  </Tooltip>
                ))}
            </Text>
          );
        } else {
          return (
            <Text
              style={{ width: 200 }}
              ellipsis={{
                tooltip:
                  record.supplierComplianceResponse?.complianceOutputs
                    ?.substance,
              }}
            >
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.complianceOutputs
                    ?.substance
                : ""}
            </Text>
          );
        }
      },
    },
    {
      title: "Concentration of PFAS",
      dataIndex:
        "supplierComplianceResponse.complianceOutputs.concentrationOfPfas",
      key: "supplierComplianceResponse.complianceOutputs.concentrationOfPfas",
      sorter: true,
      width: 150,
      hidden: regulations.filter((e: any) =>
        e.configuration.find((v: any) =>
          v.value.includes("complianceOutputs.concentrationOfPfas")
        )
      ).length
        ? false
        : true,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs
                ?.concentrationOfPfas
            : ""}
        </Text>
      ),
    },
    {
      title: "Concentration Unit",
      dataIndex:
        "supplierComplianceResponse.complianceOutputs.concentrationUnit",
      key: "supplierComplianceResponse.complianceOutputs.concentrationUnit",
      sorter: true,
      width: 150,
      hidden: regulations.filter((e: any) =>
        e.configuration.find((v: any) =>
          v.value.includes("complianceOutputs.concentrationUnit")
        )
      ).length
        ? false
        : true,
      render: (text: any, record: any) => (
        <Text>
          {record && record.supplierComplianceResponse
            ? record.supplierComplianceResponse?.complianceOutputs
                ?.concentrationUnit
            : ""}
        </Text>
      ),
    },
    {
      title: "File",
      dataIndex: "complianceDocument",
      key: "complianceDocument",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: string, record: any) => {
        return record.supplierComplianceResponse?.complianceDocument ? (
          record.supplierComplianceResponse?.complianceDocument?.includes(
            "http"
          ) ? (
            <span>
              <ExportOutlined
                onClick={() =>
                  onClickViewLink(
                    record.supplierComplianceResponse?.complianceDocument
                  )
                }
              />{" "}
              Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="campaignmanager/document/download"
              filename={record.supplierComplianceResponse?.complianceDocument}
              foldername="campaign"
              campaignId={record.sendgridCampaignId}
            />
          )
        ) : null;
      },
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierComplianceResponse
            ? new Date(
                record.supplierComplianceResponse?.updatedAt
              ).toLocaleString()
            : ""}
          <br />
          {record.supplierComplianceResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  const materialColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturer.itemNumber",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => <Text>{manufacturer}</Text>,
    },
    {
      title: "FMD Document",
      dataIndex: "supplierMaterialsResponse.fmdDocument",
      key: "supplierMaterialsResponse.fmdDocument",
      width: 150,
      render: (text: string, record: any) => {
        return record.supplierMaterialsResponse?.fmdDocument ? (
          <ReadSupportingDocument
            postUrl="public/suppliers/document/download"
            filename={record.supplierMaterialsResponse?.fmdDocument}
            foldername="campaign"
            subfolder="materials"
            campaignId={record.sendgridCampaignId}
          />
        ) : null;
      },
    },
    {
      title: "Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierMaterialsResponse?.partWeight}{" "}
          <small>{record.supplierMaterialsResponse?.partWeightUnit}</small>
        </Text>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierMaterialsResponse
            ? new Date(
                record.supplierMaterialsResponse?.updatedAt
              ).toLocaleString()
            : ""}
          <br />
          {record.supplierMaterialsResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  const scipColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturer.itemNumber",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => <Text>{manufacturer}</Text>,
    },
    {
      title: "Article ID Type",
      dataIndex: "supplierScipResponse.PrimaryArticleIdentifierType",
      key: "supplierScipResponse.PrimaryArticleIdentifierType",
      //sorter: true,
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierScipResponse
            ? record?.supplierScipResponse?.PrimaryArticleIdentifierType?.text
            : ""}
        </Text>
      ),
    },
    {
      title: "Article ID Value",
      dataIndex: "supplierScipResponse.PrimaryArticleIdentifierValue",
      key: "supplierScipResponse.PrimaryArticleIdentifierValue",
      //sorter: true,
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierScipResponse?.PrimaryArticleIdentifierValue}
        </Text>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {record.supplierScipResponse
            ? new Date(record.supplierScipResponse?.updatedAt).toLocaleString()
            : ""}
          <br />
          {record.supplierScipResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  if (props.formData.regulation === "Material") {
    columns.push(...materialColumns);
  } else if (props.formData.regulation === "Scip") {
    columns.push(...scipColumns);
  } else {
    columns.push(...regulationColumns);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Table
        columns={columns}
        dataSource={supplierResponse}
        pagination={false}
        rowKey="componentId"
        style={{
          background: "#E6F7FF",
          marginLeft: -16,
          marginRight: -16,
          width: "calc(100% + 32px)",
        }}
        scroll={{ x: "max-content" }}
        components={{
          header: {
            cell: (props: React.HTMLAttributes<HTMLTableHeaderCellElement>) => (
              <th
                {...props}
                style={{
                  backgroundColor: "#045B7C",
                  color: "white",
                  fontWeight: "bold",
                  padding: "12px 16px",
                }}
              />
            ),
          },
        }}
      />
      <Modal
        title="Feedback for Rejection"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setFeedbackText("");
        }}
        footer={[
          <>
            <Button
              onClick={() => handleReject(false)}
              style={{
                backgroundColor: "#00516E",
                color: "white",
                borderColor: "#00516E",
                marginRight: "8px",
              }}
            >
              Reject without Feedback
            </Button>
            <Button
              type="primary"
              onClick={() => handleReject(true)}
              style={{
                backgroundColor: "#F7863E",
                borderColor: "#F7863E",
              }}
            >
              Submit
            </Button>
          </>,
        ]}
        centered
        maskClosable={false}
      >
        <Input.TextArea
          rows={4}
          placeholder="Type Feedback"
          onChange={(e) => setFeedbackText(e.target.value)}
          value={feedbackText}
          style={{
            borderColor: "#ACE8FF",
            marginBottom: "50px",
          }}
        />
      </Modal>
      <Drawer
        visible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        width={600}
        closable={false}
        bodyStyle={{ padding: 0 }}
      >
        {editingItem && (
          <PendingApprovalEditForm
            record={editingItem}
            onCancel={() => setIsDrawerVisible(false)}
            onSave={handleSave}
          />
        )}
      </Drawer>
    </>
  );
};

export default SubTable;
