import React, { useState } from "react";
import { Modal, Input, Button } from "antd";

interface ApprovalCommentModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: (comment: any) => void;
}

const ApprovalCommentModal: React.FC<ApprovalCommentModalProps> = ({
  isVisible,
  onCancel,
  onSubmit,
}) => {
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    try {
      onSubmit(comment);
      setComment("");
    } catch (error) {
      console.error("Submission failed:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Comment for Approval"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      width={400}
      centered
      closable={false}
    >
      <Input.TextArea
        rows={4}
        placeholder="Enter description"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        style={{ marginBottom: "20px", borderColor: "#ACE8FF" }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={onCancel}
          style={{
            marginRight: "10px",
            backgroundColor: "#00516E",
            color: "white",
            borderColor: "#00516E",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            backgroundColor: "#F7863E",
            borderColor: "#F7863E",
            color: "white",
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ApprovalCommentModal;
