import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FilterOutlined,
  EyeOutlined,
  ExperimentOutlined,
  ExportOutlined,
  FlagFilled,
  CodeSandboxOutlined,
  CommentOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  Tabs,
  Tag,
  Tooltip,
  Spin,
  Card,
  Col,
  Empty,
  Row,
  List,
  Pagination,
  Dropdown,
  Menu,
  MenuProps,
  Select,
  Checkbox,
  CheckboxOptionType,
} from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";

import { Component } from "../../../../utils/types/component";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  listComponents,
  listComponentFilters,
} from "../../../../redux/actions/componentsAction";
import { Regulation } from "../../../../utils/types/regulation";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import ComponentForm from "../../../../components/forms/ComponentForm";
import ComponentEditForm from "../../../../components/modals/editComponentModal";
import ComplianceForm from "../../../../components/forms/ComplianceForm";
import BulkEditSpecifications from "../../../../components/modals/BulkEditSpecifications";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";
import ImportDataForm from "../../../../components/forms/ImportDataForm";
import ErrorBoundary from "../../../../utils/errorBoundary";
import EditMaterialDisclosureComponents from "../../../../components/modals/editMaterialDisclosureComponent";
import Meta from "antd/es/card/Meta";
import AddToCampaignListModal from "../../../../components/modals/AddToCampaignListModal";

const { Search } = Input;
const { Title, Text } = Typography;

const ComponentList: React.FC = () => {
  const dispatch = useAppDispatch();

  const [components, setComponents] = useState<Component[]>([]);
  const [componentCount, setComponentCount] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>("all");
  const [search, setSearch] = useState<any>();
  const navigate = useNavigate();
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [selectedComponents, setSelectedComponents] = useState<{
    key: string;
    value: any;
  } | null>(null);
  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter" ? value?.target?.value.trim() : value.trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [filterData, setFilterData] = useState<any>({});
  const [filterOptions, setFilterOptions] = useState<any>({});
  const user = getLocalStorage("user");
  const parseUser = user ? user : null;
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  interface componentProfileParams {
    id: string;
  }
  // const [menuVisible, setMenuVisible] = useState(false);
  const fetchFilterData = (
    columnName: string,
    columnSearch?: string,
    filterValue?: any
  ) => {
    if (filterData[columnName]?.length > 0) {
      return;
    }
    setLoadingData(true);
    dispatch(
      listComponentFilters({
        column: columnName,
        value: columnSearch ?? "",
        filter: JSON.stringify(filterValue),
      })
    ).then((response: any) => {
      setFilterData((prev: any) => ({ ...prev, [columnName]: response }));
      setFilterOptions((prev: any) => ({ ...prev, [columnName]: response }));
      setLoadingData(false);
      // setMenuVisible(true);
    });
  };
  // const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  // const [selectedAssignedTo, setSelectedAssignedTo] = useState<string[]>([]);
  // const [selectedRisk, setSelectedRisk] = useState<string[]>([]);
  // const [selectedComponentStatus, setSelectedComponentStatus] = useState<
  //   string[]
  // >([]);
  // const [selectedOutOfScope, setSelectedOutOfScope] = useState<string[]>([]);
  // const [selectedWhereUsed, setSelectedWhereUsed] = useState<string[]>([]);
  // const [key, setKey] = useState<string | null>(null);
  // const [advancedropdown, setadvanceDropdown] = useState(false);

  const onSelectComponents = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Component[]
  ) => {
    setSelectedComponents({
      key: activeTab,
      value: selectedRows,
    });
  };

  const componentSelection: TableRowSelection<Component> = {
    selectedRowKeys:
      selectedComponents && selectedComponents.value
        ? selectedComponents?.value?.map((e: any) =>
            e.componentId ? e.componentId : e._id
          )
        : [],
    onChange: onSelectComponents,
    preserveSelectedRowKeys: true,
  };

  const handleTabChange = (key: any) => {
    setActiveTab(key);
    setSelectedComponents(null);
  };

  const handlePreviewClick = (record: any) => {
    const params: componentProfileParams = {
      id: record?.componentId ? record?.componentId : record._id,
    };
    navigate(`${params.id}`);
  };

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const componentsList = components?.map((item) => {
    const itemValue: Component = { ...item, componentId: item._id };

    regulations?.forEach((e: Regulation) => {
      const compliance = item.compliances?.find(
        (o) => o.regulationNumber === e.regulationNumber
      );
      itemValue[e.regulationNumber] = compliance
        ? {
            ...compliance,
            internalItemNumber: item.internalItemNumber,
            manufacturer: item.manufacturer,
            componentId: item._id,
          }
        : {
            internalItemNumber: item.internalItemNumber,
            manufacturer: item.manufacturer,
            componentId: item._id,
          };
    });

    return itemValue;
  });

  // const menu = (value: any): MenuProps => ({
  //   items: [
  //     {
  //       key: "Delete",
  //       onClick: () => handlePreviewClick(value),
  //       label: "Delete",
  //       icon: <DeleteOutlined />,
  //     },
  //     {
  //       key: "Preview",
  //       onClick: () => handlePreviewClick(value),
  //       label: "Preview",
  //       icon: <EyeOutlined />,
  //     },
  //   ],
  // });
  const columns: ColumnsType<Component> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      fixed: "left",
      render: (text: string, record: any) => (
        <Space>
          {permissions?.update?.includes("components") && (
            <ComponentEditForm
              formData={record}
              fetchData={fetchData}
              activeTab="all"
            />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-component-button"
            title="Preview"
          />
          {/* <Dropdown menu={menu(record)}>
            <Space>
              <Button type="text" icon={<MoreOutlined />} />
            </Space>
          </Dropdown> */}
        </Space>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      fixed: "left",
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("internalItemNumber"),
      filters: filterData?.internalItemNumber || [],
      filteredValue: tableFilters.internalItemNumber || null,
      filterSearch: true,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer.name",
      key: "manufacturer.name",
      sorter: true,
      fixed: "left",
      render: (_: any, record: any) => (
        <Text>
          {record.manufacturer.itemNumber}
          <br></br>
          <small>{record.manufacturer.name}</small>
        </Text>
      ),
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("manufacturer.name"),
      filters: filterData["manufacturer.name"] || [],
      filteredValue: tableFilters["manufacturer.name"] || null,
      filterSearch: true,
    },
    ...(parseUser?.specifications || [])
      .filter((o: any) => !o.hidden)
      .map((e: any) => ({
        title: e.name.charAt(0).toUpperCase() + e.name.slice(1),
        dataIndex: `specification.${e.value}`,
        key: `specification.${e.value}`,
        sorter: true,
        render(_: any, record: any) {
          if (e.dataType === "Dropdown") {
            if (
              record.specification &&
              record.specification[e.value]?.toUpperCase().trim() === "ACTIVE"
            ) {
              return <Tag color="green">{record.specification[e.value]}</Tag>;
            } else if (
              record.specification &&
              record.specification[e.value]?.toUpperCase().trim() === "NRND"
            ) {
              return <Tag color="orange">{record.specification[e.value]}</Tag>;
            } else if (
              record.specification &&
              record.specification[e.value]?.toUpperCase().trim() === "UNKNOWN"
            ) {
              return <Tag color="grey">{record.specification[e.value]}</Tag>;
            } else if (record.specification && record.specification[e.value]) {
              return record.specification && record.specification[e.value] ? (
                <Tag color="red">{record.specification[e.value]}</Tag>
              ) : (
                ""
              );
            } else {
              return record.specification && record.specification[e.value] ? (
                <Tag color="default">{record.specification[e.value]}</Tag>
              ) : (
                ""
              );
            }
          } else if (e.dataType === "Fileinput") {
            return (
              <>
                {record?.specification &&
                record?.specification[e.value]?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(record?.specification[e.value])
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="products/components/document/download"
                    filename={
                      record?.specification && record?.specification[e.value]
                    }
                    foldername="components"
                  />
                )}
              </>
            );
          } else if (e.dataType === "Dateinput") {
            return record?.specification && record?.specification[e.value]
              ? new Date(record?.specification[e.value])?.toLocaleDateString()
              : "";
          } else {
            return (
              (record.specification && record.specification[e.value]) || ""
            );
          }
        },
        onFilterDropdownOpenChange: (open: any) =>
          open && fetchFilterData(`specification.${e.value}`),
        filters: e.filter ? filterData[`specification.${e.value}`] || [] : null,
        filteredValue: tableFilters[`specification.${e.value}`] || null,
        filterSearch: true,
      })),
    permissions?.read.includes("materials") && {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    permissions?.read.includes("materials") && {
      title: "Part Weight in mg",
      dataIndex: "partWeight",
      key: "partWeight",
    },
    ...(regulations || []).map((e: Regulation) => ({
      title: e.name,
      children: e.configuration
        .filter(
          (o) =>
            !o.hidden &&
            o.value !== "manufacturer" &&
            o.value !== "internalItemNumber" &&
            o.value !== "operations"
        )
        .map((k: any) => ({
          title: k.text,
          dataIndex: `${e.regulationNumber}.${k.value}`,
          key: `${e.regulationNumber}.${k.value}`,
          render: (value: any, record: any) => {
            const compliances = record.compliances.find(
              (item: any) => item.regulationNumber === e.regulationNumber
            );
            if (k.value === "complianceDocument") {
              return compliances && compliances.complianceDocument ? (
                compliances?.complianceDocument?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(compliances?.complianceDocument)
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="compliances/document/download"
                    filename={compliances.complianceDocument}
                    foldername="compliances"
                  />
                )
              ) : null;
            } else if (k.value.includes("complianceOutputs.compliant")) {
              if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "YES"
              ) {
                return <Tag color="green"> YES </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() === "NO"
              ) {
                return <Tag color="red"> NO </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.compliant?.toUpperCase() ===
                  "YES WITH EXEMPTION"
              ) {
                return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "UNKNOWN"
              ) {
                return <Tag color="default"> UNKNOWN </Tag>;
              } else {
                return compliances && compliances.complianceOutputs ? (
                  <Tag color="grey">
                    {compliances &&
                      compliances.complianceOutputs &&
                      compliances.complianceOutputs.compliant}
                  </Tag>
                ) : (
                  ""
                );
              }
            } else if (k.value.includes("complianceOutputs.version")) {
              return (
                <Text>{`${
                  compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.version
                    ? compliances.complianceOutputs.version
                    : ""
                }`}</Text>
              );
            } else if (k.value.includes("complianceOutputs.exemption")) {
              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip: compliances?.complianceOutputs?.exemption,
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.exemption
                    ? compliances.complianceOutputs.exemption.toString()
                    : ""}
                </Text>
              );
            } else if (k.value.includes("complianceOutputs.substance")) {
              return (
                <Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip: compliances?.complianceOutputs?.substance,
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.substance
                    ? compliances.complianceOutputs.substance.toString()
                    : ""}
                </Text>
              );
            } else {
              return (
                <Text>
                  {compliances && compliances?.complianceOutputs
                    ? compliances?.complianceOutputs[
                        k.value?.split(".").pop().toString()
                      ]
                    : ""}
                </Text>
              );
            }
          },
          onFilterDropdownOpenChange: (open: any) =>
            open &&
            fetchFilterData(`compliances.${k.value}`, "", {
              "compliances.regulationNumber": e.regulationNumber,
            }),
          filters: k.filter ? filterData[`compliances.${k.value}`] || [] : null,
          filteredValue:
            tableFilters[`${e.regulationNumber}.${k.value}`] || null,
        })),
    })),
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
  ].filter(Boolean);

  const getRegulation = (componentId: string) => {
    const data =
      activeTab === "materials"
        ? componentsList.filter(
            (val: { _id: string }) => val._id === componentId
          )[0]
        : componentsList.filter((val) => val._id === componentId)[0];
    const materials = data?.materials;
    const extractRegulation: any[] = materials
      ?.flatMap((val: { substances: any[] }) =>
        val.substances?.map(
          (ele: any) =>
            ele?.compliantalert === "Yes" && ele.compliantalertRegulation
        )
      )
      .filter((val: any) => val !== false)
      .flat();

    const regulationData = extractRegulation?.filter((item, index, array) => {
      return array.indexOf(item) === index;
    });

    const regulationName = regulations
      .filter((v: any) => regulationData?.includes(v.regulationNumber))
      .map((e: any) => e.name);
    return (
      activeTab === "materials" &&
      renderRegulationList(regulationName, componentId)
    );
  };

  const getSubstance = (val: any, regulationNumber: string) => {
    const combine = val
      ?.flatMap((element: any) =>
        element.substances.map((val: any) =>
          val?.compliantalert?.toLowerCase() === "yes" &&
          val?.compliantalertRegulation === regulationNumber
            ? val.name.substance
              ? val.name.substance
              : val.name
            : ""
        )
      )
      .filter((val: any) => val !== false);
    const substances = combine
      .filter((item: any, index: any, array: any) => {
        return array.indexOf(item) === index;
      })
      .filter((v: any) => v && v);
    return substances.join(",");
  };

  const getSubstancesForCompliance = (
    record: any,
    regulationNumber: string
  ) => {
    const data = componentsList.filter(
      (element) => element._id === record.componentId
    )[0];
    return data.materials &&
      data.materials.length > 0 &&
      data.materials.find((record) =>
        record.substances?.find(
          (ele: any) =>
            ele !== undefined && ele?.compliantalert?.toLowerCase() === "yes"
        )
      )
      ? getSubstance(data.materials, regulationNumber)
      : "";
  };

  const fmdHeaders = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space>
          {permissions?.update?.includes("components") && (
            <EditMaterialDisclosureComponents
              type={"Component"}
              fetchData={fetchData}
              formData={{
                component: record,
                material: "",
              }}
              editPage={"components"}
            />
          )}
          {permissions?.update?.includes("components") && (
            <ImportDataForm
              type="create"
              fetchData={fetchData}
              xmlImport={true}
              importTypeData={{
                manufacturer: record.manufacturer,
                id: record.componentId,
              }}
            />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-component-button"
            title="Preview"
          />
        </Space>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("internalItemNumber"),
      filters: filterData?.internalItemNumber || [],
      filteredValue: tableFilters.internalItemNumber || null,
      filterSearch: true,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer.name",
      key: "manufacturer.name",
      sorter: true,
      render: (_: any, record: any) => (
        <Text>
          {record.manufacturer.itemNumber}
          <br></br>
          <small>{record.manufacturer.name}</small>
        </Text>
      ),
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("manufacturer.name"),
      filters: filterData["manufacturer.name"] || [],
      filteredValue: tableFilters["manufacturer.name"] || null,
      filterSearch: true,
    },
    {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    {
      title: "Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
    },
    {
      title: "Flag",
      dataIndex: "materials",
      key: "materials.substances.compliantalertRegulation",
      width: 150,
      render: (text: any, record: any) => (
        <>
          {record.materials &&
            record.materials.length > 0 &&
            record.materials.find(
              (val: any) =>
                val.substances &&
                val.substances.find(
                  (ele: any) =>
                    ele &&
                    ele?.compliantalert &&
                    ele?.compliantalert.toLowerCase() === "yes"
                )
            ) && (
              <Tooltip title={getRegulation(record._id)}>
                <ExperimentOutlined style={{ color: "red" }} />
              </Tooltip>
            )}
        </>
      ),
      onFilterDropdownOpenChange: (open: any) =>
        open &&
        fetchFilterData("materials.substances.compliantalertRegulation"),
      filters:
        filterData["materials.substances.compliantalertRegulation"] || [],
      filteredValue:
        tableFilters["materials.substances.compliantalertRegulation"] || null,
      filterSearch: true,
    },
    {
      title: "FMD Comments",
      dataIndex: "fmdComments",
      key: "fmdComments",
      width: 150,
      render: (text: string) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
  ];

  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    setLoading(true);
    dispatch(
      listComponents({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["updatedAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        softDelete: false,
        searchField:
          !filters.length && search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        dataType: JSON.stringify({
          compliant: "Dropdown",
          version: "Dropdown",
        }),
        filters: filters.length
          ? JSON.stringify({
              elements: filters,
              path: [],
            })
          : [],
      })
    )
      .then((response) => {
        setComponents(response.components);
        setComponentCount(response.componentCount);
        setmaterialSubstanceSelect(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const [maxDisplayCompliance, setmaxDisplayCompliance] = useState<number>(4);

  const renderRegulationList = (regulationList: any, data: string) => {
    if (regulationList?.length <= maxDisplayCompliance) {
      {
        regulationList?.length > 0 && (
          <FlagFilled style={{ color: "red", marginRight: 5 }} />
        );
      }
      return (
        <>
          {regulationList?.length > 0 && (
            <>
              <FlagFilled style={{ color: "red", marginRight: 5 }} />
              {regulationList?.map((regulation: any, index: number) => (
                <Tag
                  key={index}
                  bordered={false}
                  style={{
                    background:
                      selectedComponentData?._id === data
                        ? "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))"
                        : "grey",
                    color: "white",
                  }}
                >
                  {regulation}
                </Tag>
              ))}
            </>
          )}
        </>
      );
    } else {
      const visibleItems = regulationList?.slice(0, maxDisplayCompliance);
      const remainingItems = regulationList?.slice(maxDisplayCompliance);
      const remainingCount = regulationList?.length - maxDisplayCompliance;

      return (
        <>
          {visibleItems &&
            visibleItems?.map((regulation: any, index: number) => (
              <Tag
                key={index}
                style={{
                  backgroundColor:
                    selectedComponentData?._id === data ? "#000116" : "grey",
                  color: "white",
                }}
              >
                {regulation}
              </Tag>
            ))}
          <Tooltip
            title={
              remainingItems &&
              remainingItems?.map((item: any) => item).join(", ")
            }
          >
            <span
              style={{
                color:
                  selectedComponentData?._id === data ? "#eeeeee" : "#000116",
              }}
            >
              {remainingCount
                ? `
              + ${remainingCount} more`
                : ""}
            </span>
          </Tooltip>
        </>
      );
    }
  };

  const [selectedComponentData, setselectedComponentData] =
    useState<Component | null>(null);
  const [selectedMaterialData, setselectedMaterialData] =
    useState<Component | null>(null);
  const [materialSubstanceSelect, setmaterialSubstanceSelect] = useState(true);

  if (materialSubstanceSelect && componentsList) {
    setselectedComponentData(componentsList && componentsList[0]);
    setselectedMaterialData(
      componentsList[0]?.materials && componentsList[0]?.materials[0]
    );
    setmaterialSubstanceSelect(false);
  }
  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    const entries = Object.entries(filters);
    const filteredEntries = entries.filter(([key, value]) => value !== null);
    const cleanFilters = Object.fromEntries(filteredEntries);
    setTablePagination(pagination);
    setTableFilters(cleanFilters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      // setComponents([]);
    }
  };

  const onChildClick = () => {
    setSelectedComponents(null);
  };

  const regulationsTabs = regulations?.map((regulation: any) => ({
    key: regulation.regulationNumber,
    label: regulation.name,
    children: (
      <>
        <Space
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
            flexWrap: "wrap",
          }}
        >
          {selectedComponents?.key === activeTab &&
            selectedComponents?.value?.length > 0 && (
              <Tag>Selections {selectedComponents?.value?.length}</Tag>
            )}
          {Object.keys(tableFilters).length > 0 &&
            Object.keys(tableFilters).reduce<JSX.Element[]>((acc, key) => {
              const elements =
                tableFilters[key]?.map((item, idx) => (
                  <Tag
                    className="filter-tag"
                    key={`${key}-${idx}`}
                    bordered={false}
                    closable
                    onClose={() => {
                      const tablefiltrs = { ...tableFilters };
                      const arr = tablefiltrs[key];
                      if (arr) {
                        const arrIndex = arr?.indexOf(item);
                        if (arrIndex !== -1) {
                          arr.splice(arrIndex, 1);
                        }
                        setTableFilters({ ...tablefiltrs });
                      }
                    }}
                  >
                    {typeof item === "string" && item}
                  </Tag>
                )) || [];
              acc.push(...elements);
              return acc;
            }, [])}
          {Object.keys(tableFilters).length > 0 ? (
            <Button
              icon={<FilterOutlined />}
              onClick={() => {
                setTableFilters({});
                setSearch({});
                // setSelectedRisk([]);
                // setSelectedCountries([]);
                // setSelectedAssignedTo([]);
                // setSelectedComponentStatus([]);
                // setSelectedOutOfScope([]);
                // setSelectedWhereUsed([]);
              }}
            >
              Reset Filters
            </Button>
          ) : (
            <Search
              placeholder="Search using internal item number, manufacturer name or item number"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          )}

          {/* {permissions?.create?.includes("compliances") && (
            <ComplianceForm
              type="create"
              formRegulations={{
                name: regulation.name,
                regulationNumber: regulation?.regulationNumber,
              }}
              fetchData={fetchData}
            />
          )} */}
          {permissions?.update?.includes("compliances") &&
            selectedComponents?.key === activeTab &&
            selectedComponents.value.length > 0 && (
              <ComplianceForm
                type="bulk"
                formRegulations={{
                  name: regulation.name,
                  regulationNumber: regulation?.regulationNumber,
                }}
                fetchData={fetchData}
                formData={selectedComponents?.value}
                onChildClick={onChildClick}
              />
            )}
          {/* <Button type="text" icon={<FilterOutlined />} /> */}
        </Space>
        {permissions?.read?.includes("compliances") && (
          <Table
            loading={loading}
            dataSource={componentsList?.map(
              (e: any) => e[regulation.regulationNumber]
            )}
            scroll={{
              x: regulation.configuration.length * 240,
              y: "calc(100vh - 285px)",
            }}
            rowSelection={componentSelection}
            columns={[
              {
                title: "Action",
                key: "operation",
                width: 150,
                render: (_, record) => (
                  <Space>
                    {permissions?.update.includes("components") && (
                      <ComponentEditForm
                        formData={
                          componentsList.filter(
                            (e: any) => e._id === record?.componentId
                          )[0]
                        }
                        fetchData={fetchData}
                        activeTab={regulation.regulationNumber}
                      />
                    )}
                    <Button
                      type="text"
                      icon={<EyeOutlined />}
                      onClick={() => handlePreviewClick(record)}
                      data-testid="preview-component-button"
                      title="Preview"
                    />
                  </Space>
                ),
              },
              ...regulation.configuration
                .filter((e: any) => !e.hidden)
                .map((k: any, i: any) => ({
                  title: `${k.text}`,
                  dataIndex: `${k.value}`,
                  key: `${
                    k.value === "manufacturer"
                      ? "manufacturer.name"
                      : k.value.includes("complianceOutputs")
                      ? regulation.regulationNumber + "." + k.value
                      : k.value
                  }`,
                  sorter: true,
                  render: (value: any, record: any) => {
                    if (k.value === "internalItemNumber") {
                      return (
                        <>
                          <Text>{`${record?.internalItemNumber}`}</Text>
                          {
                            <Tooltip
                              title={getSubstancesForCompliance(
                                record,
                                regulation.regulationNumber
                              )}
                            >
                              {getSubstancesForCompliance(
                                record,
                                regulation.regulationNumber
                              ).length > 0 && (
                                <ExperimentOutlined style={{ color: "red" }} />
                              )}
                            </Tooltip>
                          }
                        </>
                      );
                    }
                    if (k.value === "manufacturer") {
                      return (
                        <Text>
                          {value?.itemNumber}
                          <br></br>
                          <small>{value?.name}</small>
                        </Text>
                      );
                    } else if (k.value === "complianceDocument") {
                      return value ? (
                        value?.includes("http") ? (
                          <span>
                            <ExportOutlined
                              onClick={() => onClickViewLink(value)}
                            />{" "}
                            Link
                          </span>
                        ) : (
                          <ReadSupportingDocument
                            postUrl="compliances/document/download"
                            filename={value}
                            foldername="compliances"
                          />
                        )
                      ) : null;
                    } else if (
                      k.value.includes("complianceOutputs.compliant")
                    ) {
                      if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "YES"
                      ) {
                        return <Tag color="green"> YES </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "NO"
                      ) {
                        return <Tag color="red"> NO </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "YES WITH EXEMPTION"
                      ) {
                        return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "UNKNOWN"
                      ) {
                        return <Tag color="default"> UNKNOWN </Tag>;
                      } else {
                        return record && record.complianceOutputs ? (
                          <Tag color="grey">
                            {record &&
                              record.complianceOutputs &&
                              record.complianceOutputs.compliant}
                          </Tag>
                        ) : (
                          ""
                        );
                      }
                    } else if (k.value.includes("complianceOutputs.version")) {
                      return (
                        <Text>{`${
                          record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.version
                            ? record.complianceOutputs.version
                            : ""
                        }`}</Text>
                      );
                    } else if (
                      k.value.includes("complianceOutputs.exemption")
                    ) {
                      return (
                        <Text
                          style={{ width: 200 }}
                          ellipsis={{
                            tooltip: record?.complianceOutputs?.exemption,
                          }}
                        >
                          {record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.exemption
                            ? record.complianceOutputs.exemption
                            : ""}
                        </Text>
                      );
                    } else if (
                      k.value.includes("complianceOutputs.substance")
                    ) {
                      return (
                        <>
                          <Text>
                            {getSubstancesForCompliance(
                              record,
                              regulation.regulationNumber
                            )}
                          </Text>
                          <Text
                            style={{ width: 200 }}
                            ellipsis={{
                              tooltip: record?.complianceOutputs?.substance,
                            }}
                          >
                            {record &&
                            record.complianceOutputs &&
                            record.complianceOutputs.substance
                              ? record.complianceOutputs.substance
                              : ""}
                          </Text>
                        </>
                      );
                    } else {
                      return (
                        <Text>
                          {record && record?.complianceOutputs
                            ? record?.complianceOutputs[
                                k.value?.split(".").pop().toString()
                              ]
                            : ""}
                        </Text>
                      );
                    }
                  },
                  onFilterDropdownOpenChange: (open: any) =>
                    open &&
                    fetchFilterData(
                      `${
                        k.value === "manufacturer"
                          ? "manufacturer.name"
                          : k.value.includes("complianceOutputs")
                          ? "compliances." + k.value
                          : k.value
                      }`,
                      "",
                      {
                        "compliances.regulationNumber":
                          regulation.regulationNumber,
                      }
                    ),
                  filters: k.filter
                    ? filterData[
                        `${
                          k.value === "manufacturer"
                            ? "manufacturer.name"
                            : k.value.includes("complianceOutputs")
                            ? "compliances." + k.value
                            : k.value
                        }`
                      ] || []
                    : null,
                  filteredValue:
                    tableFilters[
                      `${
                        k.value === "manufacturer"
                          ? "manufacturer.name"
                          : k.value.includes("complianceOutputs")
                          ? regulation.regulationNumber + "." + k.value
                          : k.value
                      }`
                    ] || null,
                  filterSearch: true,
                })),
            ]}
            rowKey="componentId"
            pagination={{
              ...tablePagination,
              total: componentCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
          />
        )}
      </>
    ),
  }));
  // const handleDropdownChange = (isOpen: boolean) => {
  //   if (advancedropdown) {
  //     setadvanceDropdown(isOpen);

  //   }
  // };
  // const createDropdownMenu = (
  //   key: string,
  //   // options: { label: string; value: string }[],
  //   selectedOptions: string[],
  //   setSelectedOptions: React.Dispatch<React.SetStateAction<any>>
  // ): MenuProps["items"] => {
  //   const handleSearch = (searchQuery: string) => {
  //     if (searchQuery) {
  //       const options = searchQuery
  //         ? filterData[key]?.filter((option: any) =>
  //             option?.text?.toLowerCase().includes(searchQuery.toLowerCase())
  //           )
  //         : filterData[key];
  //       setFilterOptions((prev: any) => ({ ...prev, [key]: options }));
  //     } else {
  //       setFilterOptions(filterData);
  //     }
  //   };
  //   return [
  //     {
  //       key: "search",
  //       label: (
  //         <div onClick={(e) => e.stopPropagation()}>
  //           <Search
  //             placeholder="Search"
  //             onChange={(e) => handleSearch(e.target.value)}
  //             onSearch={handleSearch}
  //           />
  //         </div>
  //       ),
  //     },
  //     {
  //       key: "checkbox-group",
  //       label: (
  //         <div onClick={(e) => e.stopPropagation()}>
  //           <Checkbox.Group
  //             style={{ display: "block" }}
  //             options={filterOptions[key]?.map((option: any) => ({
  //               label: option.text,
  //               value: option.value,
  //             }))}
  //             value={selectedOptions}
  //             onChange={(checkedValues) => {
  //               if (setSelectedOptions) {
  //                 setSelectedOptions(checkedValues as string[]);
  //               }
  //             }}
  //           />
  //         </div>
  //       ),
  //     },
  //     {
  //       key: "confirm",
  //       label: (
  //         <Space>
  //           <Button
  //             type="primary"
  //             onClick={() => {
  //               const advancedFilters = {
  //                 risk: selectedRisk || null,
  //                 countryOfOrigin: selectedCountries || null,
  //                 assignedTo: selectedAssignedTo || null,
  //                 status: selectedComponentStatus || null,
  //                 outOfScope: selectedOutOfScope || null,
  //                 productRefs: selectedWhereUsed || null,
  //               };
  //               setTableFilters((prevFilters) => ({
  //                 ...prevFilters,
  //                 ...Object.fromEntries(
  //                   Object.entries(advancedFilters).filter(
  //                     ([, value]) => value && value.length > 0
  //                   )
  //                 ),
  //               }));
  //               setTablePagination({
  //                 ...tablePagination,
  //                 current: 1,
  //               });
  //               fetchData();
  //             }}
  //           >
  //             OK
  //           </Button>
  //           {(selectedRisk.length > 0 ||
  //             selectedCountries.length > 0 ||
  //             selectedAssignedTo.length > 0 ||
  //             selectedComponentStatus.length > 0 ||
  //             selectedOutOfScope.length > 0 ||
  //             selectedWhereUsed.length > 0) && (
  //             <Button
  //               type="primary"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setSelectedOptions([]);
  //                 setSelectedRisk([]);
  //                 setSelectedCountries([]);
  //                 setSelectedAssignedTo([]);
  //                 setSelectedComponentStatus([]);
  //                 setSelectedOutOfScope([]);
  //                 setSelectedWhereUsed([]);
  //               }}
  //             >
  //               Reset
  //             </Button>
  //           )}
  //         </Space>
  //       ),
  //     },
  //   ];
  // };
  // // Advanced filter menu items
  // const advancedFilterMenu = [
  //   {
  //     key: "Risk Level",
  //     label: (
  //       <Dropdown
  //         menu={{
  //           items: createDropdownMenu("risk", selectedRisk, setSelectedRisk),
  //         }}
  //         open={key === "Risk Level" && menuVisible}
  //         trigger={["click"]}
  //         placement="bottomLeft"
  //         onOpenChange={(open) => {
  //           if (open) {
  //             setKey("Risk Level");
  //             setMenuVisible(open);
  //             fetchFilterData("risk");
  //           } else {
  //             setMenuVisible(false);
  //             setKey(null);
  //           }
  //         }}
  //         overlayStyle={{ pointerEvents: "auto" }}
  //       >
  //         <span>Risk Level</span>
  //       </Dropdown>
  //     ),
  //   },
  //   {
  //     key: "Country Of Origin",
  //     label: (
  //       <Dropdown
  //         menu={{
  //           items: createDropdownMenu(
  //             "countryOfOrigin",
  //             selectedCountries,
  //             setSelectedCountries
  //           ),
  //         }}
  //         open={key === "Country Of Origin" && menuVisible}
  //         trigger={["click"]}
  //         placement="bottomLeft"
  //         overlayStyle={{ pointerEvents: "auto" }}
  //         onOpenChange={(open) => {
  //           if (open) {
  //             setKey("Country Of Origin");
  //             setMenuVisible(open);
  //             fetchFilterData("countryOfOrigin");
  //           } else {
  //             setMenuVisible(false);
  //             setKey(null);
  //           }
  //         }}
  //       >
  //         <span>Country of Origin</span>
  //       </Dropdown>
  //     ),
  //   },
  //   {
  //     key: "Assigned To",
  //     label: (
  //       <Dropdown
  //         menu={{
  //           items: createDropdownMenu(
  //             "assignedTo",
  //             selectedAssignedTo,
  //             setSelectedAssignedTo
  //           ),
  //         }}
  //         open={key === "Assigned To" && menuVisible}
  //         trigger={["click"]}
  //         placement="bottomLeft"
  //         overlayStyle={{ pointerEvents: "auto" }}
  //         onOpenChange={(open) => {
  //           if (open) {
  //             setKey("Assigned To");
  //             setMenuVisible(open);
  //             fetchFilterData("assignedTo");
  //           } else {
  //             setMenuVisible(false);
  //             setKey(null);
  //           }
  //         }}
  //       >
  //         <span>Assigned To</span>
  //       </Dropdown>
  //     ),
  //   },
  //   {
  //     key: "Component Status",
  //     label: (
  //       <Dropdown
  //         menu={{
  //           items: createDropdownMenu(
  //             "status",
  //             selectedComponentStatus,
  //             setSelectedComponentStatus
  //           ),
  //         }}
  //         open={key === "Component Status" && menuVisible}
  //         trigger={["click"]}
  //         placement="bottomLeft"
  //         overlayStyle={{ pointerEvents: "auto" }}
  //         onOpenChange={(open) => {
  //           if (open) {
  //             setMenuVisible(open);
  //             setKey("Component Status");
  //             fetchFilterData("status");
  //           } else {
  //             setMenuVisible(false);
  //             setKey(null);
  //           }
  //         }}
  //       >
  //         <span>Component Status</span>
  //       </Dropdown>
  //     ),
  //   },
  //   {
  //     key: "Out of scope for",
  //     label: (
  //       <Dropdown
  //         menu={{
  //           items: createDropdownMenu(
  //             "outOfScope",
  //             selectedOutOfScope,
  //             setSelectedOutOfScope
  //           ),
  //         }}
  //         open={key === "Out of scope for" && menuVisible}
  //         trigger={["click"]}
  //         placement="bottomLeft"
  //         overlayStyle={{ pointerEvents: "auto" }}
  //         onOpenChange={(open) => {
  //           if (open) {
  //             setMenuVisible(open);
  //             setKey("Out of scope for");
  //             fetchFilterData("outOfScope");
  //           } else {
  //             setMenuVisible(false);
  //             setKey(null);
  //           }
  //         }}
  //       >
  //         <span>Out of scope for</span>
  //       </Dropdown>
  //     ),
  //   },
  //   {
  //     key: "productRefs",
  //     label: (
  //       <Dropdown
  //         menu={{
  //           items: createDropdownMenu(
  //             "productRefs",
  //             selectedWhereUsed,
  //             setSelectedWhereUsed
  //           ),
  //         }}
  //         open={key === "productRefs" && menuVisible}
  //         trigger={["click"]}
  //         placement="bottomLeft"
  //         overlayStyle={{ pointerEvents: "auto" }}
  //         onOpenChange={(open) => {
  //           if (open) {
  //             setMenuVisible(open);
  //             setKey("productRefs");
  //             open && fetchFilterData("productRefs");
  //           } else {
  //             setMenuVisible(false);
  //             setKey(null);
  //           }
  //         }}
  //       >
  //         <span>Where used</span>
  //       </Dropdown>
  //     ),
  //   },
  // ];
  const componentItems = [
    {
      key: "all",
      label: "Specifications",
      children: (
        <>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            {selectedComponents?.key === activeTab &&
              selectedComponents?.value?.length > 0 && (
                <Tag>Selections {selectedComponents?.value?.length}</Tag>
              )}
            {Object.keys(tableFilters).length > 0 &&
              Object.keys(tableFilters).reduce<JSX.Element[]>((acc, key) => {
                const elements =
                  tableFilters[key]?.map((item, idx) => (
                    <Tag
                      className="filter-tag"
                      key={`${key}-${idx}`}
                      bordered={false}
                      closable
                      onClose={() => {
                        const tablefiltrs = { ...tableFilters };
                        const arr = tablefiltrs[key];
                        if (arr) {
                          const arrIndex = arr?.indexOf(item);
                          if (arrIndex !== -1) {
                            arr.splice(arrIndex, 1);
                          }
                          setTableFilters({ ...tablefiltrs });
                        }
                      }}
                    >
                      {typeof item === "string" && item}
                    </Tag>
                  )) || [];
                acc.push(...elements);
                return acc;
              }, [])}
            {Object.keys(tableFilters).length > 0 ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                  // setSelectedRisk([]);
                  // setSelectedCountries([]);
                  // setSelectedAssignedTo([]);
                  // setSelectedComponentStatus([]);
                  // setSelectedOutOfScope([]);
                  // setSelectedWhereUsed([]);
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using internal item number, manufacturer name or item number"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}
            {/* <Dropdown
              menu={{ items: advancedFilterMenu }}
              open={advancedropdown}
              trigger={["click"]}
            >
              <Button
                type="primary"
                icon={<ControlOutlined />}
                onClick={() => setadvanceDropdown(true)}
              >
                Advance Filters
              </Button>
            </Dropdown> */}
            <AddToCampaignListModal
              selectedItems={selectedComponents?.value || []}
              type="componentsAdd"
              onCancel={() => {
                setSelectedComponents({
                  key: activeTab,
                  value: [],
                });
              }}
            />
            {permissions?.create.includes("components") && (
              <ComponentForm type="create" fetchData={fetchData} />
            )}
            {permissions?.update?.includes("components") &&
              selectedComponents?.key === activeTab &&
              selectedComponents.value.length > 0 && (
                <BulkEditSpecifications
                  fetchData={fetchData}
                  formData={selectedComponents?.value}
                  onChildClick={onChildClick}
                />
              )}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
          {permissions?.read.includes("components") && (
            <Table
              loading={loading}
              dataSource={componentsList}
              bordered
              scroll={{
                x: 1000 + 1000 * regulations.length,
                y: "calc(100vh - 285px)",
              }}
              columns={columns}
              pagination={{
                ...tablePagination,
                total: componentCount,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (totalCount) => `Total  ${totalCount}  items`,
              }}
              rowKey="_id"
              onChange={handleTableChange}
              rowSelection={componentSelection}
            />
          )}
        </>
      ),
    },
    ...regulationsTabs,
    permissions?.read.includes("materials") && {
      key: "materials",
      label: "Material Disclosure",
      children: (
        <>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            {Object.keys(tableFilters).length > 0 ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                  // setSelectedRisk([]);
                  // setSelectedCountries([]);
                  // setSelectedAssignedTo([]);
                  // setSelectedComponentStatus([]);
                  // setSelectedOutOfScope([]);
                  // setSelectedWhereUsed([]);
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using internal item number, manufacturer name or item number"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}
            {/* { permissions?.create.includes("materials") && 
              <Button type="primary" icon={<PlusOutlined />}> Create </Button>
            } */}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
          {permissions?.read.includes("materials") && (
            <>
              <Row gutter={16}>
                <Col span={8}>
                  <Card title="Components" bordered={false}>
                    <List
                      key={"1"}
                      dataSource={componentsList}
                      loading={loading}
                    >
                      <div
                        style={{
                          height: 500,
                          overflow: "auto",
                          border: "1px solid rgba(140, 140, 140, 0.35)",
                        }}
                      >
                        {componentsList && componentsList.length !== 0 ? (
                          componentsList?.map((item: any, index: any) => (
                            <Card
                              key={item._id}
                              type="inner"
                              size="small"
                              style={{
                                backgroundColor:
                                  selectedComponentData?._id === item._id
                                    ? user?.primaryColor
                                    : "#eeeeee",
                                color:
                                  selectedComponentData?._id === item._id
                                    ? "#eeeeee"
                                    : user?.primaryColor,
                                marginBottom: "1rem",
                                overflow: "hidden",
                              }}
                              bordered={false}
                              onClick={() => {
                                setselectedComponentData(item);
                                setselectedMaterialData(
                                  item?.materials ? item?.materials[0] : null
                                );
                              }}
                            >
                              <Meta
                                title={
                                  <Space
                                    align="end"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: "1rem",
                                      background:
                                        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
                                    }}
                                  >
                                    {selectedComponentData?._id === item._id &&
                                    item?.fmdDocument ? (
                                      <span>
                                        {item?.fmdDocument?.includes("http") ? (
                                          <span>
                                            <ExportOutlined
                                              onClick={() =>
                                                onClickViewLink(
                                                  item?.fmdDocument
                                                )
                                              }
                                            />{" "}
                                          </span>
                                        ) : (
                                          <ReadSupportingDocument
                                            postUrl="materials/document/download"
                                            filename={item?.fmdDocument}
                                            foldername="materials"
                                            type={"fmdComponentList"}
                                          />
                                        )}
                                      </span>
                                    ) : (
                                      " "
                                    )}
                                    {permissions?.update?.includes(
                                      "components"
                                    ) &&
                                      (selectedComponentData?._id ===
                                      item._id ? (
                                        <span style={{ color: "white" }}>
                                          <ImportDataForm
                                            type="create"
                                            fetchData={fetchData}
                                            xmlImport={true}
                                            importTypeData={{
                                              manufacturer: item.manufacturer,
                                              id: item.componentId,
                                            }}
                                            module={"product"}
                                          />
                                          {item.fmdComments && (
                                            <Tooltip title={item.fmdComments}>
                                              <CommentOutlined />
                                            </Tooltip>
                                          )}
                                          <EditMaterialDisclosureComponents
                                            type={"Component"}
                                            fetchData={fetchData}
                                            formData={{
                                              component: selectedComponentData,
                                              material: selectedMaterialData,
                                            }}
                                          />
                                        </span>
                                      ) : undefined)}
                                  </Space>
                                }
                              />
                              <small>{item?.internalItemNumber}</small>
                              <br></br>
                              <Row>
                                <Col span={18}>
                                  <b>{item?.manufacturer?.itemNumber}</b>
                                </Col>
                                <Col span={4}>
                                  <b>
                                    {item?.partWeight
                                      ? parseFloat(item?.partWeight).toString()
                                      : "NA"}
                                    {item?.partUnitOfMeasure && item?.partWeight
                                      ? item?.partUnitOfMeasure
                                      : undefined}
                                  </b>
                                </Col>
                              </Row>
                              {item?.manufacturer?.name}
                              <br></br>

                              {getRegulation(item._id)}
                            </Card>
                          ))
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </div>
                    </List>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Material"
                    bordered={false}
                    extra={
                      componentsList.length > 0 && (
                        <EditMaterialDisclosureComponents
                          type={"Material"}
                          fetchData={fetchData}
                          formData={{
                            component: selectedComponentData,
                            material: selectedMaterialData,
                          }}
                        />
                      )
                    }
                  >
                    <div
                      style={{
                        height: 500,
                        overflow: "auto",
                        border: "1px solid rgba(140, 140, 140, 0.35)",
                      }}
                    >
                      {selectedComponentData &&
                      selectedComponentData?.materials ? (
                        selectedComponentData?.materials?.map(
                          (item: any, index: any) => (
                            <Card
                              key={index}
                              type="inner"
                              size="small"
                              onClick={() => setselectedMaterialData(item)}
                              style={{
                                backgroundColor:
                                  selectedMaterialData?.name === item.name &&
                                  selectedMaterialData?.weight ===
                                    item.weight &&
                                  selectedMaterialData?.unitOfMeasure ===
                                    item.unitOfMeasure
                                    ? user?.secondaryColor
                                    : "#eeeeee",
                                color:
                                  selectedMaterialData?.name === item.name &&
                                  selectedMaterialData?.weight ===
                                    item.weight &&
                                  selectedMaterialData?.unitOfMeasure ===
                                    item.unitOfMeasure
                                    ? "#eeeeee"
                                    : "black",
                                marginBottom: "1rem",
                                overflow: "hidden",
                              }}
                            >
                              <Row>
                                <Col span={16}>
                                  {
                                    <CodeSandboxOutlined
                                      style={{
                                        marginRight: 5,
                                        color:
                                          selectedMaterialData?.name ===
                                            item?.name &&
                                          selectedMaterialData?.weight ===
                                            item.weight &&
                                          selectedMaterialData?.unitOfMeasure ===
                                            item.unitOfMeasure
                                            ? "white"
                                            : item?.substances &&
                                              item?.substances.some(
                                                (subItem: {
                                                  compliantalert: string;
                                                }) =>
                                                  subItem?.compliantalert?.toLowerCase() ===
                                                  "yes"
                                              )
                                            ? "red"
                                            : "black",
                                      }}
                                    />
                                  }
                                  {item?.name}
                                </Col>
                                <Col span={8}>
                                  {parseFloat(item?.weight).toString()}
                                  {item?.unitOfMeasure || item?.unitOfMeasure}
                                </Col>
                              </Row>
                            </Card>
                          )
                        )
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Substance"
                    bordered={false}
                    extra={
                      selectedComponentData?.materials?.length &&
                      selectedComponentData?.materials?.length > 0 && (
                        <EditMaterialDisclosureComponents
                          type={"Substance"}
                          fetchData={fetchData}
                          formData={{
                            component: selectedComponentData,
                            material: selectedMaterialData,
                          }}
                        />
                      )
                    }
                  >
                    <div
                      style={{
                        height: 500,
                        overflow: "auto",
                        border: "1px solid rgba(140, 140, 140, 0.35)",
                      }}
                    >
                      {selectedComponentData &&
                      selectedMaterialData &&
                      selectedMaterialData?.substances?.length > 0 ? (
                        selectedMaterialData?.substances?.map(
                          (item: any, index: any) => (
                            <Card
                              key={index}
                              type="inner"
                              size="small"
                              style={{
                                backgroundColor: "#eeeeee",
                                color: "black",
                                marginBottom: "1rem",
                                overflow: "hidden",
                              }}
                            >
                              {
                                <ExperimentOutlined
                                  style={{
                                    marginRight: 5,
                                    color:
                                      item?.compliantalert &&
                                      item?.compliantalert === "Yes"
                                        ? "red"
                                        : "black",
                                  }}
                                />
                              }
                              <b>
                                {" "}
                                {typeof item?.name === "object"
                                  ? item.name?.substance
                                  : item.name}
                              </b>
                              <br></br>
                              <Row>
                                <Col span={16} style={{ paddingLeft: "20px" }}>
                                  {"CAS # "}
                                  <b>{item?.casNumber}</b> <br></br>
                                </Col>
                                <Col span={8}>
                                  <b>
                                    {parseFloat(item?.weight).toString()}
                                    {item?.unitOfMeasure}
                                  </b>
                                </Col>
                              </Row>
                              {item.compliantalertRegulation?.length > 0 && (
                                <>
                                  <FlagFilled
                                    style={{ color: "red", marginRight: 5 }}
                                  />
                                  {regulations
                                    .filter(
                                      (regulation: { regulationNumber: any }) =>
                                        item?.compliantalertRegulation.includes(
                                          regulation.regulationNumber
                                        )
                                    )
                                    .map((item: any) => (
                                      <Tag
                                        key={item?.regulationNumber}
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                        }}
                                      >
                                        {item.name}
                                      </Tag>
                                    ))}
                                </>
                              )}
                            </Card>
                          )
                        )
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
                {...tablePagination}
                total={componentCount}
                showTotal={(totalCount) => `Total  ${totalCount}  items`}
                showSizeChanger={true}
                showQuickJumper={true}
                onChange={(page, pageSize) =>
                  setTablePagination({
                    ...tablePagination,
                    current: page,
                    pageSize,
                  })
                }
              />
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setmaxDisplayCompliance(2);
      } else if (window.innerWidth < 1024) {
        setmaxDisplayCompliance(3);
      } else {
        setmaxDisplayCompliance(7);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);
  // useEffect(() => {
  //   if (!menuVisible && key == null) {
  //     setadvanceDropdown(false);
  //     setSelectedRisk([]);
  //     setSelectedCountries([]);
  //     setSelectedAssignedTo([]);
  //     setSelectedComponentStatus([]);
  //     setSelectedOutOfScope([]);
  //     setSelectedWhereUsed([]);
  //   }
  // }, [menuVisible, key]);

  return (
    <>
      <ErrorBoundary>
        <Spin fullscreen spinning={loadingData} />
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Components
            </Title>
          </div>
        </Space>
        <Tabs
          defaultActiveKey="all"
          items={componentItems}
          tabPosition="top"
          activeKey={activeTab}
          onChange={handleTabChange}
        />
      </ErrorBoundary>
    </>
  );
};

export default ComponentList;
